import { styled } from '@mui/material';

const Container = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
  };
});

const PageNotFound = () => {
  return <Container>Page Not Found</Container>;
};

export default PageNotFound;
