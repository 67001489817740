import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

interface AttendanceDialogContentProps {
  defaultValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const AttendanceDialogContent: React.FC<AttendanceDialogContentProps> = ({
  defaultValue,
  onChange,
}) => {
  return (
    <FormControl>
      <RadioGroup
        sx={{
          gap: '16px',
          '& .MuiTypography-root': {
            fontSize: '0.875rem',
          },
        }}
        value={defaultValue}
        onChange={onChange}
      >
        <FormControlLabel
          value="area"
          control={<Radio />}
          label="Display Area Name"
        />
        <FormControlLabel
          value="beacon"
          control={<Radio />}
          label="Display Beacon Name"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default AttendanceDialogContent;
