import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { CircularProgress, styled } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { ErrorState } from '../types';
import { DasEndpoint, DasEndpointType } from '../types/Device';
import { RmrCause, RmrPhoto } from '../types/RmrTicket';

import {
  deletePhoto,
  updateRmrCause,
  UpdateRmrCauseData,
  uploadPhotos,
} from '../apis/rmrApi';

import { parseDasId } from '../utils/common';

import AddPhotoSvgIcon from './SvgIcon/AddPhotoSvgIcon';
import ArrowLeftSvgIcon from './SvgIcon/ArrowLeftSvgIcon';
import ArrowRightSvgIcon from './SvgIcon/ArrowRightSvgIcon';
import CrossSvgIcon from './SvgIcon/CrossSvgIcon';
import SaveSvgIcon from './SvgIcon/SaveSvgIcon';
import DeviceAssignment from './DeviceAssignment';
import DeviceTag from './DeviceTag';
import Input from './Input';
import Textarea from './Textarea';

const GALLERY_WIDTH = 764;

const Container = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    padding: '16px',
    background: theme.color.gray.$240,
    border: `1px solid ${theme.color.secondary.$40}`,
    borderRadius: '4px',
    margin: '16px 0 8px 0',
    '& > .banner': {
      color: '#fff',
      fontSize: '1rem',
      padding: '16px',
      margin: '32px 0 16px 0',
      '&[data-status="error"]': {
        background: theme.color.highlight,
      },
      '&[data-status="success"]': {
        background: theme.color.green.$100,
      },
    },
    '& > div': {
      marginBottom: '16px',
      '&.normal': {
        padding: '0 56px',
        '&.action': {
          textAlign: 'center',
          marginBottom: '0',
        },
      },
      '& > label': {
        fontSize: '0.875rem',
        '& >span': {
          color: theme.color.highlight,
        },
      },
      '& > .device-wrapper': {
        margin: '8px 0 16px 0',
        '& > .container': {
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexWrap: 'wrap',
          gap: '8px',
          marginTop: '8px',
        },
      },
    },
    '& > .picture-control-container': {
      position: 'relative',
      '& > .picture-container': {
        position: 'relative',
        height: '180px',
        overflow: 'hidden',
        margin: '0 56px',
      },
      '& .picture': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '180px',
        overflow: 'hidden',
        transition: 'left 0.3s',
        '& > .list': {
          position: 'absolute',
          display: 'flex',
          gap: `calc((${GALLERY_WIDTH}px - 720px) / 3)`,
          '& > .image-wrapper': {
            position: 'relative',
            width: '180px',
            height: '180px',
            '& > img': {
              cursor: 'pointer',
              width: '180px',
              height: '180px',
              objectFit: 'cover',
            },
          },
        },
        '& .add-image': {
          cursor: 'pointer',
        },
      },
      '& .page-button': {
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        width: '40px',
        height: '100%',
        '&.previous': {
          top: 0,
          left: 16,
          justifyContent: 'flex-start',
        },
        '&.next': {
          top: 0,
          right: 0,
          justifyContent: 'flex-end',
        },
      },
      '& .error': {
        fontSize: '0.875rem',
        color: theme.color.highlight,
        padding: '0 40px 0 56px',
      },
    },
  };
});

interface EditRmrCauseProps {
  issueId: string;
  issueIndex: number;
  devicePool: string[];
  number: number;
  data: RmrCause;
  onClose: () => void;
  onSuccess?: () => void;
}

const EditRmrCause: FC<EditRmrCauseProps> = ({
  issueId,
  issueIndex,
  devicePool,
  number,
  data,
  onClose,
  onSuccess,
}) => {
  const { ticketId } = useParams();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [filter, setFilter] = useState('');
  const [selectedDasIds, setSelectedDasIds] = useState<string[]>(
    data.issueDevices,
  );
  const [photos, setPhotos] = useState<Array<RmrPhoto & { file?: File }>>(
    data.photos,
  );
  const [photosError, setPhotosError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [picturePage, setPicturePage] = useState(1);
  const [title, setTitle] = useState(data.title);
  const [titleError, setTitleError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [description, setDescription] = useState(data.description);
  const [descriptionError, setDescriptionError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [responseSatusResult, setResponseSatusResult] = useState<{
    status: 'success' | 'error';
    message: string;
  }>({
    status: 'success',
    message: '',
  });

  const uploadPhotosMutation = useMutation({
    mutationFn: async (data: {
      ticketId: string;
      issueId: string;
      causeId: string;
      images: File[];
    }) => {
      return uploadPhotos(
        data.ticketId,
        data.issueId,
        data.causeId,
        undefined,
        data.images,
      );
    },
    retry: false,
    onError: (error: AxiosError<{ error: { message: string } }>) => {
      setResponseSatusResult({
        status: 'error',
        message: `
        Update cause Success but upload photos fail. (Error: ${
          error.response?.data?.error?.message ?? error.message
        })
        `,
      });
    },
    onSuccess: () => {
      setResponseSatusResult({
        status: 'success',
        message: 'Update cause success',
      });
      onSuccess?.();
      setTimeout(() => {
        onClose();
      }, 1000);
    },
  });

  const updateCauseMutation = useMutation({
    mutationFn: async (
      data: UpdateRmrCauseData & { photos: Array<RmrPhoto & { file?: File }> },
    ) => {
      if (ticketId) {
        const patchData: UpdateRmrCauseData = {
          ticketId: data.ticketId,
          issueId: data.issueId,
          causeId: data.causeId,
          data: data.data,
        };
        return updateRmrCause(patchData).then(() => data);
      }
    },
    onError: (error: AxiosError<{ error: { message: string } }>) => {
      setResponseSatusResult({
        status: 'error',
        message: error.response?.data?.error?.message ?? error.message,
      });
    },
    onSuccess: (d) => {
      if (d && d.photos?.filter((p) => !!p.file).length > 0) {
        uploadPhotosMutation.mutate({
          ticketId: ticketId as string,
          issueId: issueId,
          causeId: d.causeId,
          images: d.photos.filter((p) => !!p.file).map((p) => p.file) as File[],
        });
      } else {
        setResponseSatusResult({
          status: 'success',
          message: 'Update cause success',
        });
        onSuccess?.();
        setTimeout(() => {
          onClose();
        }, 1000);
      }
    },
    retry: false,
  });

  useEffect(() => {
    if (photos.filter((p) => !!p.file).length > 5) {
      setPhotosError({
        isError: true,
        message: 'Only upload 5 photos one time',
      });
    } else {
      let sizeError = false;
      for (let i = 0; i < photos.length; i++) {
        const file = photos[i].file;
        if (file && file.size > 5 * 1000 * 1000) {
          sizeError = true;
          setPhotosError({
            isError: true,
            message: `Photo ${i + 1} file size over 5 MB`,
          });
          break;
        }
      }

      if (!sizeError) {
        setPhotosError({
          isError: false,
          message: ``,
        });
      }
    }
  }, [photos]);

  const handleOnSelect = (dasId: string) => {
    setSelectedDasIds([...selectedDasIds, dasId]);
  };

  const handleOnDeleteSelectedDasId = (dasId: string) => {
    setSelectedDasIds(selectedDasIds.filter((id) => id !== dasId));
  };

  const handleOnClickPrevious = () => {
    if (picturePage - 1 >= 1) {
      setPicturePage(picturePage - 1);
    }
  };

  const handleOnClickNext = () => {
    if (picturePage + 1 <= totalPage) {
      setPicturePage(picturePage + 1);
    }
  };

  const handleAddPhoto = () => {
    fileInputRef.current?.click();
  };

  const handleRemovePhoto = (index: number) => {
    const photo = photos[index];
    URL.revokeObjectURL(photo.url);
    setPhotos(photos.filter((p, i) => i !== index));
  };

  const handleOnChangeFiles = () => {
    const files = fileInputRef.current?.files;
    if (files) {
      const images: Array<RmrPhoto & { file?: File }> = [...photos];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        images.push({
          id: uuidv4(),
          url: URL.createObjectURL(file),
          file,
        });
      }

      setPhotos(images);
    }
  };

  const handleUpdateCause = async () => {
    if (ticketId) {
      const needToDeletePhotos: string[] = [];
      data.photos.forEach((p) => {
        let isExist = false;
        for (let i = 0; i < photos.length; i++) {
          if (p.id === photos[i].id) {
            isExist = true;
            break;
          }
        }
        if (!isExist) {
          needToDeletePhotos.push(p.id);
        }
      });

      for (let i = 0; i < needToDeletePhotos.length; i++) {
        await deletePhoto({
          ticketId,
          issueId,
          causeId: data.id,
          photoId: needToDeletePhotos[i],
        });
      }

      const addDasIds: string[] = [];
      const removeDasIds: string[] = [];

      data.issueDevices.forEach((id) => {
        let isExist = false;
        for (let i = 0; i < selectedDasIds.length; i++) {
          if (id === selectedDasIds[i]) {
            isExist = true;
            break;
          }
        }

        if (!isExist) {
          removeDasIds.push(id);
        }
      });

      selectedDasIds.forEach((id) => {
        let isExist = false;
        for (let i = 0; i < data.issueDevices.length; i++) {
          if (id === data.issueDevices[i]) {
            isExist = true;
            break;
          }
        }

        if (!isExist) {
          addDasIds.push(id);
        }
      });

      updateCauseMutation.mutate({
        ticketId,
        issueId,
        causeId: data.id,
        data: {
          title,
          description,
          dasIds: {
            $remove: removeDasIds,
            $add: addDasIds,
          },
        },
        photos,
      });
    }
  };

  const totalPage = Math.ceil(photos.length / 4);

  const deviceTags = selectedDasIds.map((dasId) => {
    return (
      <DeviceTag
        key={`devicetags-${issueIndex}-${dasId}`}
        dasId={dasId}
        mode="edit"
        type={parseDasId(dasId) as DasEndpointType}
        onDelete={handleOnDeleteSelectedDasId}
      />
    );
  });

  const images = photos.map((photo, index) => {
    return (
      <div
        key={`issue${issueIndex}-create-cause-${number}-photo-${photo.id}`}
        className="image-wrapper"
      >
        <CrossSvgIcon
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            width: '32px',
            height: '32px',
            color: '#fff',
          }}
          onClick={() => handleRemovePhoto(index)}
        />
        <img src={photo.url} />
      </div>
    );
  });

  const isValid =
    title !== '' && selectedDasIds.length > 0 && description !== '';

  return (
    <Container>
      <CrossSvgIcon
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: '16px',
          right: '16px',
          width: '40px',
          height: '40px',
        }}
        onClick={onClose}
      />
      <div className="normal">
        <label>
          CAUSE {number}
          <span>*</span>
        </label>
        <Input
          sx={() => ({
            display: 'block',
            '& > input': { backgroundColor: '#fff' },
          })}
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          onBlur={() => {
            if (title === '') {
              setTitleError({
                isError: true,
                message: 'Title do not be empty',
              });
            } else {
              setTitleError({
                isError: false,
                message: '',
              });
            }
          }}
          error={titleError.isError}
          errorMessage={titleError.message}
        />
      </div>
      <div className="normal">
        <label>
          Associated Devices<span>*</span>
        </label>
        <div className="device-wrapper">
          {
            <DeviceAssignment
              prefixKey={`create-issue-${issueIndex}`}
              filter={filter}
              assignMap={devicePool.reduce<{
                [id: string]: DasEndpoint;
              }>((prev, curr, index) => {
                prev[curr] = {
                  id: `devicePool-${index}`,
                  dasId: curr,
                  //@ts-ignore
                  type: parseDasId(curr) ?? 'unknown',
                  metadata: {},
                };
                return prev;
              }, {})}
              selectedList={selectedDasIds}
              disableTooltip
              onSelect={handleOnSelect}
              onFilterChange={(text) => setFilter(text)}
              onFetchNextPage={() => {
                //
              }}
              hasNextPage={false}
            />
          }
          <div className="container">{deviceTags}</div>
        </div>
      </div>
      <div className="picture-control-container">
        {picturePage > 1 && (
          <div className="page-button previous" onClick={handleOnClickPrevious}>
            <ArrowLeftSvgIcon sx={{ width: 40, height: 40 }} />
          </div>
        )}
        <div className="picture-container">
          <div
            className="picture"
            style={{
              left: `${
                (picturePage - 1) * -(GALLERY_WIDTH + (GALLERY_WIDTH - 720) / 3)
              }px`,
            }}
          >
            <div className="list">
              <AddPhotoSvgIcon
                className="add-image"
                sx={{ width: '180px', height: '180px' }}
                onClick={handleAddPhoto}
              />
              <input
                ref={fileInputRef}
                type="file"
                accept=".jpg,.jpeg,.png"
                hidden
                multiple
                onChange={handleOnChangeFiles}
              />
              {images}
            </div>
          </div>
        </div>
        {picturePage < totalPage && (
          <div className="page-button next" onClick={handleOnClickNext}>
            <ArrowRightSvgIcon sx={{ width: 40, height: 40 }} />
          </div>
        )}
        {photosError.isError && (
          <div className="error">{photosError.message}</div>
        )}
      </div>
      <div className="normal">
        <label>
          Description<span>*</span>
        </label>
        <Textarea
          sx={{ display: 'block' }}
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          onBlur={() => {
            if (description === '') {
              setDescriptionError({
                isError: true,
                message: 'Description do not empty',
              });
            } else {
              setDescriptionError({
                isError: false,
                message: '',
              });
            }
          }}
          error={descriptionError.isError}
          errorMessage={descriptionError.message}
        />
      </div>
      <div className="normal action">
        <DialogButton
          variant="contained"
          color="primary"
          disabled={
            !isValid ||
            updateCauseMutation.isLoading ||
            uploadPhotosMutation.isLoading
          }
          endIcon={
            !(
              updateCauseMutation.isLoading || uploadPhotosMutation.isLoading
            ) && <SaveSvgIcon sx={{ width: '32px', height: '32px' }} />
          }
          onClick={handleUpdateCause}
        >
          {updateCauseMutation.isLoading || uploadPhotosMutation.isLoading ? (
            <CircularProgress sx={{ color: '#FFF' }} size={24} />
          ) : (
            'SAVE'
          )}
        </DialogButton>
      </div>
      {responseSatusResult.message !== '' && (
        <div className="banner" data-status={responseSatusResult.status}>
          {responseSatusResult.message}
        </div>
      )}
    </Container>
  );
};

export default EditRmrCause;
