import PauseIcon from '@mui/icons-material/Pause';
import { Box, BoxProps, CircularProgress, styled } from '@mui/material';

import { TaskStatus } from '../../types';

import CancelSvgIcon from '../../assets/svg/task-status/CancelSvgIcon';
import FailSvgIcon from '../../assets/svg/task-status/FailSvgIcon';
import SuccesssSvgIcon from '../../assets/svg/task-status/SuccessSvgIcon';

interface ContainerProps {
  status: TaskStatus;
}

const Container = styled(Box)<ContainerProps>`
  display: inline-flex;
  align-items: center;
  color: ${({ status, theme }) => {
    switch (status) {
      case 'pending':
        return theme.color.secondary.$100;
      case 'inProgress':
        return theme.color.primary.$100;
      case 'completed':
        return theme.color.green.$100;
      case 'failed':
        return theme.color.highlight;
      case 'canceled':
      default:
        return 'inherit';
    }
  }};

  & .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    & > * {
      display: block;
    }
  }
`;

interface TaskStatusIconProps extends BoxProps {
  status: TaskStatus;
}

const TaskStatusIcon: React.FC<TaskStatusIconProps> = ({
  status,
  ...otherProps
}) => {
  let statusIcon;
  let statusText;

  switch (status) {
    case 'pending':
      statusIcon = <PauseIcon />;
      statusText = status.toUpperCase();
      break;
    case 'inProgress':
      statusIcon = <CircularProgress size={16} />;
      statusText = 'In Progress'.toUpperCase();
      break;
    case 'completed':
      statusIcon = <SuccesssSvgIcon />;
      statusText = status.toUpperCase();
      break;
    case 'failed':
      statusIcon = <FailSvgIcon />;
      statusText = status.toUpperCase();
      break;
    case 'canceled':
      statusIcon = <CancelSvgIcon />;
      statusText = status.toUpperCase();
      break;
  }

  return (
    <Container status={status} {...otherProps}>
      <div className="icon-container">{statusIcon}</div>
      {statusText}
    </Container>
  );
};

export default TaskStatusIcon;
