import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Searchbar } from '@beeinventor/dasiot-react-component-lib';
import { styled } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { setFilterText } from '../../slices/systemSlice';

import Sidebar from './Sidebar';
import UserPopper from './UserPopper';

const languageList = [
  {
    code: 'en-US',
    name: 'English',
    fullName: 'English(US)',
  },
];

const Container = styled('div')(({ theme }) => {
  return {
    display: 'flex',

    '& .main': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      color: theme.color.secondary.$100,
      background: '#F5F5F5',
      overflowY: 'hidden', // This can't remove. If you did it. device page has problem on Windows
    },

    '& .operateButton': {
      cursor: 'pointer',
      alignItems: 'center',
      display: 'flex',
      height: 74,
      justifyContent: 'center',
      width: 74,
      '&.selected': {
        backgroundColor: theme.color.primary.$80,
        '&:hover': { backgroundColor: theme.color.primary.$80 },
      },
      '&:hover': { backgroundColor: 'rgba(0,0,0, .08)' },
      '& > img': {
        objectFit: 'cover',
        display: 'block',
        width: '40px',
        height: '40px',
        margin: 0,
        borderRadius: '50%',
      },
    },
  };
});

const HomePage = () => {
  const userInfoButtonRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const imageURL = useAppSelector((store) => store.user.imageURL);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [text, setState] = useState('');

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/rmr', { replace: true });
    }
  }, [location]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setFilterText(text));
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [text]);

  const handleOnChangeSearchText = (e: React.FormEvent<EventTarget>) => {
    setState((e.currentTarget as HTMLInputElement).value);
  };

  return (
    <Container>
      <Sidebar />
      <main className="main">
        <Searchbar
          LanguageButtonProps={{
            list: languageList,
          }}
          operationNode={
            <>
              <div
                ref={(ref) => {
                  userInfoButtonRef.current = ref;
                }}
                className={'operateButton' + (openUserInfo ? ' selected' : '')}
                onClick={() => {
                  setOpenUserInfo(!openUserInfo);
                }}
              >
                <img
                  src={
                    imageURL
                      ? imageURL
                      : '/assets/image/svg/user_default_avatar.svg'
                  }
                />
              </div>
              <UserPopper
                anchorEl={userInfoButtonRef.current}
                open={openUserInfo}
                onClickAway={() => setOpenUserInfo(false)}
              />
            </>
          }
          onChange={handleOnChangeSearchText}
        />
        <Outlet />
      </main>
    </Container>
  );
};

export default HomePage;
