import { useMemo, useState } from 'react';
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Project, ProjectMap } from '../../types/Project';

import { getProjectAudios, transferProjectAudios } from '../../apis/audioApi';
import { getProjects } from '../../apis/projectApi';

import ConfirmDialog from '../../components/Dialog/ConfirmDialog';

const Container = styled(Box)`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 160px 1fr;
  grid-template-rows: 1fr;
  height: calc(100vh - 26px - 48px);
  padding: 24px 48px;
`;

const AudioContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 10px;

  & label {
    display: inline-block;
    color: #a1a1a1;
    margin: 0 0 10px 0;
  }

  & > .list {
    flex: 1 1 auto;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.box_bbg};
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    overflow-y: auto;

    & > .audio {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 7px 10px;

      &:hover {
        background-color: ${({ theme }) => theme.color.primary.$60};
      }

      & > div:first-of-type {
        display: flex;
        padding: 6px;
        align-items: center;
        gap: 10px;
        color: #fff;
        border-radius: 4px;
        background: ${({ theme }) => theme.color.primary.$100};
      }

      & > p {
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

const OperationContainer = styled(Box)`
  padding: 0 10px;

  & .operation-button {
    display: flex;
    justify-content: center;
    align-items: center;
    & > button {
      border-radius: 4px;
    }
  }
`;

const TransferPaPage = () => {
  const { data: projectMap } = useQuery(
    ['get-project-map'],
    async () => {
      const res = await getProjects();
      return res.data.data.reduce<ProjectMap>((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {});
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [transferType, setTransferType] = useState<'Overwrite' | 'Merge'>(
    'Overwrite',
  );
  const [transferDirection, setTransferDirection] = useState<'left' | 'right'>(
    'left',
  );
  const [leftProject, setLeftProject] = useState<Project>();
  const [leftProjectName, setLeftProjectName] = useState('');
  const [rightProject, setRightProject] = useState<Project>();
  const [rightProjectName, setRightProjectName] = useState('');

  const { data: leftProjectAudios, refetch: refetchLeftProjectAudios } =
    useQuery(
      ['get-audios', leftProject?.id],
      async () => {
        const res = await getProjectAudios({
          projectId: leftProject?.id as string,
        });
        return res.data.data;
      },
      {
        enabled: !!leftProject?.id,
        refetchOnWindowFocus: false,
      },
    );

  const { data: rightProjectAudios, refetch: refetchRightProjectAudios } =
    useQuery(
      ['get-audios', rightProject?.id],
      async () => {
        const res = await getProjectAudios({
          projectId: rightProject?.id as string,
        });
        return res.data.data;
      },
      {
        enabled: !!rightProject?.id,
        refetchOnWindowFocus: false,
      },
    );

  const projectOptions = useMemo(() => {
    if (projectMap) {
      return Object.values<Project>(projectMap ?? {}).map((p) => ({
        label: `${p.name}${p.description ? ' | ' + p.description : ''}`,
        id: p.id,
      }));
    }

    return [];
  }, [projectMap]);

  const leftAudioItems = useMemo(() => {
    return leftProjectAudios?.map((audio) => {
      return (
        <div key={`audio-${audio.id}`} className="audio">
          <div>{audio.audioTrackId}</div>
          <Typography title={audio.name}>{audio.name}</Typography>
        </div>
      );
    });
  }, [leftProjectAudios]);

  const rightAudioItems = useMemo(() => {
    return rightProjectAudios?.map((audio) => {
      return (
        <div key={`audio-${audio.id}`} className="audio">
          <div>{audio.audioTrackId}</div>
          <Typography title={audio.name}>{audio.name}</Typography>
        </div>
      );
    });
  }, [rightProjectAudios]);

  const openConfirmDialog = async (direction: typeof transferDirection) => {
    setTransferDirection(direction);
    setIsOpenConfirmDialog(true);
  };

  const handleTransferPA = async () => {
    if (leftProject && rightProject) {
      try {
        await transferProjectAudios({
          sourceProjectId:
            transferDirection === 'left' ? rightProject.id : leftProject.id,
          targetProjectId:
            transferDirection === 'left' ? leftProject.id : rightProject.id,
          action: transferType,
        });

        if (transferDirection === 'left') {
          await refetchLeftProjectAudios();
        } else {
          await refetchRightProjectAudios();
        }

        setIsOpenConfirmDialog(false);
      } catch (error) {
        if (error instanceof AxiosError) {
          alert(
            `Error:${JSON.stringify(error.response?.data ?? error.message)}`,
          );
        }
      }
    }
  };

  return (
    <>
      <Container>
        <AudioContainer style={{ gridColumn: '1 / 2' }}>
          <div>
            <label>Project</label>
            <Autocomplete
              disablePortal
              options={projectOptions}
              value={
                leftProject
                  ? { label: leftProject.name, id: leftProject.id }
                  : null
              }
              onChange={(e, v) => {
                if (projectMap && v) {
                  setLeftProject(projectMap[v.id]);
                } else {
                  setLeftProject(undefined);
                }
              }}
              isOptionEqualToValue={(curr) => {
                return curr.id === leftProject?.id;
              }}
              inputValue={leftProjectName}
              onInputChange={(e, text) => {
                setLeftProjectName(text);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="primary"
                  label="Selecte Project"
                />
              )}
            />
          </div>
          <div className="list">{leftAudioItems}</div>
        </AudioContainer>
        <OperationContainer style={{ gridColumn: '2 / 3' }}>
          <FormControl>
            <RadioGroup
              name="radio-buttons-group"
              value={transferType}
              onChange={(e, v) => {
                setTransferType(v as any);
              }}
            >
              <FormControlLabel
                value="Overwrite"
                control={<Radio />}
                label="OverWrite"
              />
              <FormControlLabel
                value="Merge"
                control={<Radio />}
                label="Merge"
              />
            </RadioGroup>
            <FormControl className="operation-button">
              <IconButton onClick={() => openConfirmDialog('right')}>
                <KeyboardDoubleArrowRight />
              </IconButton>
            </FormControl>
            <FormControl className="operation-button">
              <IconButton onClick={() => openConfirmDialog('left')}>
                <KeyboardDoubleArrowLeft />
              </IconButton>
            </FormControl>
          </FormControl>
        </OperationContainer>
        <AudioContainer style={{ gridColumn: '3 / 4' }}>
          <div>
            <label>Project</label>
            <Autocomplete
              disablePortal
              options={projectOptions}
              value={
                rightProject
                  ? { label: rightProject.name, id: rightProject.id }
                  : null
              }
              onChange={(e, v) => {
                if (projectMap && v) {
                  setRightProject(projectMap[v.id]);
                } else {
                  setRightProject(undefined);
                }
              }}
              isOptionEqualToValue={(curr) => {
                return curr.id === rightProject?.id;
              }}
              inputValue={rightProjectName}
              onInputChange={(e, text) => {
                setRightProjectName(text);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="primary"
                  label="Selecte Project"
                />
              )}
            />
          </div>
          <div className="list">{rightAudioItems}</div>
        </AudioContainer>
      </Container>
      <ConfirmDialog
        title={'Transfer PA'}
        description={
          <Typography variant="body1">
            Are you sure <span className="bold">{transferType}</span> PA from
            <br />
            <span className="bold">
              {transferDirection === 'left'
                ? rightProjectName
                : leftProjectName}
            </span>
            <br />
            to
            <br />
            <span className="bold">
              {' '}
              {transferDirection === 'left'
                ? leftProjectName
                : rightProjectName}
            </span>
            ?
          </Typography>
        }
        open={isOpenConfirmDialog}
        onSubmit={handleTransferPA}
        onClose={() => {
          setIsOpenConfirmDialog(false);
        }}
      />
    </>
  );
};

export default TransferPaPage;
