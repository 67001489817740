import { Audio, Response } from '../types';

import { dsmOpsAxios } from '.';

type GetProjectAudiosPayload = {
  projectId: string;
};

export const getProjectAudios = (payload: GetProjectAudiosPayload) => {
  return dsmOpsAxios.get<Response<Audio[]>>(`/v1/audios`, {
    params: payload,
  });
};

type TransferProjectAudiosPayload = {
  sourceProjectId: string;
  targetProjectId: string;
  action: 'Merge' | 'Overwrite';
};

export const transferProjectAudios = (
  payload: TransferProjectAudiosPayload,
) => {
  return dsmOpsAxios.post(`/v1/audios`, payload);
};
