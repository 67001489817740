import { RouteObject } from 'react-router-dom';

import FeatureListPage from '../../pages/feature-list';
import FeatureListInfoPage from '../../pages/feature-list/info';

const featureListRouter: RouteObject = {
  path: 'feature-list',
  element: <FeatureListPage />,
  children: [
    {
      path: ':projectId/info',
      element: <FeatureListInfoPage />,
    },
  ],
};

export default featureListRouter;
