import { useState } from 'react';
import { styled } from '@mui/material';

import AddButton from '../../../components/AddButton';
import Input from '../../../components/Input';
import AddSvgIcon from '../../../components/SvgIcon/AddSvgIcon';

import CloseDialogSvgIcon from '../../../assets/svg/dialog/CloseDialogSvgIcon';
import { inputSx } from '../../../theme';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .rtmp-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
    background: ${({ theme }) => theme.color.gray.$250};
    border: 1px solid ${({ theme }) => theme.color.gray.$235};
    border-radius: 8px;

    & .title {
      font-weight: 500;
      & > span {
        color: ${({ theme }) => theme.color.red.$100};
        font-weight: 400;
      }
    }

    & .text-field {
      color: ${({ theme }) => theme.color.secondary.$60};
    }

    & .close-icon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  & button {
    & > svg {
      color: ${({ theme }) => theme.color.primary.$100};
    }
  }
`;

interface DascartDialogContentProps {
  rtmpUrls: string[] | undefined;
  onChange: (rtmpUrls: string[]) => void;
}

const DascartDialogContent: React.FC<DascartDialogContentProps> = ({
  rtmpUrls: originalRtmpUrls,
  onChange,
}) => {
  const [rtmpUrls, setRtmpUrls] = useState<string[]>(originalRtmpUrls ?? ['']);

  const rtmpUrlItems = rtmpUrls.map((url, index) => {
    return (
      <div key={`rtmp-url-${index}`} className="rtmp-container">
        {index > 0 && (
          <CloseDialogSvgIcon
            className="close-icon"
            onClick={() => {
              const front = rtmpUrls.slice(0, index);
              const rear = rtmpUrls.slice(index + 1, rtmpUrls.length);
              const newRtmpUrls = [...front, ...rear];
              setRtmpUrls(newRtmpUrls);
              onChange(newRtmpUrls);
            }}
          />
        )}
        <div className="title">
          RTMP<span>*</span>
        </div>
        <div className="text-field">
          <label>URL</label>
          <Input
            sx={{ ...inputSx, '& > input': { backgroundColor: '#fff' } }}
            value={url}
            onChange={(e) => {
              const localRtmpUrls = [...rtmpUrls];
              localRtmpUrls[index] = e.currentTarget.value;
              setRtmpUrls(localRtmpUrls);
              onChange(localRtmpUrls);
            }}
          />
        </div>
      </div>
    );
  });

  return (
    <Container>
      {rtmpUrlItems}
      <AddButton onClick={() => setRtmpUrls([...rtmpUrls, ''])}>
        <AddSvgIcon /> Add RTMP
      </AddButton>
    </Container>
  );
};

export default DascartDialogContent;
