import { SvgIcon, SvgIconProps } from '@mui/material';

const QuerySvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5ZM18.3347 19.7489C17.2598 20.5356 15.9341 21 14.5 21C10.9101 21 8 18.0899 8 14.5C8 10.9101 10.9101 8 14.5 8C18.0899 8 21 10.9101 21 14.5C21 15.9341 20.5356 17.2597 19.7489 18.3346L23.6852 22.2709C24.0757 22.6614 24.0757 23.2946 23.6852 23.6851C23.2947 24.0756 22.6615 24.0756 22.271 23.6851L18.3347 19.7489Z"
        stroke="currentColor"
      />
    </SvgIcon>
  );
};

export default QuerySvgIcon;
