import type { SVGProps } from 'react';
import * as React from 'react';
const CircleEditSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={32} height={32} fill="#3E3E3E" rx={16} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m19.25 9.88.556-.557a1.104 1.104 0 0 1 1.562 0l1.309 1.309c.43.431.43 1.13 0 1.562l-.557.557-2.87-2.87m-1.324 1.324L9 20.13V23h2.87l8.926-8.926-2.87-2.87"
      clipRule="evenodd"
    />
  </svg>
);
export default CircleEditSvgIcon;
