import { ErrorState } from '../types';
import { DasEndpointType, LocatorType } from '../types/Device';

class ValidatorDasId {
  static validateDasId(
    value: string,
    deviceType: DasEndpointType | LocatorType,
  ): ErrorState {
    if (value === '') {
      return {
        isError: true,
        message: 'dasId should not empty.',
      };
    } else if (value.length !== 13) {
      return {
        isError: true,
        message: 'dasId is 13 digits.',
      };
    } else if (
      deviceType === 'daslock' &&
      value.charAt(1) !== 'L' &&
      value.charAt(1) !== 'G'
    ) {
      return {
        isError: true,
        message: 'dasId must begin with EGL for main daslock, ELL for sublock',
      };
    } else if (
      deviceType === 'dascas_g' &&
      (value.charAt(0) !== 'P' || value.charAt(3) !== 'G')
    ) {
      return {
        isError: true,
        message: 'Main detector dasId must begin with "P__G"',
      };
    } else if (
      deviceType === 'dascas' &&
      (value.charAt(0) !== 'P' || value.charAt(3) !== 'C')
    ) {
      return {
        isError: true,
        message: 'DasCAS dasId must begin with "P__C"',
      };
    } else {
      return {
        isError: false,
        message: '',
      };
    }
  }
}

export default ValidatorDasId;
