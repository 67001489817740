import { Box, BoxProps, styled } from '@mui/material';

const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 17px 48px;
  background-color: ${({ theme }) => theme.color.secondary.$40};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

const PageHeaderContainer: React.FC<BoxProps> = ({
  children,
  ...otherProps
}) => {
  return <Container {...otherProps}>{children}</Container>;
};

export default PageHeaderContainer;
