import { Avatar, styled } from '@mui/material';

const Container = styled('div')`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.secondary.$80};
`;

interface UserTextProps {
  imageUrl: string | undefined;
  name: string;
}

const UserText: React.FC<UserTextProps> = ({ imageUrl, name }) => {
  return (
    <Container>
      <Avatar
        sx={{ width: '32px', height: '32px', marginRight: '10px' }}
        src={imageUrl}
      />
      {name}
    </Container>
  );
};

export default UserText;
