import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DialogButton,
  Dropdown,
} from '@beeinventor/dasiot-react-component-lib';
import {
  DialogContent as MuiDialogContent,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import * as XLSX from 'xlsx';

import { InputValidationError } from '../../../types';

import { uploadEndpoint } from '../../../apis/deviceApi';
import { getProjects } from '../../../apis/projectApi';

import ImportDialog from '../../../components/Dialog/ImportDialog';
import PreviewDialog from '../../../components/Dialog/PreviewDialog';
import Input from '../../../components/Input';
import LoadingDialog from '../../../components/LoadingDialog';
import CrossSvgIcon from '../../../components/SvgIcon/CrossSvgIcon';

import theme from '../../../theme';

const DialogContent = styled(MuiDialogContent)(({ theme }) => {
  return {
    '& > label': {
      fontSize: '0.875rem',
      color: theme.color.secondary.$60,
      '& > span': {
        color: '#FF6B00',
      },
    },
    '& > .property': {
      marginBottom: '16px',
      '& > label': {
        color: '#969696',
        '& > span': {
          color: theme.color.highlight,
        },
      },
    },
    '& button': {
      marginBottom: '8px',
    },
  };
});

const ImportDeviceExcel = () => {
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState<string>();
  const [excelData, setExcelData] = useState<{ [key: string]: string }[]>([]);
  const [excelFile, setExcelFile] = useState<File>();
  const [openPanelExcel, setOpenPanelExcel] = useState(false);
  const [errorFormatExcel, setErrorFormatExcel] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const importExcelFile = useMutation({
    mutationFn: uploadEndpoint,
    onMutate: () => {
      setOpenPanelExcel(false);
      setErrorFormatExcel({
        isError: false,
        message: '',
      });
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response) {
        setErrorFormatExcel({
          isError: true,
          message: JSON.stringify(error.response.data.error),
        });
      } else {
        setErrorFormatExcel({
          isError: true,
          message: JSON.stringify((error as Error).message),
        });
      }
    },
    onSuccess: () => {
      navigate(-1);
    },
  });

  const fileRef = useRef<HTMLInputElement | null>(null);

  const { data: projects } = useQuery(
    ['get-projects'],
    async () => {
      const res = await getProjects();
      if (res.data.data) {
        return res.data.data;
      }
      return [];
    },
    {
      initialData: [],
    },
  );

  const onSave = () => {
    setOpenPanelExcel(true);
    return undefined;
  };

  const handleOnclickImport = () => {
    fileRef.current?.click();
  };

  const handleFileUpload = () => {
    const fileTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const selectedFile = fileRef.current?.files?.[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setErrorFormatExcel({
          isError: false,
          message: '',
        });
        setExcelFile(selectedFile);

        const reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          if (e.target?.result) {
            const workbook = XLSX.read(e.target.result, { type: 'buffer' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet) as {
              [key: string]: string;
            }[];
            setExcelData(data);
          }
        };
      } else {
        setErrorFormatExcel({
          isError: true,
          message: 'Please select only excel file types',
        });
      }
    }
  };

  const handleClickImport = async () => {
    if (excelFile && projectId) {
      importExcelFile.mutate({
        excelFile,
        projectId,
      });
    }
  };

  const PROJECT_LIST = useMemo(() => {
    if (projects.length > 0) {
      return projects.map((project) => ({
        id: project.id,
        name: project.name,
        value: project.id,
      }));
    }
    return [];
  }, [projects]);

  return (
    <PreviewDialog
      open
      onCancel={() => navigate(-1)}
      dialogTitle="Import Excel"
      onSave={onSave}
      saveable={excelData.length > 0}
    >
      <div className="header">
        <CrossSvgIcon
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 1,
            width: '40px',
            height: '40px',
          }}
          onClick={() => navigate(-1)}
        />
      </div>
      <DialogContent>
        <label>
          Project
          <span>*</span>
        </label>
        <Dropdown
          sx={{
            display: 'inline-flex',
            width: '100%',
            paddingTop: 0,
            paddingBottom: 0,
            border: `1px solid ${theme.color.secondary.$60}`,
            marginBottom: '16px',
          }}
          selectedId={projectId}
          list={PROJECT_LIST}
          placeholder="Select Project"
          popperProps={{
            sx: {
              color: '#606060',
              zIndex: 2000,
              maxHeight: '160px',
              overflow: 'auto',
            },
          }}
          onSelect={(v) => {
            setProjectId(v as string);
          }}
        />
        <label className="text">
          Serial Number
          <span>*</span>
        </label>
        <div className="container-input">
          {excelFile && <Typography>{excelFile?.name}</Typography>}

          <Input
            type="file"
            accept=".xlsx, .xls"
            sx={{
              width: '100%',
              height: '100%',
              alignContent: 'center',
              'input[type="file"]': {
                display: 'none',
              },
              display: 'flex',
              padding: '0',
              margin: '0',
            }}
            error={errorFormatExcel.isError}
            errorMessage={errorFormatExcel.message}
            onChange={handleFileUpload}
            rightIcon={
              <DialogButton
                variant="contained"
                color="primary"
                onClick={handleOnclickImport}
                sx={{ marginLeft: 'auto', marginTop: '10px' }}
              >
                Choose FIle
              </DialogButton>
            }
            ref={fileRef}
          />
        </div>
      </DialogContent>
      {openPanelExcel && (
        <ImportDialog
          sx={{
            '& .MuiPaper-root': {
              minWidth: '1000px',
              height: '564px',
              maxHeight: '564px',
            },
          }}
          open={openPanelExcel}
          dialogTitle="Import Excel"
          onCancel={() => setOpenPanelExcel(false)}
          previous
          onPrevious={() => setOpenPanelExcel(false)}
          onSave={handleClickImport}
          saveable={!!excelFile}
        >
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: '564px',
              }}
            >
              <TableHead>
                <TableRow>
                  {Object.keys(excelData[0]).map((key) => (
                    <TableCell key={key}>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {excelData.map((individualExcelData, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'gray' : 'white',
                      color: index % 2 === 0 ? 'white' : 'gray',
                    }}
                  >
                    {Object.keys(individualExcelData).map((key) => (
                      <TableCell key={key}>
                        {individualExcelData[key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ImportDialog>
      )}
      {importExcelFile.isLoading && (
        <LoadingDialog
          isLoading={importExcelFile.isLoading}
          open
          text="Uploading"
        />
      )}
    </PreviewDialog>
  );
};

export default ImportDeviceExcel;
