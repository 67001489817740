import { Response } from '../types';
import { Organization, OrganizationDetail } from '../types/Organization';

import { dsmOpsAxios } from '.';

export const getOrgs = () => {
  return dsmOpsAxios.get<Response<Organization[]>>('v1/orgs');
};

export const getOrgDetail = (data: { orgId: string }) => {
  return dsmOpsAxios.get<Response<OrganizationDetail>>(
    `/v1/orgs/${data.orgId}`,
  );
};

export const updateOrg = (data: {
  orgId: string;
  payload: Partial<Omit<Organization, 'id' | ''>>;
}) => {
  return dsmOpsAxios.patch(`/v1/orgs/${data.orgId}`, {
    ...data.payload,
  });
};
