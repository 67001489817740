import { RouteObject } from 'react-router-dom';

import ProjectSettingPage from '../../pages/project-setting';
import ThreeDModelSettingPage from '../../pages/project-setting/3d-model';
import Create3DModel from '../../pages/project-setting/3d-model/Create3DModel';

const projectSettingRouter: RouteObject = {
  path: 'project-setting',
  element: <ProjectSettingPage />,
  children: [
    {
      path: '3d-model',
      element: <ThreeDModelSettingPage />,
      children: [
        {
          path: 'create',
          element: <Create3DModel />,
        },
      ],
    },
  ],
};

export default projectSettingRouter;
