import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowDownSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4073 13.393C12.0168 13.0025 11.3836 13.0025 10.9931 13.393C10.6026 13.7835 10.6026 14.4167 10.9931 14.8072L15.1931 19.0072C15.2419 19.056 15.2945 19.0987 15.35 19.1353C15.7381 19.3916 16.2656 19.3489 16.6073 19.0072L20.8073 14.8072C21.1978 14.4167 21.1978 13.7835 20.8073 13.393C20.4168 13.0025 19.7836 13.0025 19.3931 13.393L15.9002 16.8859L12.4073 13.393Z"
      />
    </SvgIcon>
  );
};

export default ArrowDownSvgIcon;
