import { RouteObject } from 'react-router-dom';

import DevicesPage from '../../pages/devices';
import DasEndpointPage from '../../pages/devices/das-endpoint';
import CreateDevice from '../../pages/devices/das-endpoint/create';
import Detail from '../../pages/devices/detail';
import ExportQrcodeDialog from '../../pages/devices/export-qrcode/ExportQrcodeDialog';
import ImportDeviceExcel from '../../pages/devices/import-device/ImportDeviceExcel';
import LocatorPage from '../../pages/devices/locator';
import TransferDeviceDialog from '../../pages/devices/transfer-device/TransferDeviceDialog';

const devicesRouter: RouteObject = {
  path: 'devices',
  element: <DevicesPage />,
  children: [
    {
      path: 'das-endpoint',
      element: <DasEndpointPage />,
      children: [
        {
          path: 'create',
          element: <CreateDevice />,
        },
        {
          path: 'transfer-device',
          element: <TransferDeviceDialog />,
        },
        {
          path: 'export-qrcode',
          element: <ExportQrcodeDialog />,
        },
        {
          path: 'import',
          element: <ImportDeviceExcel />,
        },
      ],
    },
    {
      path: 'locator',
      element: <LocatorPage />,
      children: [
        {
          path: 'transfer-device',
          element: <TransferDeviceDialog />,
        },
        {
          path: 'create',
          element: <CreateDevice />,
        },
        {
          path: 'transfer-device',
          element: <TransferDeviceDialog />,
        },
      ],
    },
    {
      path: ':dasId/detail',
      element: <Detail />,
    },
  ],
};
export default devicesRouter;
