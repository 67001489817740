import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const RadioCheckedSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 20C31 26.0751 26.0751 31 20 31C13.9249 31 9 26.0751 9 20C9 13.9249 13.9249 9 20 9C26.0751 9 31 13.9249 31 20Z"
        fill="white"
        stroke="#E5E5E5"
        strokeWidth="2"
      />
      <path
        d="M28 20C28 24.4183 24.4183 28 20 28C15.5817 28 12 24.4183 12 20C12 15.5817 15.5817 12 20 12C24.4183 12 28 15.5817 28 20Z"
        fill="#E6A600"
      />
    </SvgIcon>
  );
};

export default RadioCheckedSvgIcon;
