import { PagedData, Response } from '../types';
import { CreateIssue, RmrTicket, RmrTicketStatus } from '../types/RmrTicket';
import { User } from '../types/User';

import { dsmOpsAxios } from '.';

type GetRmrTicketsResponse = {
  id: string;
  customer: string;
  contract: string;
  raisedBy: string;
  serialNumber: string;
  dueDate: string;
  status: RmrTicketStatus;
  type: 'hardware' | 'software';
  version: number;
  createdAt: string;
  updateAt: string;
};

export type GetRmrTicketsParams = {
  limit?: number;
  expiry?: boolean;
  status?: RmrTicketStatus;
  dueDate?: string;
  nextCursor?: string;
};

export type CreateRmrTicket = {
  customer: string;
  contract: string;
  raisedBy: string;
  participants: string[];
  auditor: string;
  dueDate: string;
  issues: Omit<CreateIssue, 'isValid'>[];
};

export type CreateRmrIssueData = {
  title: string;
  description: string;
  dasIds: string[];
};

export type UpdateRmrIssueData = Omit<CreateRmrIssueData, 'dasIds'> & {
  id: string;
  dasIds: {
    $remove: string[];
    $add: string[];
  };
};

export type CreateRmrCauseData = {
  id: string;
  title: string;
  description: string;
  dasIds: string[];
};

export type CreateRmrSolutionData = {
  id: string;
  title: string;
  description: string;
  dasIds: string[];
};

export type UpdateRmrTicketData = {
  customer: string;
  contract: string;
  raisedBy: string;
  dueDate: string;
  participants: {
    $remove?: string[];
    $add?: string[];
  };
  auditor: string;
};

export type DeleteRmrIssueData = {
  issueId: string;
};

export type DeleteRmrCauseData = {
  ticketId: string;
  issueId: string;
  causeId: string;
};

export type DeleteRmrSolutionData = {
  ticketId: string;
  issueId: string;
  causeId: string;
  solutionId: string;
};

export const getRmrTickets = (params?: GetRmrTicketsParams) => {
  return dsmOpsAxios.get<PagedData<GetRmrTicketsResponse>>('/v1/rmrs', {
    params,
  });
};

export const getRmrTicket = (ticketId: string) => {
  return dsmOpsAxios.get<Response<RmrTicket>>(`/v1/rmrs/${ticketId}`);
};

export const updateRmrTicketStatus = (
  ticketId: string,
  status: RmrTicketStatus,
) => {
  return dsmOpsAxios.post(`/v1/rmrs/${ticketId}/status`, {
    status,
  });
};

export const getRmrTicketRevision = (ticketId: string, revisionId: string) => {
  return dsmOpsAxios.get<Response<RmrTicket>>(
    `/v1/rmrs/${ticketId}/revisions/${revisionId}`,
  );
};

export const getParticipants = () => {
  return dsmOpsAxios.get<Response<User[]>>('/v1/rmr/participant/whitelist');
};

export const createRmrTicket = (data: CreateRmrTicket) => {
  return dsmOpsAxios.post('/v1/rmrs', { ...data, type: 'hardware' });
};

export const createIssue = (ticketId: string, data: CreateRmrIssueData) => {
  return dsmOpsAxios.patch(`/v1/rmrs/${ticketId}`, {
    issues: {
      $add: [data],
    },
  });
};

export const createCause = (
  ticketId: string,
  issueId: string,
  data: CreateRmrCauseData,
) => {
  return dsmOpsAxios.patch(`/v1/rmrs/${ticketId}`, {
    issues: {
      $update: [
        {
          id: issueId,
          causes: {
            $add: [data],
          },
        },
      ],
    },
  });
};

export type UpdateRmrCauseData = {
  ticketId: string;
  issueId: string;
  causeId: string;
  data: {
    title: string;
    description: string;
    dasIds: {
      $remove: string[];
      $add: string[];
    };
  };
};

export type UpdateRmrSolutionData = {
  ticketId: string;
  issueId: string;
  causeId: string;
  solutionId: string;
  data: {
    title: string;
    description: string;
    dasIds: {
      $remove: string[];
      $add: string[];
    };
  };
};

export const updateRmrCause = (data: UpdateRmrCauseData) => {
  return dsmOpsAxios.patch(`/v1/rmrs/${data.ticketId}`, {
    issues: {
      $update: [
        {
          id: data.issueId,
          causes: {
            $update: [
              {
                id: data.causeId,
                ...data.data,
              },
            ],
          },
        },
      ],
    },
  });
};

export const updateRmrSolution = (data: UpdateRmrSolutionData) => {
  return dsmOpsAxios.patch(`/v1/rmrs/${data.ticketId}`, {
    issues: {
      $update: [
        {
          id: data.issueId,
          causes: {
            $update: [
              {
                id: data.causeId,
                solutions: {
                  $update: [
                    {
                      id: data.solutionId,
                      ...data.data,
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  });
};

export const createSolution = (
  ticketId: string,
  issueId: string,
  causeId: string,
  data: CreateRmrSolutionData,
) => {
  return dsmOpsAxios.patch(`/v1/rmrs/${ticketId}`, {
    issues: {
      $update: [
        {
          id: issueId,
          causes: {
            $update: [
              {
                id: causeId,
                solutions: {
                  $add: [data],
                },
              },
            ],
          },
        },
      ],
    },
  });
};

export const uploadPhotos = (
  ticketId: string,
  issueId: string,
  causeId: string,
  solutionId: string | undefined,
  files: File[],
) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('photos', file);
  });
  if (solutionId) {
    return dsmOpsAxios.post(
      `/v1/rmrs/${ticketId}/issues/${issueId}/causes/${causeId}/solutions/${solutionId}/photos`,
      formData,
    );
  }
  return dsmOpsAxios.post(
    `/v1/rmrs/${ticketId}/issues/${issueId}/causes/${causeId}/photos`,
    formData,
  );
};

export type RemovePhotosData = {
  ticketId: string;
  issueId: string;
  causeId: string;
  solutionId?: string;
  photoId: string;
};

export const deletePhoto = (data: RemovePhotosData) => {
  if (data.solutionId) {
    return dsmOpsAxios.delete(
      `/v1/rmrs/${data.ticketId}/issues/${data.issueId}/causes/${data.causeId}/solutions/${data.solutionId}/photos/${data.photoId}`,
      {
        timeout: 10000,
      },
    );
  }
  return dsmOpsAxios.delete(
    `/v1/rmrs/${data.ticketId}/issues/${data.issueId}/causes/${data.causeId}/photos/${data.photoId}`,
    {
      timeout: 10000,
    },
  );
};

export const updateRmrTicket = (
  ticketId: string,
  data: UpdateRmrTicketData,
) => {
  return dsmOpsAxios.patch(`/v1/rmrs/${ticketId}`, {
    ...data,
    type: 'hardware',
  });
};

export const updateRmrIssue = (ticketId: string, data: UpdateRmrIssueData) => {
  return dsmOpsAxios.patch(`/v1/rmrs/${ticketId}`, {
    issues: {
      $update: [data],
    },
  });
};

export const deleteRmrIssue = (ticketId: string, data: DeleteRmrIssueData) => {
  return dsmOpsAxios.patch(`/v1/rmrs/${ticketId}`, {
    issues: {
      $remove: [data.issueId],
    },
  });
};

export const deleteRmrCause = (data: DeleteRmrCauseData) => {
  return dsmOpsAxios.patch(`/v1/rmrs/${data.ticketId}`, {
    issues: {
      $update: [
        {
          id: data.issueId,
          causes: {
            $remove: [data.causeId],
          },
        },
      ],
    },
  });
};

export const deleteRmrSolution = (data: DeleteRmrSolutionData) => {
  return dsmOpsAxios.patch(`/v1/rmrs/${data.ticketId}`, {
    issues: {
      $update: [
        {
          id: data.issueId,
          causes: {
            $update: [
              {
                id: data.causeId,
                solutions: {
                  $remove: [data.solutionId],
                },
              },
            ],
          },
        },
      ],
    },
  });
};
