import { useState } from 'react';
import { styled } from '@mui/material';

import Input from '../../../components/Input';

import { inputSx } from '../../../theme';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  & > label {
    padding-top: 15px;
    color: var(--Secondary-60, #a1a1a1);
    font-family: 'Noto Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  & > div {
    & > input {
      color: #3e3e3e;
    }
    & > input::placeholder {
      color: #a1a1a1;
    }
  }
`;

interface CapOffDialogContentProps {
  metadata: {
    [propertyName: string]: any;
  };
  onChange: (propertyName: string, value: any) => void;
}

const CapOffDialogContent: React.FC<CapOffDialogContentProps> = ({
  metadata,
  onChange,
}) => {
  const [capOffMetadata, setCapOffMetadata] = useState<{
    [propertyName: string]: any;
  }>({
    maxAttempts: metadata?.maxAttempts,
    maxPeriod: metadata?.maxPeriod,
  });

  return (
    <Container>
      <label>Max Period (mins)</label>
      <Input
        sx={{ ...inputSx }}
        value={capOffMetadata?.maxPeriod || ''}
        placeholder="Default: 5"
        onChange={(e) => {
          setCapOffMetadata({
            ...capOffMetadata,
            maxPeriod: Number(e.currentTarget.value),
          });
          onChange('maxPeriod', e.currentTarget.value);
        }}
      />
      <label>Max number of alerts</label>
      <Input
        sx={{
          ...inputSx,
        }}
        value={capOffMetadata?.maxAttempts || ''}
        placeholder="Default: 5"
        onChange={(e) => {
          setCapOffMetadata({
            ...capOffMetadata,
            maxAttempts: Number(e.currentTarget.value),
          });
          onChange('maxAttempts', e.currentTarget.value);
        }}
      />
    </Container>
  );
};

export default CapOffDialogContent;
