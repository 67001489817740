import { RouteObject } from 'react-router-dom';

import OrgSetting from '../../pages/org-setting';
import OrgInfoDialog from '../../pages/org-setting/orgId';

const orgSettingRouter: RouteObject = {
  path: 'org-setting',
  element: <OrgSetting />,
  children: [
    {
      path: ':orgId',
      element: <OrgInfoDialog />,
    },
  ],
};

export default orgSettingRouter;
