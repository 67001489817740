import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@beeinventor/dasiot-react-component-lib';
import {
  CircularProgress,
  ClickAwayListener,
  Popper as MuiPopper,
  styled,
  Tooltip,
} from '@mui/material';

import { DasEndpoint } from '../types/Device';

import { memberButtonSx } from '../theme';
import { mapToDeviceUrl, parseDasId } from '../utils/common';

import AddSvgIcon from './SvgIcon/AddSvgIcon';
import QuerySvgIcon from './SvgIcon/QuerySvgIcon';

const Container = styled('div')(() => {
  return {
    display: 'inline-block',
  };
});

const Popper = styled(MuiPopper)(({ theme }) => {
  return {
    color: theme.color.secondary.$100,
    background: '#fff',
    zIndex: '2000',
    padding: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',

    '& .loading': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '12px',
    },
    '& > div': {
      '& > .query-wrapper': {
        display: 'flex',
        alignItems: 'center',
        minWidth: '250px',
        background: theme.color.box_bbg,
        borderRadius: '4px',
        '& > input': {
          fontSize: '0.875rem',
          color: theme.color.secondary.$100,
          background: 'transparent',
          border: 'none',
          outline: 'none',
          margin: '0 4px',
        },
      },
      '& > .list-wrapper': {
        maxHeight: '220px',
        overflow: 'auto',
        '& > .item': {
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          border: `1px solid ${theme.color.gray.$245}`,
          borderRadius: '4px',
          margin: '4px 0',
          '&:last-of-type': {
            marginBottom: 0,
          },
          '& > .avatar': {
            width: '32px',
            height: '32px',
            objectFit: 'cover',
            margin: '8px',
          },
          '& > div': {
            flex: 1,
            padding: '0 8px 0 0',
            '& .title': {
              fontSize: '0.75rem',
              fontWeight: '500',
            },
            '& .email': {
              fontSize: '0.75rem',
            },
          },
        },
      },
    },
  };
});

interface ListWrapperProps {
  items: ReactNode;
  onFetchNextPage: () => void;
  hasNextPage: boolean;
}

const ListWrapper: FC<ListWrapperProps> = ({
  items,
  onFetchNextPage,
  hasNextPage,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const buttomRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let observer: IntersectionObserver;

    if (contentRef.current && buttomRef.current && hasNextPage) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              onFetchNextPage();
            }
          });
        },
        {
          root: contentRef.current,
          rootMargin: '48px',
        },
      );

      observer.observe(buttomRef.current);
    }

    return () => {
      if (contentRef.current && buttomRef.current) {
        observer?.unobserve(buttomRef.current);
      }
    };
  }, [hasNextPage]);

  return (
    <div ref={contentRef} className="list-wrapper">
      {items}
      {hasNextPage && (
        <div className="loading">
          <CircularProgress size={24} color="primary" />
        </div>
      )}
      <div ref={buttomRef} className="bottom" />
    </div>
  );
};

interface DeviceAssignmentProps {
  prefixKey?: string;
  filter?: string;
  assignMap: { [id: string]: DasEndpoint } | undefined;
  selectedList: string[];
  disabled?: boolean;
  disableTooltip?: boolean;
  hasNextPage: boolean | undefined;
  onSelect: (dasId: string) => void;
  onFilterChange: (text: string) => void;
  onFetchNextPage: () => void;
}

const DeviceAssignment: FC<DeviceAssignmentProps> = ({
  filter = '',
  prefixKey = '',
  assignMap = {},
  selectedList,
  disabled = false,
  disableTooltip = false,
  hasNextPage = false,
  onSelect,
  onFilterChange,
  onFetchNextPage,
}) => {
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const items = useMemo(() => {
    return Object.values(assignMap)
      .filter((dasendpoint) => {
        let isMatch = false;

        for (let i = 0; i < selectedList.length; i++) {
          if (selectedList[i] === dasendpoint.dasId) {
            isMatch = true;
            break;
          }
        }

        return !isMatch;
      })
      .filter((dasendpoint) => {
        if (filter === '') return true;

        return dasendpoint.dasId?.includes(filter);
      })
      .map((dasendpoint) => {
        return (
          <Tooltip
            key={`${prefixKey}-dasendpoint-item-${dasendpoint.id}`}
            title={
              <pre className="metadata">
                {JSON.stringify(dasendpoint.metadata, undefined, 2)}
              </pre>
            }
            arrow
            placement="right"
            disableHoverListener={disableTooltip}
            enterDelay={500}
            PopperProps={{
              sx: () => ({
                zIndex: 2100,
                '& .MuiTooltip-tooltip': {
                  maxWidth: 'unset',
                },
                '& .metadata': {
                  fontFamily: '"Noto Sans Mono", monospace',
                },
              }),
            }}
          >
            <div className="item" onClick={() => onSelect(dasendpoint.dasId)}>
              <img
                className="avatar"
                src={mapToDeviceUrl(parseDasId(dasendpoint.dasId))}
              />
              <div>
                <div className="title">{dasendpoint.dasId}</div>
              </div>
            </div>
          </Tooltip>
        );
      });
  }, [selectedList, assignMap, filter, onSelect]);

  return (
    <>
      <Container ref={buttonRef}>
        <Button
          sx={memberButtonSx}
          variant="contained"
          color="primary"
          endIcon={<AddSvgIcon sx={{ width: '32px', height: '32px' }} />}
          disabled={disabled}
          onClick={() => setOpen(!open)}
        >
          ADD DEVICE
        </Button>
      </Container>
      <Popper
        anchorEl={buttonRef.current}
        open={open}
        placement="bottom-start"
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <div className="device-assignment-popper-container">
            <div className="query-wrapper">
              <QuerySvgIcon sx={{ width: '32px', height: '32px' }} />{' '}
              <input
                value={filter}
                onChange={(e) => {
                  onFilterChange(e.currentTarget.value);
                }}
              />
            </div>
            <ListWrapper
              items={items}
              onFetchNextPage={onFetchNextPage}
              hasNextPage={hasNextPage}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default DeviceAssignment;
