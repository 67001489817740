import { SvgIcon, SvgIconProps } from '@mui/material';

const HistoryVersionSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M15.9995 12V16"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06619 19.9911C10.0345 21.6732 11.5813 22.946 13.4185 23.5722C15.2556 24.1984 17.2577 24.1353 19.0518 23.3948C20.8459 22.6543 22.3096 21.2868 23.1702 19.547C24.0308 17.8073 24.2295 15.8141 23.7294 13.9387C23.2293 12.0633 22.0644 10.4337 20.4519 9.3535C18.8393 8.27328 16.8891 7.81614 14.9645 8.06726C13.0399 8.31838 11.2722 9.26065 9.99078 10.7185C8.70938 12.1763 8.00167 14.0502 7.99951 15.9911"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.4995 14L7.99951 16L5.99951 13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9995 16L17.9995 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default HistoryVersionSvgIcon;
