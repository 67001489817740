import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import {
  Button,
  DialogButton,
  Dropdown,
} from '@beeinventor/dasiot-react-component-lib';
import { CircularProgress, styled } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';

import { DasEndpoint, DasEndpointType } from '../../../types/Device';
import { SystemState } from '../../../types/Store';

import { getDasEndpoints } from '../../../apis/dasEndpointApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useDascasMap from '../../../hooks/useDascasMap';
import { setTransferDeviceMap } from '../../../slices/systemSlice';

import HardwareItem from '../../../components/HardwareItem';
import AddSvgIcon from '../../../components/SvgIcon/AddSvgIcon';

import { deviceTypeList } from '../../../common/device';
import { mapToDeviceUrl } from '../../../utils/common';

import EditAiServerDialog from './edit/EditAiServerDialog';
import EditDasAirDialog from './edit/EditDasAirDialog';
import EditDasAoaTagDialog from './edit/EditDasAoaTagDialog';
import EditDascasDialog from './edit/EditDascasDialog';
import EditDasCasGDialog from './edit/EditDasCasGDialog';
import EditDasConcreteDialog from './edit/EditDasConcreteDialog';
import EditDasCTagDialog from './edit/EditDasCTagDialog';
import EditDasGasDialog from './edit/EditDasGasDialog';
import EditDasLockDialog from './edit/EditDasLockDialog';
import EditDasLoopDialog from './edit/EditDasLoopDialog';
import EditDasPowerDialog from './edit/EditDasPowerDialog';
import EditDasTempDialog from './edit/EditDasTempDialog';
import EditDasTrackDialog from './edit/EditDasTrackDialog';
import EditDasTrackVDialog from './edit/EditDasTrackVDialog';
import EditDasWaterLDialog from './edit/EditDasWaterLDialog';

const deviceFilterList: Array<{
  id: string;
  name: string;
  value: 'all' | DasEndpointType;
}> = [
  {
    id: 'all',
    name: 'All',
    value: 'all',
  },
  ...deviceTypeList,
];

const Container = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 48px)',
    '& > .filter-container': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      background: '#c4c4c4',
      padding: '17px 48px',
      gap: '8px',
      '& > .info': {
        flex: '1 1 50%',
        fontWeight: 'bold',
      },
    },
    '& > .content': {
      flex: '1',
      padding: '46px 48px',
      overflow: 'auto',
      '& > .loading': {
        width: 'auto',
        height: 'auto',
      },

      '& .detail': {
        position: 'absolute',
        zIndex: 10,
        left: 0,
        right: 0,
        backgroundColor: '#E5E5E5',
        padding: '0 32px 0 112px',
        marginBottom: '8px',

        '& > .row': {
          display: 'flex',
          alignItems: 'center',
          '& > span.title': {
            minWidth: '150px',
            lineHeight: 1.5,
            fontWeight: '500',
            color: '3E3E3E',
          },
          '& > span.dasId': {
            display: 'inline-block',
            color: '#A1A1A1',
            margin: '0 0 0 8px',
          },
        },

        '& img': {
          width: '50px',
          height: '50px',
        },
      },
    },
    '& > .operation-container': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '0.875rem',
      lineHeight: 1.5,
      background: theme.color.secondary.$40,
      padding: '16px',
      borderRadius: '10px 10px 0px 0px',
      margin: '0 48px',
      '& > .button-container': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '10px',
      },
    },
  };
});

const DasEndpointPage = () => {
  const dispatch = useAppDispatch();
  const { isHidden: isSidebarHidden } = useAppSelector(
    (store) => store.system.sidebar,
  );
  const transferDeviceMap = useAppSelector(
    (store) => store.system.transferDeviceMap,
  );
  const location = useLocation();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const filterText = useAppSelector((store) => store.system.filter.text);
  const [total, setTotal] = useState(0);
  const [deviceMap, setDasIdMap] = useState<SystemState['transferDeviceMap']>(
    {},
  );

  const [isNeedLoadMore, setIsNeedLoadMore] = useState(false);
  const [deviceType, setDasEndpointType] = useState<DasEndpointType>();
  const [contentSize, setContentSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 300,
    height: 200,
  });
  const [isExpandSet, setIsExpandSet] = useState<{ [id: string]: boolean }>({});
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedDasEndpoint, setSelectedDasEndpoint] = useState<
    DasEndpoint & {
      bindingDascasGMap?: { [id: string]: DasEndpoint | undefined };
    }
  >();
  const {
    data: getEndpointsResponse,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['get-dasendpoints', deviceType, filterText],
    queryFn: async ({ pageParam }) => {
      const res = await getDasEndpoints({
        type: deviceType,
        dasId: filterText ? filterText : undefined,
        nextCursor: pageParam,
      });
      setIsNeedLoadMore(false);
      return res;
    },
    enabled: location.pathname === '/devices/das-endpoint',
    getNextPageParam: (res) => res.data.paging.nextCursor,
    onSuccess: (res) => {
      setTotal(res.pages[0].data.metadata.total);
    },
    refetchOnWindowFocus: false,
  });

  const {
    data: dascasMap,
    isLoading: isDascasMapLoading,
    isRefetching: isDascasMapRefetching,
    refetct: refetctDascasMap,
  } = useDascasMap();

  useEffect(() => {
    if (isNeedLoadMore && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isNeedLoadMore, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    setDasIdMap(transferDeviceMap);
  }, [transferDeviceMap]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          setContentSize({
            width:
              entry.contentBoxSize[0].inlineSize -
              (isSidebarHidden ? 0 : 270) -
              96,
            height: contentRef.current?.clientHeight
              ? contentRef.current?.clientHeight - 72
              : 172,
          });
        }
      }
    });

    if (contentRef.current) {
      resizeObserver.observe(document.body);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [isSidebarHidden]);

  const dasEndpoints = useMemo(() => {
    return getEndpointsResponse?.pages.map((res) => res.data.data).flat();
  }, [getEndpointsResponse, deviceMap, dascasMap]);

  const selectedDevicesCount = Object.values(deviceMap).reduce((prev, curr) => {
    if (curr?.isSelected) {
      prev++;
    }
    return prev;
  }, 0);

  const Row = useCallback(
    ({ index, style }) => {
      const dasEndpoint = dasEndpoints?.[index];
      if (!dasEndpoint) return null;
      let dascas:
        | (DasEndpoint & {
            bindingDascasGMap: { [id: string]: DasEndpoint | undefined };
          })
        | undefined;
      const dascasGList: ReactNode[] = [];
      if (dasEndpoint.type === 'dascas') {
        dascas = dascasMap?.[dasEndpoint.id];
        if (dascas) {
          Object.values(dascas?.bindingDascasGMap).forEach((d) => {
            dascasGList.push(
              <div key={`dascas-g-${d?.id}`} className="row">
                <img src={mapToDeviceUrl('dascas_g')} />
                <span className="title">Main Detector</span>
                <span className="dasId">{d?.dasId}</span>
              </div>,
            );
          });
        }
      }
      return (
        <div
          key={`dasEndpoint-${dasEndpoint.id}`}
          style={style}
          ref={() => {
            if (index === dasEndpoints.length - 1) {
              setIsNeedLoadMore(true);
            }
          }}
        >
          <HardwareItem
            number={index + 1}
            data={dasEndpoint}
            editable
            displayExpandButton={dasEndpoint.type === 'dascas'}
            isExpand={isExpandSet[dasEndpoint.id]}
            isSelected={deviceMap[dasEndpoint.dasId]?.isSelected ?? false}
            onSelect={(data, value) => {
              setDasIdMap((origin) => {
                return {
                  ...origin,
                  [data.dasId]: {
                    id: data.id,
                    isSelected: value,
                  },
                };
              });
            }}
            onExpand={(isExpand) => {
              setIsExpandSet((origin) => {
                return {
                  ...origin,
                  [dasEndpoint.id]: isExpand,
                };
              });
            }}
            onEdit={() => {
              setIsEditDialogOpen(true);
              if (dasEndpoint.type === 'dascas') {
                setSelectedDasEndpoint(dascas);
              } else {
                setSelectedDasEndpoint(dasEndpoint);
              }
            }}
          />
          {dascas && isExpandSet[dasEndpoint.id] && (
            <div className="detail">
              <div className="row">
                <img src={mapToDeviceUrl('dastrack')} />
                <span className="title">Cabin Alarm</span>
                <span className="dasId">{dascas.dasId}</span>
              </div>
              <div className="row">
                <img src={mapToDeviceUrl('hock-tag')} />
                <span className="title">Hock Tag</span>
                <span className="dasId">{dascas.metadata.collisionId}</span>
              </div>
              <div className="row">
                <img src={mapToDeviceUrl('hock-tag')} />
                <span className="title">Hock Tag</span>
                <span className="dasId">
                  {dascas.metadata.backupCollisionId}
                </span>
              </div>
              {dascasGList}
            </div>
          )}
        </div>
      );
    },
    [dasEndpoints, isExpandSet, deviceMap, dascasMap],
  );

  let EditDeviceDialog: React.FC<any> | undefined;
  switch (selectedDasEndpoint?.type) {
    case 'dasloop':
      EditDeviceDialog = EditDasLoopDialog;
      break;
    case 'dastrack':
      EditDeviceDialog = EditDasTrackDialog;
      break;
    case 'dastrack_v':
      EditDeviceDialog = EditDasTrackVDialog;
      break;
    case 'dastemp':
      EditDeviceDialog = EditDasTempDialog;
      break;
    case 'daspower':
      EditDeviceDialog = EditDasPowerDialog;
      break;
    case 'dasair':
      EditDeviceDialog = EditDasAirDialog;
      break;
    case 'das_aoa_tag':
      EditDeviceDialog = EditDasAoaTagDialog;
      break;
    case 'daswater':
      EditDeviceDialog = EditDasWaterLDialog;
      break;
    case 'daslock':
      EditDeviceDialog = EditDasLockDialog;
      break;
    case 'dasgas':
      EditDeviceDialog = EditDasGasDialog;
      break;
    case 'dasconcrete':
      EditDeviceDialog = EditDasConcreteDialog;
      break;
    case 'dascas_g':
      EditDeviceDialog = EditDasCasGDialog;
      break;
    case 'das_ai_box':
      EditDeviceDialog = EditAiServerDialog;
      break;
    case 'das_collision_tag':
      EditDeviceDialog = EditDasCTagDialog;
      break;
  }

  return (
    <>
      <Container>
        <div className="filter-container">
          <Dropdown
            sx={{
              display: 'inline-flex',
              maxWidth: '220px',
              paddingTop: 0,
              paddingBottom: 0,
            }}
            selectedId={deviceType}
            list={deviceFilterList}
            placeholder="Device Type"
            popperProps={{
              sx: {
                color: '#606060',
              },
            }}
            onSelect={(v) => {
              setDasEndpointType(() => {
                if (v === 'all') return undefined;
                return v as DasEndpointType;
              });
            }}
          />
          <div className="info">Total: {total}</div>
          <Button
            sx={{
              '& a': {
                color: '#fff',
                textDecoration: 'none',
              },
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              //
            }}
            endIcon={<AddSvgIcon />}
          >
            <Link to="import">Import Excel</Link>
          </Button>
          <Button
            sx={{
              '& a': {
                color: '#fff',
                textDecoration: 'none',
              },
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              //
            }}
            endIcon={<AddSvgIcon />}
          >
            <Link to="create">Add DasEndpoint</Link>
          </Button>
        </div>
        <div ref={contentRef} className="content">
          {isLoading || isDascasMapLoading ? (
            <div className="loading">
              <CircularProgress color="primary" />
            </div>
          ) : (
            <FixedSizeList
              width={contentSize.width}
              height={contentSize.height}
              itemCount={dasEndpoints?.length ?? 0}
              itemSize={72}
            >
              {Row}
            </FixedSizeList>
          )}
          {(isDascasMapRefetching || isFetchingNextPage) && (
            <div className="loading">
              <CircularProgress color="primary" />
            </div>
          )}
        </div>
        {selectedDevicesCount > 0 && (
          <div className="operation-container">
            <span>
              {selectedDevicesCount}{' '}
              {selectedDevicesCount === 1 ? 'device' : 'devices'} selected
            </span>
            <div className="button-container">
              <DialogButton
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(setTransferDeviceMap(deviceMap));
                  navigate('export-qrcode');
                }}
              >
                Export QRCode
              </DialogButton>
              <DialogButton
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(setTransferDeviceMap(deviceMap));
                  navigate('transfer-device');
                }}
              >
                Transfer
              </DialogButton>
            </div>
          </div>
        )}
        <Outlet />
      </Container>
      {selectedDasEndpoint?.type === 'dascas' && (
        <EditDascasDialog
          open={isEditDialogOpen}
          dascas={
            selectedDasEndpoint as
              | (DasEndpoint & {
                  bindingDascasGMap: { [id: string]: DasEndpoint | undefined };
                })
              | undefined
          }
          onClose={() => {
            setSelectedDasEndpoint(undefined);
            setIsEditDialogOpen(false);
          }}
          onEditSuccess={() => {
            refetch();
            refetctDascasMap();
          }}
        />
      )}
      {EditDeviceDialog && (
        <EditDeviceDialog
          open={isEditDialogOpen}
          device={selectedDasEndpoint}
          onClose={() => {
            setSelectedDasEndpoint(undefined);
            setIsEditDialogOpen(false);
          }}
          onEditSuccess={() => {
            refetch();
            refetctDascasMap();
          }}
        />
      )}
    </>
  );
};

export default DasEndpointPage;
