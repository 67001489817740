import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SystemState } from '../types/Store';

const initialState: SystemState = {
  selectedProject: undefined,
  projectElevation: undefined,
  memberMap: {},
  filter: {
    text: '',
    hardware: {
      dueDate: null,
      projectDateFilter: 'all',
      statusFilter: 'all',
    },
  },
  needRefresh: false,
  transferEmail: null,
  transferDeviceMap: {},
  transferReserveSetting: false,
  sidebar: {
    isHidden: false,
  },
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setFilterText: (
      state,
      action: PayloadAction<SystemState['filter']['text']>,
    ) => {
      return {
        ...state,
        filter: {
          ...state.filter,
          text: action.payload,
        },
      };
    },
    setHardwareFilter: (
      state,
      action: PayloadAction<Partial<SystemState['filter']['hardware']>>,
    ) => {
      return {
        ...state,
        filter: {
          ...state.filter,
          hardware: {
            ...state.filter.hardware,
            ...action.payload,
          },
        },
      };
    },
    setMemberMap: (state, action: PayloadAction<SystemState['memberMap']>) => {
      return {
        ...state,
        memberMap: action.payload,
      };
    },
    setSelectedProject: (
      state,
      action: PayloadAction<SystemState['selectedProject']>,
    ) => {
      return {
        ...state,
        selectedProject: action.payload,
      };
    },
    setProjectElevation: (
      state,
      action: PayloadAction<SystemState['projectElevation']>,
    ) => {
      return {
        ...state,
        projectElevation: action.payload,
      };
    },
    setNeedRefresh: (
      state,
      action: PayloadAction<SystemState['needRefresh']>,
    ) => {
      return {
        ...state,
        needRefresh: action.payload,
      };
    },
    setTransferDeviceMap: (
      state,
      action: PayloadAction<SystemState['transferDeviceMap']>,
    ) => {
      return {
        ...state,
        transferDeviceMap: action.payload,
      };
    },
    setTransferEmail: (
      state,
      action: PayloadAction<SystemState['transferEmail']>,
    ) => {
      return {
        ...state,
        transferEmail: action.payload,
      };
    },
    setTransferReserveSetting: (
      state,
      action: PayloadAction<SystemState['transferReserveSetting']>,
    ) => {
      return {
        ...state,
        transferReserveSetting: action.payload,
      };
    },
    setSidebar: (state, action: PayloadAction<SystemState['sidebar']>) => {
      return {
        ...state,
        sidebar: action.payload,
      };
    },
  },
});

export const {
  setHardwareFilter,
  setFilterText,
  setMemberMap,
  setSelectedProject,
  setProjectElevation,
  setNeedRefresh,
  setTransferDeviceMap,
  setTransferEmail,
  setTransferReserveSetting,
  setSidebar,
} = systemSlice.actions;

export default systemSlice.reducer;
