import { RouteObject } from 'react-router-dom';

import TaskListPage from '../../pages/task-list';
import TaskInformationDialog from '../../pages/task-list/info';

const taskListRouter: RouteObject = {
  path: 'task-list',
  element: <TaskListPage />,
  children: [
    {
      path: ':taskId/info',
      element: <TaskInformationDialog />,
    },
  ],
};

export default taskListRouter;
