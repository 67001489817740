import { FC } from 'react';
import { styled } from '@mui/material';

import { DasEndpointType } from '../types/Device';

import { mapToDeviceUrl } from '../utils/common';

import CrossSvgIcon from './SvgIcon/CrossSvgIcon';

const Container = styled('div')(({ theme }) => {
  return {
    ...theme.typography.caption,
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.color.secondary.$100,
    backgroundColor: '#FFF',
    border: `1px solid ${theme.color.gray.$245}`,
    borderRadius: '4px',

    '&[data-mode="view"]': {
      paddingRight: '8px',
    },

    '& > img, & > svg': {
      verticalAlign: 'middle',
    },
  };
});

interface DeviceTagProps {
  type?: DasEndpointType;
  dasId: string;
  mode?: 'view' | 'edit';
  onDelete?: (dasId: string) => void;
}

export const DeviceTag: FC<DeviceTagProps> = ({
  type,
  dasId,
  mode = 'view',
  onDelete,
}) => {
  return (
    <Container data-mode={mode}>
      <img src={mapToDeviceUrl(type)} />
      {dasId}
      {mode === 'edit' && (
        <CrossSvgIcon
          sx={{ cursor: 'pointer', width: '32px', height: '32px' }}
          onClick={() => onDelete?.(dasId)}
        />
      )}
    </Container>
  );
};

export default DeviceTag;
