import { Avatar, Box, BoxProps, styled } from '@mui/material';

const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 9px 0;
  margin: 0 36px 10px;

  & > .name {
    color: ${({ theme }) => theme.color.secondary.$100};
  }
  & > .email {
    color: ${({ theme }) => theme.color.secondary.$80};
  }
`;

interface OrgMemberItemProps extends BoxProps {
  imageUrl?: string;
  name: string | undefined;
  email?: string;
}

const OrgMemberItem: React.FC<OrgMemberItemProps> = ({
  imageUrl,
  name,
  email,
}) => {
  return (
    <Container>
      <Avatar sx={{ width: '32px', height: '32px' }} src={imageUrl} />
      <span className="name">{name}</span>
      <span className="email">{email}</span>
    </Container>
  );
};

export default OrgMemberItem;
