import type { SVGProps } from 'react';

const DaspowerSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M23.146 12.65h-6.288a.304.304 0 0 1-.22-.096.284.284 0 0 1-.073-.224l.976-7.349a.852.852 0 0 0-.134-.576.9.9 0 0 0-.483-.362.93.93 0 0 0-.61.018.894.894 0 0 0-.458.39l-7.75 13.651a.808.808 0 0 0 .019.83c.078.126.189.228.32.298a.88.88 0 0 0 .433.101h6.291a.305.305 0 0 1 .22.097.285.285 0 0 1 .073.224l-1.007 7.349a.85.85 0 0 0 .129.589.9.9 0 0 0 .49.369.931.931 0 0 0 .622-.026.892.892 0 0 0 .457-.41l7.745-13.651a.808.808 0 0 0-.019-.817.844.844 0 0 0-.311-.296.878.878 0 0 0-.422-.108Z"
    />
  </svg>
);
export default DaspowerSvgIcon;
