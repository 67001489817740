import { Auth } from '../types/Auth';

import { authAxios, dsmOpsAxios } from '.';

export const login = async (username: string, password: string) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('username', username);
  urlencoded.append('password', password);
  urlencoded.append('grant_type', 'password');
  urlencoded.append('client_id', import.meta.env.VITE_AUTH_CLIENT_ID);
  return authAxios.post<Auth>('/token', urlencoded, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const logout = async () => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('client_id', import.meta.env.VITE_AUTH_CLIENT_ID);
  urlencoded.append(
    'refresh_token',
    localStorage.getItem('refresh_token') ?? '',
  );
  return authAxios.post('/logout', urlencoded, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const refresh = async () => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('client_id', import.meta.env.VITE_AUTH_CLIENT_ID);
  urlencoded.append('grant_type', 'refresh_token');
  urlencoded.append(
    'refresh_token',
    localStorage.getItem('refresh_token') ?? '',
  );
  return authAxios.post<Auth>('/token', urlencoded, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const me = async () => {
  return dsmOpsAxios.get('/v1/me');
};
