import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { CircularProgress, Dialog as MuiDialog, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { endOfDay } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { ErrorState } from '../../../types';
import { CreateIssue } from '../../../types/RmrTicket';
import { User } from '../../../types/User';

import {
  CreateRmrTicket,
  createRmrTicket,
  getParticipants,
} from '../../../apis/rmrApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setMemberMap } from '../../../slices/systemSlice';

import AddButton from '../../../components/AddButton';
import Assignment from '../../../components/Assignment';
import Input from '../../../components/Input';
import MemeberTag from '../../../components/MemberTag';
import AddSvgIcon from '../../../components/SvgIcon/AddSvgIcon';
import CrossSvgIcon from '../../../components/SvgIcon/CrossSvgIcon';

import { datePickerPopperSx, inputSx } from '../../../theme';

import EditIssueView from './EditIssueView';

const Dialog = styled(MuiDialog)(({ theme }) => {
  return {
    '& .MuiPaper-root': {
      minWidth: '600px',
      '& > .header': {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        height: '64px',
        padding: '16px',
        backgroundColor: theme.color.box_bbg,
        '& > .title': {
          ...theme.typography.body1,
          flex: 1,
          fontWeight: '500',
          textAlign: 'center',
        },
        '& > .close-icon': {
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          '&:before': {
            display: 'block',
            content: '""',
            width: '2px',
            height: '30px',
            backgroundColor: theme.color.secondary.$40,
            marginRight: '20px',
          },
        },
      },
      '& > .banner': {
        color: '#fff',
        fontSize: '1rem',
        padding: '16px',
        '&[data-status="error"]': {
          background: theme.color.highlight,
        },
        '&[data-status="success"]': {
          background: theme.color.green.$100,
        },
      },
      '& > .content': {
        maxHeight: '640px',
        padding: '24px',
        overflow: 'auto',
        '& > div': {
          margin: '16px 0',
          '&:first-of-type': {
            marginTop: '0',
          },
          '& > label': {
            fontSize: '0.875rem',
            color: theme.color.secondary.$60,
            '& > span': {
              color: '#FF6B00',
            },
          },
          '& > .member-wrapper': {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            gap: '8px',
            margin: '8px 0 16px 0',
          },
        },
      },
      '& > .action': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px',
      },
    },
  };
});

const CreateRmrTIcketDialog = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const createRmrTicketMutation = useMutation({
    mutationFn: async (data: CreateRmrTicket) => {
      return createRmrTicket(data);
    },
    onMutate: () => {
      setResposeSatusResult({
        status: 'success',
        message: '',
      });
    },
    onSuccess: () => {
      setResposeSatusResult({
        status: 'success',
        message: 'Create success',
      });
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    },
    onError: (error: AxiosError<{ error: { message: string } }>) => {
      setResposeSatusResult({
        status: 'error',
        message: error.response?.data.error.message ?? error.message,
      });
    },
  });
  const memberMap = useAppSelector((store) => store.system.memberMap);
  // const [status, setStatus] = useState<RmrTicketStatus>('open');
  const [selectedParticipantIds, setSelectedParticipantIds] = useState<
    string[]
  >([]);
  const [selectedAuditorIds, setSelectedAuditorIds] = useState<string[]>([]);
  const [customerName, setCustomerName] = useState('');
  const [customerNameError, setCustomerNameError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [contractNumber, setContractNumber] = useState('');
  const [contractNumberError, setContractNumberError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [dueDate, setDueDate] = useState<Date | null | undefined>(
    endOfDay(new Date()),
  );
  const [dueDateError, setDueDateError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [proposer, setProposer] = useState('');
  const [proposerError, setProposerError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [issues, setIssues] = useState<Array<{ id: string } & CreateIssue>>([
    {
      id: uuidv4(),
      dasIds: [],
      title: '',
      description: '',
      isValid: false,
    },
  ]);
  const [resposeSatusResult, setResposeSatusResult] = useState<{
    status: 'success' | 'error';
    message: string;
  }>({
    status: 'success',
    message: '',
  });

  useQuery(
    ['get-member'],
    async () => {
      const result = await getParticipants();
      return result.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const map = data.reduce<{ [id: string]: User }>((prev, curr) => {
          prev[curr.id] = curr;
          return prev;
        }, {});

        dispatch(setMemberMap(map));
      },
    },
  );

  const handleOnSelectParticipant = useCallback((id: string) => {
    setSelectedParticipantIds((pArr) => [...pArr, id]);
  }, []);

  const handleOnRemoveSelectedParticipantId = useCallback((id: string) => {
    setSelectedParticipantIds((pArr) => pArr.filter((pId) => pId !== id));
  }, []);

  const handleOnSelectAuditor = useCallback((id: string) => {
    setSelectedAuditorIds((arr) => [...arr, id]);
  }, []);

  const handleOnRemoveSelectedAuditorId = useCallback((id: string) => {
    setSelectedAuditorIds((arr) => arr.filter((aId) => aId !== id));
  }, []);

  const participantTags = selectedParticipantIds
    .filter((id) => {
      return !!memberMap[id];
    })
    .map((id) => {
      return (
        <MemeberTag
          key={`participant-${id}`}
          name={`${memberMap[id].firstName} ${memberMap[id].lastName}`}
          onDelete={() => {
            handleOnRemoveSelectedParticipantId(id);
          }}
        />
      );
    });

  const auditorTags = selectedAuditorIds
    .filter((id) => {
      return !!memberMap[id];
    })
    .map((id) => {
      return (
        <MemeberTag
          key={`auditor-${id}`}
          name={`${memberMap[id].firstName} ${memberMap[id].lastName}`}
          onDelete={() => {
            handleOnRemoveSelectedAuditorId(id);
          }}
        />
      );
    });

  const handleOnDeleteIssue = (issueIndex: number) => {
    setIssues(issues.filter((issue, index) => index !== issueIndex));
  };

  const handleOnChangeIssue = (issueIndex: number, issue: CreateIssue) => {
    const temp = [...issues];
    temp[issueIndex] = {
      id: issues[issueIndex].id,
      ...issue,
    };
    setIssues(temp);
  };

  const createIssueComponents = issues.map((issue, index) => {
    return (
      <EditIssueView
        key={`CreateRmrTIcketDialog-edit-issue-view-${issue.id}`}
        onDelete={handleOnDeleteIssue}
        issueIndex={index}
        onChange={(newIssue) => handleOnChangeIssue(index, newIssue)}
      />
    );
  });

  const handleOnCreateIssue = () => {
    setIssues([
      ...issues,
      {
        id: uuidv4(),
        title: '',
        dasIds: [],
        description: '',
        isValid: false,
      },
    ]);
  };

  const handleOnClose = () => {
    navigate(-1);
  };

  const handleCreateRmrTicket = () => {
    if (dueDate) {
      createRmrTicketMutation.mutate({
        customer: customerName,
        contract: contractNumber,
        dueDate: dueDate.toISOString(),
        raisedBy: proposer,
        participants: selectedParticipantIds,
        auditor: selectedAuditorIds[0],
        issues: issues.map((issue) => {
          return {
            title: issue.title,
            dasIds: issue.dasIds,
            description: issue.description,
          };
        }),
      });
    }
  };

  const isValid =
    customerName !== '' &&
    contractNumber !== '' &&
    !!dueDate &&
    proposer !== '' &&
    selectedParticipantIds.length > 0 &&
    selectedAuditorIds.length > 0 &&
    issues.reduce((prev, curr) => {
      return prev && curr.isValid;
    }, true);

  return (
    <Dialog open>
      <div className="header">
        <img className="device-icon" src="/assets/image/svg/rmr-hardware.svg" />
        <span className="title">RETURN MERCHANDISE REPORT</span>
        <div className="close-icon" onClick={handleOnClose}>
          <CrossSvgIcon sx={{ width: '40px', height: '40px' }} />
        </div>
      </div>
      {resposeSatusResult.message !== '' && (
        <div className="banner" data-status={resposeSatusResult.status}>
          {resposeSatusResult.message}
        </div>
      )}
      <div className="content">
        <div>
          <label>
            Customer Name<span>*</span>
          </label>
          <Input
            sx={inputSx}
            value={customerName}
            onChange={(e) => setCustomerName(e.currentTarget.value)}
            onBlur={() => {
              if (customerName === '') {
                setCustomerNameError({
                  isError: true,
                  message: 'Customer name do not empty',
                });
              } else {
                setCustomerNameError({
                  isError: false,
                  message: '',
                });
              }
            }}
            error={customerNameError.isError}
            errorMessage={customerNameError.message}
          />
        </div>
        <div>
          <label>
            Contract Number<span>*</span>
          </label>
          <Input
            sx={inputSx}
            value={contractNumber}
            onChange={(e) => setContractNumber(e.currentTarget.value)}
            onBlur={() => {
              if (contractNumber === '') {
                setContractNumberError({
                  isError: true,
                  message: 'Contract number do not empty',
                });
              } else {
                setContractNumberError({
                  isError: false,
                  message: '',
                });
              }
            }}
            error={contractNumberError.isError}
            errorMessage={contractNumberError.message}
          />
        </div>
        <div>
          <label>
            Due Date<span>*</span>
          </label>
          <DatePicker
            onChange={(date) => {
              if (date instanceof Date && !isNaN(date.getTime())) {
                setDueDate(endOfDay(date));
              }
            }}
            value={dueDate}
            slotProps={{
              popper: {
                sx: datePickerPopperSx,
              },
              textField: {
                size: 'small',
                fullWidth: true,
                error: dueDateError.isError,
                helperText: dueDateError.message,
                placeholder: 'YYYY-MM-DD',
                onBlur: () => {
                  if (!dueDate) {
                    setDueDateError({
                      isError: true,
                      message: 'Due date do not empty',
                    });
                  } else {
                    setDueDateError({
                      isError: false,
                      message: '',
                    });
                  }
                },
              },
            }}
          />
        </div>
        <div>
          <label>
            Proposer<span>*</span>
          </label>
          <Input
            sx={inputSx}
            value={proposer}
            onChange={(e) => setProposer(e.currentTarget.value)}
            onBlur={() => {
              if (proposer === '') {
                setProposerError({
                  isError: true,
                  message: 'Proposer do not empty',
                });
              } else {
                setProposerError({
                  isError: false,
                  message: '',
                });
              }
            }}
            error={proposerError.isError}
            errorMessage={proposerError.message}
          />
        </div>
        <div>
          <label>
            Participants<span>*</span>
          </label>
          <div className="member-wrapper">
            <Assignment
              prefixKey="participant"
              memberMap={memberMap}
              selectedList={selectedParticipantIds}
              onSelect={handleOnSelectParticipant}
            />
            {participantTags}
          </div>
        </div>
        <div>
          <label>
            Auditor<span>*</span>
          </label>
          <div className="member-wrapper">
            <Assignment
              prefixKey="auditor"
              memberMap={memberMap}
              selectedList={selectedAuditorIds}
              disabled={selectedAuditorIds.length >= 1}
              onSelect={handleOnSelectAuditor}
            />
            {auditorTags}
          </div>
        </div>
        {createIssueComponents}
        <AddButton onClick={handleOnCreateIssue}>
          <AddSvgIcon color="primary" /> Add Issue
        </AddButton>
      </div>
      <div className="action">
        <DialogButton
          sx={(theme) => ({ color: theme.color.primary.$100 })}
          variant="text"
          color="primary"
          onClick={handleOnClose}
        >
          Cancel
        </DialogButton>
        <DialogButton
          variant="contained"
          color="primary"
          disabled={!isValid || createRmrTicketMutation.isLoading}
          onClick={handleCreateRmrTicket}
        >
          {createRmrTicketMutation.isLoading ? (
            <CircularProgress sx={{ color: '#fff' }} size={14} />
          ) : (
            'CREATE'
          )}
        </DialogButton>
      </div>
    </Dialog>
  );
};

export default CreateRmrTIcketDialog;
