import { FC, useMemo, useRef, useState } from 'react';
import { Button } from '@beeinventor/dasiot-react-component-lib';
import { ClickAwayListener, Popper as MuiPopper, styled } from '@mui/material';

import { User } from '../types/User';

import { memberButtonSx } from '../theme';

import AddSvgIcon from './SvgIcon/AddSvgIcon';
import QuerySvgIcon from './SvgIcon/QuerySvgIcon';

const Container = styled('div')(() => {
  return {
    display: 'inline-block',
  };
});

const Popper = styled(MuiPopper)(({ theme }) => {
  return {
    color: theme.color.secondary.$100,
    background: '#fff',
    zIndex: '2000',
    padding: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',

    '& > div': {
      '& > .query-wrapper': {
        display: 'flex',
        alignItems: 'center',
        minWidth: '250px',
        background: theme.color.box_bbg,
        borderRadius: '4px',
        '& > input': {
          fontSize: '0.875rem',
          color: theme.color.secondary.$100,
          background: 'transparent',
          border: 'none',
          outline: 'none',
          margin: '0 4px',
        },
      },
      '& > .list-wrapper': {
        maxHeight: '220px',
        overflow: 'auto',
        '& > .item': {
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          border: `1px solid ${theme.color.gray.$245}`,
          borderRadius: '4px',
          margin: '4px 0',
          '&:last-of-type': {
            marginBottom: 0,
          },
          '& > .avatar': {
            width: '32px',
            height: '32px',
            objectFit: 'cover',
            margin: '8px',
          },
          '& > div': {
            flex: 1,
            padding: '0 8px 0 0',
            '& .title': {
              fontSize: '0.75rem',
              fontWeight: '500',
            },
            '& .email': {
              fontSize: '0.75rem',
            },
          },
        },
      },
    },
  };
});

interface AssignmentProps {
  prefixKey?: string;
  memberMap: { [id: string]: User } | undefined;
  selectedList: string[];
  disabled?: boolean;
  onSelect: (id: string) => void;
}

const Assignment: FC<AssignmentProps> = ({
  prefixKey = '',
  memberMap = {},
  selectedList,
  disabled = false,
  onSelect,
}) => {
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [filter, setFilter] = useState('');
  const [open, setOpen] = useState(false);

  const items = useMemo(() => {
    return Object.values(memberMap)
      .filter((member) => {
        let isMatch = false;

        for (let i = 0; i < selectedList.length; i++) {
          if (selectedList[i] === member.id) {
            isMatch = true;
            break;
          }
        }

        return !isMatch;
      })
      .filter((member) => {
        if (filter === '') return true;

        return (
          member.firstName?.includes(filter) ||
          member.lastName?.includes(filter) ||
          member.email.includes(filter)
        );
      })
      .map((member) => {
        return (
          <div
            key={`${prefixKey}-member-item-${member.id}`}
            className="item"
            onClick={() => onSelect(member.id)}
          >
            <img
              className="avatar"
              src={
                member.imageURL
                  ? member.imageURL
                  : '/assets/image/svg/user_default_avatar.svg'
              }
            />
            <div>
              <div className="title">{`${member.firstName} ${member.lastName}`}</div>
              <div className="email">{member.email}</div>
            </div>
          </div>
        );
      });
  }, [selectedList, memberMap, filter, onSelect]);

  return (
    <>
      <Container ref={buttonRef}>
        <Button
          sx={memberButtonSx}
          variant="contained"
          color="primary"
          endIcon={<AddSvgIcon sx={{ width: '32px', height: '32px' }} />}
          disabled={disabled}
          onClick={() => setOpen(!open)}
        >
          ASSIGN
        </Button>
      </Container>
      <Popper
        anchorEl={buttonRef.current}
        open={open}
        placement="bottom-start"
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <div>
            <div className="query-wrapper">
              <QuerySvgIcon sx={{ width: '32px', height: '32px' }} />{' '}
              <input
                value={filter}
                onChange={(e) => {
                  setFilter(e.currentTarget.value);
                }}
              />
            </div>
            <div className="list-wrapper">{items}</div>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default Assignment;
