import { Button, Dropdown } from '@beeinventor/dasiot-react-component-lib';
import { DatePicker } from '@mui/x-date-pickers';
import { endOfDay } from 'date-fns';

import { ProjectDateFilterOption, StatusFilterOption } from '../../../types';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setHardwareFilter } from '../../../slices/systemSlice';

import PageHeaderContainer from '../../../components/PageHeaderContainer';

const projectDateFilterList = [
  {
    id: 'all',
    name: 'All',
    value: 'all',
  },
  {
    id: 'in-time',
    name: 'In Time',
    value: 'in-time',
  },
  {
    id: 'overdue',
    name: 'Overdue',
    value: 'overdue',
  },
];

const statusFilterList = [
  {
    id: 'all',
    name: 'All',
    value: 'all',
  },
  {
    id: 'open',
    name: 'Open',
    value: 'open',
  },
  {
    id: 'inProgress',
    name: 'In Progress',
    value: 'inProgress',
  },
  {
    id: 'completed',
    name: 'Completed',
    value: 'completed',
  },
];

const Header = () => {
  const dispatch = useAppDispatch();
  const { projectDateFilter, statusFilter, dueDate } = useAppSelector(
    (store) => store.system.filter.hardware,
  );

  return (
    <PageHeaderContainer
      sx={{
        '& .divider': {
          width: '2px',
          height: '30px',
          backgroundColor: '#3E3E3E',
        },
      }}
    >
      <Dropdown
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
        selectedId={projectDateFilter === 'all' ? undefined : projectDateFilter}
        list={projectDateFilterList}
        placeholder="Project Date"
        popperProps={{
          sx: {
            color: '#606060',
          },
        }}
        onSelect={(v) => {
          dispatch(
            setHardwareFilter({
              projectDateFilter: v as ProjectDateFilterOption,
            }),
          );
        }}
      />
      <Dropdown
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
        selectedId={statusFilter === 'all' ? undefined : statusFilter}
        list={statusFilterList}
        placeholder="Status"
        popperProps={{
          sx: {
            color: '#606060',
          },
        }}
        onSelect={(v) => {
          dispatch(
            setHardwareFilter({
              statusFilter: v as StatusFilterOption,
            }),
          );
        }}
      />
      <div className="divider"></div>
      <DatePicker
        onChange={(date) => {
          if (date instanceof Date && !isNaN(date.getTime())) {
            dispatch(
              setHardwareFilter({
                dueDate: endOfDay(date).toISOString(),
              }),
            );
          }
        }}
        value={dueDate ? new Date(dueDate) : null}
        label="Due Date: YYYY-MM-DD"
        slotProps={{ textField: { size: 'small' } }}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={
          (dueDate === '' || dueDate === null) &&
          projectDateFilter === 'all' &&
          statusFilter === 'all'
        }
        onClick={() => {
          dispatch(
            setHardwareFilter({
              dueDate: null,
              projectDateFilter: 'all',
              statusFilter: 'all',
            }),
          );
        }}
      >
        Reset
      </Button>
    </PageHeaderContainer>
  );
};

export default Header;
