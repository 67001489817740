import { ErrorState } from '../types';

class ValidatorMetadata {
  static validateTerminalId(value): ErrorState {
    if (value === '') {
      return {
        isError: true,
        message: 'Terminal Id should not be empty',
      };
    } else if (value.length !== 10) {
      return {
        isError: true,
        message: 'Terminal Id is 10 digits',
      };
    } else if (value !== value.toUpperCase()) {
      return {
        isError: true,
        message: 'Terminal Id must be uppercase',
      };
    } else {
      return {
        isError: false,
        message: '',
      };
    }
  }

  static validateGatewayId(value): ErrorState {
    if (value === '') {
      return {
        isError: true,
        message: 'Gateway Id should not be empty',
      };
    } else if (value.length !== 10) {
      return {
        isError: true,
        message: 'Gateway Id is 10 digits',
      };
    } else if (value !== value.toUpperCase()) {
      return {
        isError: true,
        message: 'Gateway Id must be uppercase',
      };
    } else {
      return {
        isError: false,
        message: '',
      };
    }
  }

  static validateReportInterval(value): ErrorState {
    const interval = Number(value);
    if (interval < 20) {
      return {
        isError: false,
        message: 'Report interval should be at least 20 seconds',
      };
    } else {
      return {
        isError: false,
        message: '',
      };
    }
  }

  static validateCollisionId(value): ErrorState {
    if (value === '') {
      return {
        isError: true,
        message: 'CollisionId should not be empty',
      };
    } else {
      return {
        isError: false,
        message: '',
      };
    }
  }

  static validatePhone(value): ErrorState {
    if (value === '') {
      return {
        isError: true,
        message: 'Phone should not be empty',
      };
    } else {
      return {
        isError: false,
        message: '',
      };
    }
  }

  static validateRequired(value): ErrorState {
    if (value === '') {
      return {
        isError: true,
        message: 'Should not be empty',
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validate(value): ErrorState {
    if (value === '') {
      return {
        isError: true,
        message: 'Should not be empty',
      };
    }

    return {
      isError: false,
      message: '',
    };
  }

  static validateHttpUrl(value): ErrorState {
    const regex = /^https?:\/\/?/g;
    if (value === '') {
      return {
        isError: true,
        message: 'Should not be empty',
      };
    } else if (!regex.test(value)) {
      return {
        isError: true,
        message: 'Protocol should be http/https',
      };
    }

    return {
      isError: false,
      message: '',
    };
  }
}

export default ValidatorMetadata;
