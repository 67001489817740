import { RouteObject } from 'react-router-dom';

import LoginPage from '../../pages/login';

export const router: RouteObject[] = [
  {
    path: '/login',
    element: <LoginPage />,
  },
];
