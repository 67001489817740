import { configureStore } from '@reduxjs/toolkit';

import authSliceReducer from './slices/authSlice';
import rmrReducer from './slices/rmrSlice';
import systemReducer from './slices/systemSlice';
import userSliceReducer from './slices/userSlice';

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    user: userSliceReducer,
    system: systemReducer,
    rmr: rmrReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
