import type { SVGProps } from 'react';

const DastempSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m18 15.918-.572.583a4.97 4.97 0 0 0-.21.228l-.92 1.563a5 5 0 1 0 8.273-1.79L24 15.917V10a3 3 0 0 0-6 0v5.918Zm-2-.892-3.913 2.404V21l1.915-1.177A7 7 0 1 0 26 15.101V10a5 5 0 0 0-10 0v.882L11.31 8l-6.559 4.033 6.564 4.028L16 13.185v1.84ZM21 9a1 1 0 0 0-1 1v7.17a3.001 3.001 0 1 0 2 0V10a1 1 0 0 0-1-1ZM4 16.983v-3.57l6.543 4.017V21L4 16.983Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DastempSvgIcon;
