import React, { useState } from 'react';
import { Input, styled } from '@mui/material';

const List = styled('ul')`
  padding: 0;
  & > li {
    display: flex;
    gap: 10px;
    align-items: center;
    list-style-type: none;
  }
`;

interface WebHookDialogContentProps {
  metadata: {
    [proprtyName: string]: any;
  };
  onChange: (proportyName: string, value: string) => void;
}

const WebhookDialogContent: React.FC<WebHookDialogContentProps> = ({
  metadata,
  onChange,
}) => {
  const [webhookMetadata, setWebhookMetaData] = useState<{
    [proprtyName: string]: any;
  }>({ limit: metadata?.limit });

  return (
    <List>
      <div className="text-field">
        <label>Number of Host</label>
        <Input
          value={webhookMetadata?.limit}
          placeholder="Default: 2"
          sx={{ display: 'block' }}
          onChange={(e) => {
            setWebhookMetaData({
              ...webhookMetadata,
              limit: Number(e.target.value),
            });
            onChange('limit', e.target.value);
          }}
        ></Input>
      </div>
    </List>
  );
};

export default WebhookDialogContent;
