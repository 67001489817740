import { Switch } from '@beeinventor/dasiot-react-component-lib';
import { styled } from '@mui/material';

const List = styled('ul')`
  padding: 0;
  & > li {
    display: flex;
    gap: 10px;
    align-items: center;
    list-style-type: none;
  }

  & > .text-field {
    margin-top: 10px;
  }
`;

interface DasLoopDialogContentProps {
  metadata: {
    [propertyName: string]: any;
  };
  onChange: (propertyName: string, value: any) => void;
}

const DasLoopDialogContent: React.FC<DasLoopDialogContentProps> = ({
  metadata,
  onChange,
}) => {
  return (
    <List>
      <li>
        <Switch
          checked={!!metadata.disablePa}
          onChange={(_, checked) => onChange('disablePa', checked)}
        />
        <span>Disable Public Announcement</span>
      </li>
    </List>
  );
};

export default DasLoopDialogContent;
