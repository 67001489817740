import React, { useState } from 'react';
import { styled } from '@mui/material';

import Input from '../../../components/Input';

import { inputSx } from '../../../theme';

interface LostConnectDialogContentProps {
  metadata: {
    [propertyName: string]: any;
  };
  onChange: (propertyName: string, value: any) => void;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  & > label {
    padding-top: 15px;
    color: var(--Secondary-60, #a1a1a1);
    font-family: 'Noto Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  & > textarea {
    padding: 5px;
    width: 100%;
    height: 160px;
    color: #3e3e3e;
    font-family: 'Noto Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background-color: #f3f3f3;
    border: none;
    & > ::placeholder {
      color: var(--Secondary-60, #a1a1a1);
    }
  }
`;

const LostConnectDialogContent: React.FC<LostConnectDialogContentProps> = ({
  metadata,
  onChange,
}) => {
  const [localConnectionLostMetadata, setLocalConnectionLostMetadata] =
    useState({
      max: metadata?.max,
      interval: metadata?.interval,
      exclude: metadata?.exclude,
    });
  return (
    <Container>
      <label>Report Interval (Mins)</label>

      <Input
        sx={inputSx}
        type="number"
        value={localConnectionLostMetadata.interval}
        onChange={(e) => {
          setLocalConnectionLostMetadata({
            ...localConnectionLostMetadata,
            interval: e.currentTarget.value,
          });
          onChange('interval', Number(e.currentTarget.value));
        }}
      />
      <label>Max number of alerts (Times)</label>

      <Input
        type="number"
        sx={inputSx}
        value={localConnectionLostMetadata.max}
        onChange={(e) => {
          setLocalConnectionLostMetadata({
            ...localConnectionLostMetadata,
            max: e.currentTarget.value,
          });
          onChange('max', Number(e.currentTarget.value));
        }}
      />
    </Container>
  );
};

export default LostConnectDialogContent;
