import type { SVGProps } from 'react';

const DaswaterLSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 21H8V6h6v3h-3v1h3v3h-3v1h3v3h-3v1h3v3Zm2.924 5c-2.85 0-5.308-1.639-6.429-4H15V8.157C16.093 6.826 16.924 6 16.924 6S24.001 13.029 24 19.126C24 22.922 20.833 26 16.924 26Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DaswaterLSvgIcon;
