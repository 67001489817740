import { useState } from 'react';
import { styled } from '@mui/material';
import { format } from 'date-fns';

const Container = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px;

  &:nth-of-type(even) {
    background: ${({ theme }) => theme.color.secondary.$40};
  }

  &:hover {
    background: ${({ theme }) => theme.color.secondary.$60};
  }

  & pre {
    cursor: text;
    width: 800px;
  }
`;

interface ReportItemProps {
  data: any;
  filterRegex?: RegExp;
}

const ReportItem: React.FC<ReportItemProps> = ({ data, filterRegex }) => {
  const [isExpand, setIsisExpand] = useState(false);
  let rawDataText = JSON.stringify(data, null, 2);

  if (filterRegex) {
    const lines = rawDataText.split('\n');
    rawDataText = '';
    lines.forEach((line) => {
      if (filterRegex.test(line)) {
        rawDataText += line + '\n';
      }
    });
  }

  let date: Date;
  if (data.timestamp) {
    date = new Date(data.timestamp);
  } else if (data?.shadow?.dataPoint?.timestamp) {
    date = new Date(data.shadow.dataPoint.timestamp);
  } else {
    return null;
  }

  return (
    <Container onClick={() => setIsisExpand(!isExpand)}>
      <div>{format(date, 'yyyy-MM-dd HH:mm:ss')}</div>
      <pre
        style={{ height: isExpand ? 300 : 60 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {rawDataText}
      </pre>
    </Container>
  );
};

export default ReportItem;
