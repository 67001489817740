import type { SVGProps } from 'react';

const DasfindSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.996 4 26 10.004 22.005 12.4A6.996 6.996 0 0 0 16 9a6.996 6.996 0 0 0-6.004 3.399L6 10.004 15.996 4ZM9.162 14.497 5 12v10.5l10 6v-5.57a7.001 7.001 0 0 1-5.838-8.433ZM17 22.929V28.5l10-6V12l-4.162 2.497a7.028 7.028 0 0 1-.317 4.053L24 20.03a2.808 2.808 0 0 1-3.971 3.97l-1.479-1.478a6.952 6.952 0 0 1-1.55.408ZM16 19a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 2c1.09 0 2.097-.348 2.918-.94l2.525 2.525a.808.808 0 0 0 1.142-1.142l-2.524-2.525A5 5 0 1 0 16 21Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DasfindSvgIcon;
