import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RmrTicket } from '../types/RmrTicket';
import { RmrState } from '../types/Store';

const initialState: RmrState = {
  currentRmrTicket: undefined,
};

export const userSlice = createSlice({
  name: 'rmr',
  initialState,
  reducers: {
    setRmrTicket: (state, action: PayloadAction<RmrTicket | undefined>) => {
      return {
        ...state,
        currentRmrTicket: action.payload,
      };
    },
  },
});

export const { setRmrTicket } = userSlice.actions;

export default userSlice.reducer;
