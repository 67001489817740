import { styled } from '@mui/material';
import { format } from 'date-fns';

import { TaskStatus } from '../../types';

import TaskStatusIcon from './TaskStatusIcon';

const Container = styled('div')`
  display: flex;
  align-items: center;
  min-height: 72px;
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.secondary.$60};
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 11px 24px 11px 16px;
  margin: 8px 0;
  gap: 24px;

  & > div {
    &:first-of-type {
      color: ${({ theme }) => theme.color.secondary.$100};
      min-width: 30px;
      font-weight: 500;
      text-align: center;
    }
    &:nth-of-type(2) {
      min-width: 140px;
      display: flex;
      align-items: center;
      font-weight: 700;
    }
    &:nth-of-type(3) {
      flex: 2;
    }
    &:nth-of-type(4) {
      flex: 1;
      text-align: end;
    }
    &:nth-of-type(5) {
      text-align: end;
    }
    &.timestamp {
      & span {
        display: inline-block;
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }
`;

interface TaskItemProps {
  order: number;
  dasId: string;
  status: TaskStatus;
  taskType: 'Firmware Update' | 'Audio Update' | 'Play PA';
  createdAt: string;
}

const TaskItem: React.FC<TaskItemProps> = ({
  order,
  dasId,
  status,
  taskType,
  createdAt,
}) => {
  const date = new Date(createdAt);

  return (
    <Container>
      <div>{order}</div>
      <TaskStatusIcon status={status} />
      <div>{dasId}</div>
      <div>{taskType}</div>
      <div className="timestamp">
        <span>{format(date, 'yyyy - MM - dd')}</span>
        <span>{format(date, 'hh : mm : ss')}</span>
      </div>
    </Container>
  );
};

export default TaskItem;
