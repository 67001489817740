import type { SVGProps } from 'react';

const DataDownloadSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 24C6.79086 24 5 22.2091 5 20C5 18.1358 6.27532 16.5694 8.00111 16.1257C8.00037 16.0839 8 16.042 8 16C8 12.134 11.134 9 15 9C18.174 9 20.8546 11.1125 21.7125 14.0081C21.8077 14.0027 21.9035 14 22 14C24.7614 14 27 16.2386 27 19C27 21.7614 24.7614 24 22 24H9ZM16 13C16.5523 13 17 13.4477 17 14V18.1315L18.4453 17.1679C18.9048 16.8616 19.5257 16.9858 19.8321 17.4453C20.1384 17.9048 20.0142 18.5257 19.5547 18.8321L16.5661 20.8245C16.4065 20.9342 16.2136 20.9989 16.0055 21L16 21L15.9945 21C15.7865 20.9989 15.5935 20.9342 15.4339 20.8245L12.4453 18.8321C11.9858 18.5257 11.8616 17.9048 12.168 17.4453C12.4743 16.9858 13.0952 16.8616 13.5547 17.1679L15 18.1315V14C15 13.4477 15.4477 13 16 13Z"
      fill="currentColor"
    />
  </svg>
);
export default DataDownloadSvgIcon;
