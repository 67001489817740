import { PagedData, Response } from '../types';
import { DasEndpoint, DasEndpointType } from '../types/Device';

import { dsmOpsAxios } from '.';

type CreateDasEndpointData = {
  dasId?: string;
  metadata: { [key: string]: string | number };
};

export const createDasEndpoint = (data?: CreateDasEndpointData) => {
  return dsmOpsAxios.post<PagedData<DasEndpoint>>('/v1/das-endpoints', {
    dasId: data?.dasId,
    ...data?.metadata,
  });
};

type GetDasEndpointParams = {
  type?: DasEndpointType;
  limit?: number;
  dasId?: string;
  nextCursor?: string | null;
  prevCursor?: string | null;
};

export const getDasEndpoints = (params?: GetDasEndpointParams) => {
  return dsmOpsAxios.get<PagedData<DasEndpoint>>('/v1/das-endpoints', {
    params,
  });
};

export type UpdateDasEndpointData = {
  dasId: string;
  payload: { [propertyName: string]: any };
};

export const updateDasEndPoints = (data: UpdateDasEndpointData) => {
  return dsmOpsAxios.patch(`/v1/das-endpoints/${data.dasId}`, data.payload);
};

export const checkDasEndpointExist = (dasId: string) => {
  return dsmOpsAxios.head(`/v1/das-endpoints?dasId=${dasId}`);
};

export type GenerateQrcodePathData = {
  dasIds: string[];
  host: string;
};

export const generateQrcodePath = (data: GenerateQrcodePathData) => {
  return dsmOpsAxios.post<Response<string[]>>(
    `/v1/das-endpoints/qr-code-path`,
    data,
  );
};
