import type { SVGProps } from 'react';

const PublicAccessSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.06 4.94a1.5 1.5 0 0 1 0 2.12l-1 1L28 11l-4 4-2.94-2.94-4.026 4.028a6.5 6.5 0 1 1-2.121-2.121l9.026-9.028a1.5 1.5 0 0 1 2.122 0ZM11.5 23a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PublicAccessSvgIcon;
