import type { SVGProps } from 'react';

const DasConcreteSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.998 6.584a1.5 1.5 0 1 0-1.414 1.414l1.264 1.264a6.983 6.983 0 0 0-1.662 3.127 2 2 0 0 0-2.432 3.175c-.213.466-.385.95-.51 1.45a2 2 0 1 0 0 3.97c.125.502.297.986.51 1.451A2 2 0 0 0 9.2 25.6C10.986 27.09 13.375 28 16 28c2.626 0 5.015-.91 6.8-2.4a2 2 0 0 0 2.446-3.164c.213-.466.385-.95.51-1.45a2 2 0 1 0 0-3.97 8.295 8.295 0 0 0-.51-1.451 2 2 0 0 0-2.432-3.175 6.984 6.984 0 0 0-1.662-3.128l1.264-1.264L22.5 8a1.5 1.5 0 1 0-1.498-1.416l-1.408 1.408A6.968 6.968 0 0 0 16 7a6.968 6.968 0 0 0-3.594.992zM20.5 17a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M13 15.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m7.5 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M13 22.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m3-1.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
      clipRule="evenodd"
    />
  </svg>
);
export default DasConcreteSvgIcon;
