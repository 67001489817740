import { useState } from 'react';
import { Switch } from '@beeinventor/dasiot-react-component-lib';
import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';

import RadioCheckedSvgIcon from '../../../components/SvgIcon/RadioCheckedSvgIcon';
import RadioUncheckedSvgIcon from '../../../components/SvgIcon/RadioUncheckedSvgIcon';

const List = styled('ul')`
  padding: 0;
  & > li {
    display: flex;
    gap: 10px;
    align-items: center;
    list-style-type: none;
  }

  & > .text-field {
    margin-top: 10px;
  }
`;

interface ContainerProps {
  disabled?: boolean;
}
// prettier-ignore
const Container = styled('div', { shouldForwardProp: prop => prop !== 'disabled' })<ContainerProps>`
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: ${({ theme }) => theme.color.gray.$250};
  border: 1px solid ${({ theme }) => theme.color.gray.$235};
  border-radius: 8px;
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  ${({  disabled }) => {
    if (disabled)
      return {
        opacity: "0.5"
      };
  }}
`;

type AttendanceType = 'attendanceReport' | 'workTimeReport';

interface DataDownloadDialogContentProps {
  metadata: {
    [propertyName: string]: any;
  };
  onChange: (propertyName: string, value: any) => void;
}

const DataDownloadDialogContent: React.FC<DataDownloadDialogContentProps> = ({
  metadata,
  onChange,
}) => {
  const [attendanceType, setAttendanceType] = useState<AttendanceType>(
    metadata?.workTimeReport === true ? 'workTimeReport' : 'attendanceReport',
  );

  const handleOnChangeAttendance = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as AttendanceType;

    setAttendanceType(value);
    onChange('attendanceReport', value === 'attendanceReport');
    onChange('workTimeReport', value === 'workTimeReport');
  };

  const onChangeAllAttendance = (checked: any) => {
    if (checked) {
      if (attendanceType === 'attendanceReport') {
        onChange('attendanceReport', true);
        onChange('workTimeReport', false);
      } else {
        onChange('attendanceReport', false);
        onChange('workTimeReport', true);
      }
    } else {
      onChange('allAttendance', false);
    }
  };

  const isCheckedAttendance =
    !!metadata.workTimeReport || !!metadata.attendanceReport;
  return (
    <List>
      <li>
        <Switch
          checked={!!metadata.vod}
          onChange={(_, checked) => onChange('vod', checked)}
        />
        <span>VOD</span>
      </li>
      <li>
        <Switch
          checked={!!metadata.generalReport}
          onChange={(_, checked) => onChange('generalReport', checked)}
        />
        <span>Device Data</span>
      </li>
      <li>
        <Switch
          checked={!!metadata.alertReport}
          onChange={(_, checked) => onChange('alertReport', checked)}
        />
        <span>Alert Report</span>
      </li>
      <li>
        <Switch
          checked={isCheckedAttendance}
          onChange={(_, checked) => onChangeAllAttendance(checked)}
        />
        <span>Worker Report </span>
      </li>
      <Container disabled={!isCheckedAttendance}>
        <RadioGroup
          defaultValue="attendanceReport"
          name="radio-buttons-group"
          onChange={handleOnChangeAttendance}
          value={attendanceType}
        >
          <FormControlLabel
            value="attendanceReport"
            control={
              <Radio
                icon={
                  <RadioUncheckedSvgIcon
                    sx={{ width: '40px', height: '40px' }}
                  />
                }
                checkedIcon={
                  <RadioCheckedSvgIcon sx={{ width: '40px', height: '40px' }} />
                }
                disableRipple
              />
            }
            label="Attendance"
          />
          <FormControlLabel
            value="workTimeReport"
            control={
              <Radio
                icon={
                  <RadioUncheckedSvgIcon
                    sx={{ width: '40px', height: '40px' }}
                  />
                }
                checkedIcon={
                  <RadioCheckedSvgIcon sx={{ width: '40px', height: '40px' }} />
                }
                disableRipple
              />
            }
            label="Detail Time"
          />
        </RadioGroup>
      </Container>
      <li>
        <Switch
          checked={!!metadata.plantAttendanceReport}
          onChange={(_, checked) => onChange('plantAttendanceReport', checked)}
        />
        <span>Plant Attendance Report</span>
      </li>
    </List>
  );
};

export default DataDownloadDialogContent;
