import type { SVGProps } from 'react';
const TowerCraneSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.64 6.232a1 1 0 0 0-1.025-.155l-12 5A1 1 0 0 0 6 12v2a1 1 0 0 0 1 1h10v10a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V15h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-.36-.768l-6-5ZM23.438 12H21l-1.219-3.047L23.438 12ZM17 12l1.48-3.7L9.6 12H17Zm-5 5h-2v1.126A4.002 4.002 0 0 0 11 26a4 4 0 0 0 4-4h-2a2 2 0 1 1-2-2h1v-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TowerCraneSvgIcon;
