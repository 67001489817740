import { SvgIcon, SvgIconProps } from '@mui/material';

const EditSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7132 13.0064L24.35 12.3697C24.8428 11.8768 25.642 11.8768 26.1349 12.3697L27.6303 13.8651C28.1232 14.358 28.1232 15.1571 27.6303 15.65L26.9935 16.2868L23.7132 13.0064ZM22.2009 14.5187L12 24.7196V28H15.2804L25.4813 17.7991L22.2009 14.5187Z"
      />
    </SvgIcon>
  );
};

export default EditSvgIcon;
