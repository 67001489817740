import axios, { AxiosError } from 'axios';

import {
  login as loginAction,
  logout as logoutAction,
} from '../slices/authSlice';

import { store } from '../store';

import { refresh } from './authApi';

export const authAxios = axios.create({
  baseURL: `${
    import.meta.env.VITE_AUTH_URL
  }/auth/realms/dasiot/protocol/openid-connect`,
});

const needRefresh = (expiredDate?: number) => {
  const now = Date.now();

  if (!expiredDate) return false;

  return now > expiredDate;
};

const refreshAccessToken = async () => {
  const res = await refresh();
  store.dispatch(
    loginAction({
      accessToken: res.data.access_token,
      expiresIn: Date.now() + res.data.expires_in * 1000,
      refreshExpiresIn: Date.now() + res.data.refresh_expires_in * 1000,
      refreshToken: res.data.refresh_token,
    }),
  );
  return res.data.access_token;
};

export const dsmOpsAxios = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
});

dsmOpsAxios.interceptors.request.use(async (config) => {
  const { accessToken, expiresIn } = store.getState().auth;

  let token = accessToken;

  if (needRefresh(expiresIn)) {
    try {
      token = await refreshAccessToken();
    } catch (error) {
      const err = error as Error | AxiosError<{ error_description: string }>;
      if (axios.isAxiosError(err)) {
        store.dispatch(logoutAction());
        return Promise.reject(
          new Error((err.response?.data as any)?.error_description),
        );
      }
      return Promise.reject(err);
    }
  }

  config.headers.set('Authorization', `Bearer ${token}`);
  return config;
});

dsmOpsAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error instanceof AxiosError) {
      const data = error.response?.data;
      if (data?.error?.code === 'UnauthorizedError') {
        store.dispatch(logoutAction());
      }
    }
    return Promise.reject(error);
  },
);

export interface NetWorkReport {
  timestamp: string;
}

export interface GeneralReport {
  id: string;
}

export interface AlertReport {
  id: string;
  timestamp: string;
}
