import { FC } from 'react';
import { styled, SxProps } from '@mui/material';

import { RmrTicketStatus } from '../types/RmrTicket';

import OverdueIconSvgIcon from './SvgIcon/OverdueIconSvgIcon';

const Container = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    display: 'inline-block',
    padding: '13px',

    '& > div': {
      borderRadius: '4px',
      width: '100%',
      height: '100%',
      '&[data-status="open"]': {
        backgroundColor: theme.color.green.$100,
      },
      '&[data-status="inProgress"]': {
        backgroundColor: theme.color.primary.$80,
      },
      '&[data-status="completed"]': {
        backgroundColor: theme.color.secondary.$100,
      },
      '&[data-overdue="true"]': {
        clipPath:
          'path("M14 8.71012C13.3663 8.89872 12.695 9 12 9C8.13401 9 5 5.86599 5 2C5 1.30503 5.10128 0.633709 5.28988 0H4C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14H10C12.2091 14 14 12.2091 14 10V8.71012Z")',
        '&[data-status="completed"]': {
          clipPath: 'unset',
        },
      },
    },
  };
});

interface StatusIcoProps {
  sx?: SxProps;
  size?: number;
  status: RmrTicketStatus;
  overdue?: boolean;
}

const StatusIcon: FC<StatusIcoProps> = ({ sx, size = 40, status, overdue }) => {
  return (
    <Container
      sx={{
        ...sx,
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      {overdue && status !== 'completed' && (
        <OverdueIconSvgIcon
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 1,
            width: '10px',
            height: '10px',
          }}
        />
      )}
      <div data-overdue={overdue} data-status={status} />
    </Container>
  );
};

export default StatusIcon;
