import { SvgIcon, SvgIconProps } from '@mui/material';

const AddSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="33" height="32" viewBox="0 0 33 32" fill="none" {...props}>
      <rect x="11" y="15" width="10" height="2" rx="1" fill="currentColor" />
      <rect
        x="17"
        y="11"
        width="10"
        height="2"
        rx="1"
        transform="rotate(90 17 11)"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default AddSvgIcon;
