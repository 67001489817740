import { ReactNode } from 'react';
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  styled,
} from '@mui/material';

const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    & > .header {
      padding: 12px 16px;
      background-color: ${({ theme }) => theme.color.box_bbg};
    }
  }
`;

interface OpsDialogProps extends MuiDialogProps {
  headerNode: ReactNode;
}

const OpsDialog: React.FC<OpsDialogProps> = ({
  headerNode,
  children,
  ...otherProps
}) => {
  return (
    <Dialog {...otherProps}>
      <div className="header">{headerNode}</div>
      {children}
    </Dialog>
  );
};

export default OpsDialog;
