import { FC, MouseEvent, ReactNode, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { DasEndpointType } from '../types/Device';
import { RmrIssue as RmrIssueData } from '../types/RmrTicket';

import { deleteRmrIssue, DeleteRmrIssueData } from '../apis/rmrApi';

import { parseDasId } from '../utils/common';

import CreateRmrCause from './CreateRmrCause';
import DeviceTag from './DeviceTag';
import EditRmrIssue from './EditRmrIssue';
import MoreOptionPopper from './MoreOptionPopper';
import RmrButton from './RmrButton';

const Container = styled('div')(({ theme }) => {
  return {
    backgroundColor: theme.color.gray.$250,
    border: `1px solid ${theme.color.gray.$235}`,
    borderRadius: '8px',
    margin: '8px 0 16px 0',
    padding: '20px',

    '& > .header': {
      cursor: 'pointer',
      padding: '8px 0',
      fontSize: '1.125rem',
      fontWeight: '700',

      '& > img': {
        verticalAlign: 'middle',
        '&.icon-more': {
          float: 'right',
          '&[data-disabled="true"]': {
            cursor: 'default',
            opacity: 0.3,
          },
        },
      },
    },

    '& > .content': {
      '& > .device-tag-Container': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        paddingLeft: '48px',
        margin: '16px 0',
      },
      '& > .description': {
        fontSize: '0.875rem',
        paddingLeft: '48px',
        margin: '16px 0',
      },
    },
  };
});

interface RmrIssueProps {
  disabledMoreOption?: boolean;
  number: number;
  data: RmrIssueData;
  onChange?: () => void;
  refetch?: () => void;
  children?: ReactNode;
}

const RmrIssue: FC<RmrIssueProps> = ({
  disabledMoreOption = false,
  number,
  data,
  refetch,
  children,
}) => {
  const { ticketId } = useParams();
  const moreOptionRef = useRef<HTMLImageElement | null>(null);
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [showContent, setShowContent] = useState(true);
  const [showCreateCause, setShowCreateCause] = useState(false);
  const [showMoreOption, setShowMoreOption] = useState(false);
  const deleteIssueMutation = useMutation({
    mutationFn: async (data: { ticketId: string; data: DeleteRmrIssueData }) =>
      deleteRmrIssue(data.ticketId, data.data),
    onError: (error) => {
      if (error instanceof AxiosError) {
        alert(error.response?.data?.error?.message ?? error.message);
      }
    },
    onSuccess: () => {
      refetch?.();
    },
  });

  const handleShowContent = () => {
    setShowContent(!showContent);
  };

  const handleShowMoreOption = (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    if (!disabledMoreOption) {
      setShowMoreOption(!showMoreOption);
    }
  };

  const handleOnEdit = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setShowMoreOption(false);
    setMode('edit');
  };

  const handleOnDelete = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    if (confirm(`Do you really delete the issue ${number}. "${data.title}"?`)) {
      if (ticketId) {
        deleteIssueMutation.mutate({
          ticketId,
          data: {
            issueId: data.id,
          },
        });
      }
    }
  };

  const deviceTags = data.issueDevices.map((dasId) => {
    return (
      <DeviceTag
        key={`issue-${data.id}-dasId-${dasId}`}
        mode="view"
        type={parseDasId(dasId) as DasEndpointType}
        dasId={dasId}
      />
    );
  });

  if (mode === 'view')
    return (
      <Container>
        <div className="header" onClick={handleShowContent}>
          <img
            src={
              showContent
                ? '/assets/image/svg/triangle-up.svg'
                : '/assets/image/svg/triangle-down.svg'
            }
          />
          {number}. {data.title}
          <img
            ref={moreOptionRef}
            className="icon-more"
            src="/assets/image/svg/more.svg"
            data-disabled={disabledMoreOption}
            onClick={handleShowMoreOption}
          />
          <MoreOptionPopper
            anchorEl={moreOptionRef.current}
            open={showMoreOption}
            placement="bottom-end"
            onClose={() => setShowMoreOption(false)}
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
          />
        </div>
        <div className="content">
          <div className="device-tag-Container">{deviceTags}</div>
          <div className="description">{data.description}</div>
          {showContent && (
            <>
              {!disabledMoreOption && (
                <RmrButton
                  className="add-button cause"
                  onClick={() => setShowCreateCause(true)}
                  disabled={showCreateCause}
                >
                  <img src="/assets/image/svg/add.svg" /> CAUSE{' '}
                  {data.causes.length + 1}
                </RmrButton>
              )}
              {showCreateCause && (
                <CreateRmrCause
                  issueId={data.id}
                  number={data.causes.length + 1}
                  issueIndex={number}
                  devicePool={data.issueDevices}
                  onClose={() => {
                    setShowCreateCause(false);
                    refetch?.();
                  }}
                />
              )}
              {children}
            </>
          )}
        </div>
      </Container>
    );

  if (mode === 'edit')
    return (
      <EditRmrIssue
        data={data}
        issueIndex={number - 1}
        onCancel={() => setMode('view')}
        onSuccess={refetch}
      />
    );

  return null;
};

export default RmrIssue;
