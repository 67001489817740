import type { SVGProps } from 'react';

const AiCameraSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M18.716 8.526 17.226 13h4.608l-2.772 5.419-4.615-1.237-2.34 5.016-.27.578H8v1.4l-3 1.6v-8l3 1.6v1.4h2.563l1.922-4.12-5.52-1.478a1 1 0 0 1-.706-1.225L8.19 6.74a1 1 0 0 1 1.225-.707l9.3 2.492ZM24.062 13h3.452l-1.571 5.863-4.292-1.15L24.062 13Zm-13.093-.95a1.352 1.352 0 1 0 .7-2.612 1.352 1.352 0 0 0-.7 2.612Z" />
      <path d="m22 5-2 6h1.5l.333-1h2.334l.333 1H26l-2-6h-2Zm1.667 3.5L23 6.5l-.667 2h1.334ZM27 5h1.5v1.5H27V5Zm1.5 2H27v4h1.5V7Z" />
    </g>
  </svg>
);
export default AiCameraSvgIcon;
