import { useMemo, useState } from 'react';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';
import { AxiosError } from 'axios';

import { DasEndpoint } from '../../../../types/Device';

import { updateDasEndPoints } from '../../../../apis/dasEndpointApi';

import Input from '../../../../components/Input';
import CrossSvgIcon from '../../../../components/SvgIcon/CrossSvgIcon';

import { editDeviceInputSx, inputSx as originInputSx } from '../../../../theme';
import Validator from '../../../../utils/Validator';

const inputSx = { ...originInputSx, ...editDeviceInputSx };

const Dialog = styled(MuiDialog)(({ theme }) => {
  return {
    '& .MuiPaper-root': {
      width: '520px',
      '& .header': {
        ...theme.typography.subtitle1,
        position: 'relative',
        textAlign: 'center',
        fontSize: '1.125rem',
        padding: '16px',
      },
    },
    '& .banner': {
      color: '#fff',
      fontSize: '1rem',
      padding: '16px',
      margin: '32px 0 16px 0',
      '&[data-status="error"]': {
        background: theme.color.highlight,
      },
      '&[data-status="success"]': {
        background: theme.color.green.$100,
      },
    },
  };
});

const DialogContent = styled(MuiDialogContent)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '16px 60px',
    '& > .property': {
      marginBottom: '16px',
      '& > label': {
        color: '#969696',
        '& > span': {
          color: theme.color.highlight,
        },
      },
    },
  };
});

const DialogActions = styled(MuiDialogActions)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
  };
});

const FormContainer = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  & > div {
    text-align: left;
    width: 100%;
    & > label {
      color: ${({ theme }) => theme.color.secondary.$60};
      & > span {
        color: ${({ theme }) => theme.color.highlight};
      }
    }
    input {
      &:read-only {
        background-color: #f3f3f3;
      }
    }
  }
`;

interface EditDasLoopDialogProps extends DialogProps {
  device: DasEndpoint | undefined;
  onEditSuccess?: () => void;
}

const EditDasLoopDialog: React.FC<EditDasLoopDialogProps> = ({
  device,
  onClose,
  onEditSuccess,
  ...otherProps
}) => {
  const [config, setConfig] = useState<{
    dasId: string;
    uwbId: string;
    collisionId: string;
    sim: string;
  }>({
    dasId: device?.dasId ?? '',
    uwbId: device?.metadata.uwbId ?? '',
    sim: device?.metadata.sim ?? '',
    collisionId: device?.metadata.collisionId,
  });
  const [responseSatusResult, setResponseSatusResult] = useState<{
    status: 'success' | 'error';
    message: string;
  }>({
    status: 'success',
    message: '',
  });

  const isValid = useMemo(() => {
    let result = true;
    result = result && !Validator.validateDasLoopDasId(config.dasId).isError;
    result = result && !!config.collisionId;
    return result;
  }, [config]);

  const handleUpdate = async () => {
    setResponseSatusResult({
      status: 'success',
      message: '',
    });
    const payload = {
      dasId: config.dasId,
      uwbId: config.uwbId === '' ? '-' : config.uwbId,
      collisionId: config.collisionId,
      sim: config.sim === '' ? '-' : config.sim,
    };
    try {
      await updateDasEndPoints({
        dasId: device?.dasId ?? '',
        payload,
      });

      setResponseSatusResult({
        status: 'success',
        message: `Endpoint updated successfully`,
      });

      onEditSuccess?.();
    } catch (error) {
      if (error instanceof AxiosError) {
        setResponseSatusResult({
          status: 'error',
          message: error.response?.data.error.message,
        });
      } else if (error instanceof Error) {
        setResponseSatusResult({
          status: 'error',
          message: error.message,
        });
      }
    }
  };

  return (
    <Dialog {...otherProps}>
      <div className="header">
        Edit DasLoop
        <CrossSvgIcon
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 1,
            width: '40px',
            height: '40px',
          }}
          onClick={(e) => {
            onClose?.(e, 'backdropClick');
          }}
        />
      </div>
      {responseSatusResult.message !== '' && (
        <div className="banner" data-status={responseSatusResult.status}>
          {responseSatusResult.message}
        </div>
      )}
      <DialogContent>
        <FormContainer>
          <div>
            <label>
              Das ID<span>*</span>
            </label>
            <Input
              sx={inputSx}
              value={config.dasId}
              onChange={(e) => {
                setConfig({
                  ...config,
                  dasId: e.currentTarget.value,
                });
              }}
            />
          </div>
        </FormContainer>
        <FormContainer>
          <div>
            <label>UWB ID</label>
            <Input
              sx={inputSx}
              value={config.uwbId}
              onChange={(e) => {
                setConfig({
                  ...config,
                  uwbId: e.currentTarget.value,
                });
              }}
            />
          </div>
        </FormContainer>
        <FormContainer>
          <div>
            <label>SIM Card</label>
            <Input
              sx={inputSx}
              value={config.sim}
              onChange={(e) => {
                setConfig({
                  ...config,
                  sim: e.currentTarget.value,
                });
              }}
            />
          </div>
        </FormContainer>
        <FormContainer>
          <div>
            <label>
              Collision ID<span>*</span>
            </label>
            <Input
              sx={inputSx}
              value={config.collisionId}
              onChange={(e) => {
                setConfig({
                  ...config,
                  collisionId: e.currentTarget.value,
                });
              }}
            />
          </div>
        </FormContainer>
      </DialogContent>
      <DialogActions>
        <DialogButton
          variant="text"
          color="primary"
          sx={(theme) => ({
            color: theme.color.primary.$100,
          })}
          onClick={(e) => onClose?.(e, 'backdropClick')}
        >
          Cancel
        </DialogButton>
        <DialogButton
          variant="contained"
          color="primary"
          disabled={!isValid}
          onClick={handleUpdate}
        >
          Update
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditDasLoopDialog;
