import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { CircularProgress, styled } from '@mui/material';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ErrorState } from '../types';
import { DasEndpoint, DasEndpointType } from '../types/Device';

import { getDasEndpoints } from '../apis/dasEndpointApi';
import { createIssue, CreateRmrIssueData } from '../apis/rmrApi';

import { parseDasId } from '../utils/common';

import CrossSvgIcon from './SvgIcon/CrossSvgIcon';
import SaveSvgIcon from './SvgIcon/SaveSvgIcon';
import DeviceAssignment from './DeviceAssignment';
import DeviceTag from './DeviceTag';
import Input from './Input';
import Textarea from './Textarea';

const Container = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    padding: '16px 20px',
    background: theme.color.gray.$250,
    border: `1px solid ${theme.color.secondary.$40}`,
    borderRadius: '8px',
    '& > .banner': {
      color: '#fff',
      fontSize: '1rem',
      padding: '16px',
      margin: '32px 0 16px 0',
      '&[data-status="error"]': {
        background: theme.color.highlight,
      },
      '&[data-status="success"]': {
        background: theme.color.green.$100,
      },
    },
    '& > div': {
      marginBottom: '16px',
      '&.normal': {
        '&.action': {
          textAlign: 'center',
          marginBottom: '0',
        },
      },
      '& > label': {
        fontSize: '0.875rem',
        color: theme.color.secondary.$60,
        '& >span': {
          color: theme.color.highlight,
        },
      },
      '& > .device-wrapper': {
        margin: '8px 0 16px 0',
        '& > .container': {
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexWrap: 'wrap',
          gap: '8px',
          marginTop: '8px',
        },
      },
    },
  };
});

interface CreateRmrIssueProps {
  issueIndex: number;
  onClose: () => void;
}

const CreateRmrIssue: FC<CreateRmrIssueProps> = ({ issueIndex, onClose }) => {
  const { ticketId } = useParams();
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [filter, setFilter] = useState('');
  const [selectedDasIds, setSelectedDasIds] = useState<string[]>([]);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState<ErrorState>({
    isError: false,
    message: '',
  });
  const [responseSatusResult, setResponseSatusResult] = useState<{
    status: 'success' | 'error';
    message: string;
  }>({
    status: 'success',
    message: '',
  });
  const createRmrIssueMutation = useMutation({
    mutationFn: async (data: CreateRmrIssueData) => {
      if (ticketId) {
        return createIssue(ticketId, data);
      }
    },
    onError: (error: AxiosError<{ error: { message: string } }>) => {
      setResponseSatusResult({
        status: 'error',
        message: error.response?.data?.error?.message ?? error.message,
      });
    },
    onSuccess: () => {
      setResponseSatusResult({
        status: 'success',
        message: 'Create issue success',
      });
      setTimeout(() => {
        onClose();
      }, 1000);
    },
    retry: false,
  });

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['get-dasendpoint', filter],
    queryFn: async ({ pageParam }) => {
      const res = await getDasEndpoints({
        nextCursor: pageParam as string | null,
        limit: 10,
        dasId: filter,
      });
      return res.data;
    },
    getNextPageParam: (lastPage) => lastPage.paging.nextCursor,
    refetchOnWindowFocus: false,
  });

  const dasendpoints = useMemo(() => {
    return data?.pages
      .map((page) => {
        return page.data;
      })
      .flat()
      .reduce<{ [dasId: string]: DasEndpoint }>((prev, dasendpoint) => {
        prev[dasendpoint.dasId] = dasendpoint;
        return prev;
      }, {});
  }, [data?.pages, selectedDasIds]);

  const handleOnDeleteSelectedDasId = (dasId: string) => {
    setSelectedDasIds(selectedDasIds.filter((id) => id !== dasId));
  };

  const deviceTags = selectedDasIds.map((dasId) => {
    return (
      <DeviceTag
        key={`devicetags-${issueIndex}-${dasId}`}
        dasId={dasId}
        mode="edit"
        type={parseDasId(dasId) as DasEndpointType}
        onDelete={handleOnDeleteSelectedDasId}
      />
    );
  });

  const handleOnSelect = (dasId: string) => {
    setSelectedDasIds([...selectedDasIds, dasId]);
  };

  const handleCreateIssue = () => {
    createRmrIssueMutation.mutate({
      dasIds: selectedDasIds,
      title,
      description,
    });
  };

  const isValid =
    title !== '' && selectedDasIds.length > 0 && description !== '';

  return (
    <Container>
      <CrossSvgIcon
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: '0',
          right: '0',
          width: '40px',
          height: '40px',
        }}
        onClick={onClose}
      />
      <div>
        <label>
          Report Issue {issueIndex + 1}
          <span>*</span>
        </label>
        <Input
          sx={() => ({
            display: 'block',
            '& > input': { backgroundColor: '#fff' },
          })}
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          onBlur={() => {
            if (title === '') {
              setTitleError({
                isError: true,
                message: 'Title do not be empty',
              });
            } else {
              setTitleError({
                isError: false,
                message: '',
              });
            }
          }}
          error={titleError.isError}
          errorMessage={titleError.message}
        />
      </div>
      <div className="normal">
        <label>
          Associated Devices<span>*</span>
        </label>
        <div className="device-wrapper">
          <DeviceAssignment
            prefixKey={`create-issue-${issueIndex + 1}`}
            filter={filter}
            assignMap={dasendpoints}
            onSelect={handleOnSelect}
            selectedList={selectedDasIds}
            onFilterChange={(text) => setFilter(text)}
            onFetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
          <div className="container">{deviceTags}</div>
        </div>
      </div>
      <div className="normal">
        <label>
          Description<span>*</span>
        </label>
        <Textarea
          sx={{ display: 'block' }}
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          onBlur={() => {
            if (description === '') {
              setDescriptionError({
                isError: true,
                message: 'Description do not empty',
              });
            } else {
              setDescriptionError({
                isError: false,
                message: '',
              });
            }
          }}
          error={descriptionError.isError}
          errorMessage={descriptionError.message}
        />
      </div>
      <div className="normal action">
        <DialogButton
          sx={{ padding: '0' }}
          variant="contained"
          color="primary"
          disabled={!isValid || createRmrIssueMutation.isLoading}
          onClick={handleCreateIssue}
          endIcon={
            !createRmrIssueMutation.isLoading && (
              <SaveSvgIcon sx={{ width: '32px', height: '32px' }} />
            )
          }
        >
          {createRmrIssueMutation.isLoading ? (
            <CircularProgress sx={{ color: '#FFF' }} size={24} />
          ) : (
            'CREATE'
          )}
        </DialogButton>
      </div>
      {responseSatusResult.message !== '' && (
        <div className="banner" data-status={responseSatusResult.status}>
          {responseSatusResult.message}
        </div>
      )}
    </Container>
  );
};

export default CreateRmrIssue;
