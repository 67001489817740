import { useRef, useState } from 'react';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { CircularProgress, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ErrorState } from '../../../types';

import { checkEmailExist, getWho } from '../../../apis/userApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setTransferEmail } from '../../../slices/systemSlice';

import EmailSvgIcon from '../../../components/SvgIcon/EmailSvgIcon';

import { delay } from '../../../utils/common';
import StatisticsDevicesBlock from '../StatisticsDevicesBlock';

const Container = styled('div')`
  padding: 10px 30px;
  & > .title {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.5;
    text-align: center;
  }

  & > .device-list {
    max-height: 180px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 16px;
    margin: 8px 0;
    overflow: auto;

    & > .device-item {
      display: flex;
      align-items: center;
      & > .number {
        display: inline-block;
        min-width: 50px;
        font-size: 1.5rem;
        text-align: center;
      }
      & > .das-type {
        margin: 0 10px;
      }
      & > .device-type {
        font-size: 0.875rem;
        line-height: 1.5;
      }
    }
  }

  & > .search-container {
    & > label {
      color: ${({ theme }) => theme.color.secondary.$60};
      & > span {
        color: ${({ theme }) => theme.color.highlight};
      }
    }
    & > .email-container {
      display: flex;
      align-items: center;
      background: ${({ theme }) => theme.color.box_bbg};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      & > svg {
        display: block;
      }

      & > input {
        flex: 1;
        background-color: transparent;
        outline: none;
        border: none;
        color: ${({ theme }) => theme.color.secondary.$100};
        line-height: 1.5;

        &::placeholder {
          color: ${({ theme }) => theme.color.secondary.$60};
        }
      }
    }

    & > .error-message {
      text-align: center;
      color: ${({ theme }) => theme.color.highlight};
    }
  }

  & > .action {
    display: flex;
    justify-content: space-between;
    padding: 24px 0 14px;
  }
`;

interface ConfirmEmailProps {
  onNext: () => void;
  onCancel: () => void;
}

const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ onNext, onCancel }) => {
  const dispatch = useAppDispatch();
  const timerRef = useRef<NodeJS.Timeout>();
  const deviceMap = useAppSelector((store) => store.system.transferDeviceMap);
  const [email, setEmail] = useState('');
  const [errorState, setErrorState] = useState<ErrorState>({
    isError: false,
    message: '',
  });

  const { isFetching } = useQuery(
    ['check-email-exist', email],
    async () => {
      setErrorState({
        isError: false,
        message: '',
      });
      await delay(800);
      return checkEmailExist({ email, exact: true }).then(() => email);
    },
    {
      enabled: !!email,
      retry: false,
      onSuccess: async (email) => {
        const user = await getWho(email).then((res) => res.data.data);
        if (user.org.ownerId !== user.id) {
          setErrorState({
            isError: true,
            message: 'This account is not organization owner',
          });
        } else {
          setErrorState({
            isError: false,
            message: '',
          });
        }
      },
      onError: async (err: AxiosError) => {
        const message =
          err.response?.status === 404
            ? 'Email does not exist.'
            : `Status ${err.response?.status ?? 'Unknown'}`;
        setErrorState({
          isError: true,
          message,
        });
      },
    },
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setEmail(value);
    }, 800);
  };

  const handleClickNext = () => {
    dispatch(setTransferEmail(email));
    onNext();
  };

  const isClickable = !errorState.isError && email && !isFetching;

  return (
    <Container>
      <div className="title">Transfer Device</div>
      <StatisticsDevicesBlock deviceMap={deviceMap} />
      <div className="search-container">
        <label>
          Transfer to another account<span>*</span>
        </label>
        <div className="email-container">
          <EmailSvgIcon />
          <input
            placeholder="E-mail"
            onChange={handleOnChange}
            onBlur={() => {
              if (!email) {
                setErrorState({
                  isError: true,
                  message: 'E-mail is required',
                });
              } else {
                setErrorState({
                  isError: false,
                  message: '',
                });
              }
            }}
          />
          {isFetching && (
            <CircularProgress
              sx={{ display: 'block', margin: '8px' }}
              size="1rem"
            />
          )}
        </div>
        {errorState.isError && (
          <div className="error-message">{errorState.message}</div>
        )}
      </div>
      <div className="action">
        <DialogButton
          sx={(theme) => ({ color: theme.color.primary.$100 })}
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </DialogButton>
        <DialogButton
          variant="contained"
          color="primary"
          disabled={!isClickable}
          onClick={handleClickNext}
        >
          Next
        </DialogButton>
      </div>
    </Container>
  );
};

export default ConfirmEmail;
