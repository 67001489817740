import { FC, MouseEvent } from 'react';
import {
  ClickAwayListener,
  Popper as MuiPopper,
  PopperProps,
  styled,
} from '@mui/material';

import EditSvgIcon from './SvgIcon/EditSvgIcon';
import TrashSvgIcon from './SvgIcon/TrashSvgIcon';

const Popper = styled(MuiPopper)(({ theme }) => {
  return {
    zIndex: 2000,
    '& ul': {
      minWidth: '154px',
      listStyleType: 'none',
      color: theme.color.secondary.$100,
      background: '#FFF',
      padding: '0',
      borderRadius: '4px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
      margin: '0',
      '& > li': {
        cursor: 'pointer',
        fontSize: '0.875rem',
        paddingRight: '16px',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.05)',
        },
        '& > svg': {
          width: '2.5rem',
          height: '2.5rem',
          verticalAlign: 'middle',
        },
      },
    },
  };
});

interface MoreOptionPopperProps extends PopperProps {
  onEdit?: (e: MouseEvent<HTMLLIElement>) => void;
  onDelete?: (e: MouseEvent<HTMLLIElement>) => void;
  onClose: () => void;
}

const MoreOptionPopper: FC<MoreOptionPopperProps> = ({
  onEdit,
  onDelete,
  onClose,
  ...props
}) => {
  return (
    <Popper {...props}>
      <ClickAwayListener onClickAway={onClose}>
        <ul>
          <li onClick={onEdit}>
            <EditSvgIcon /> EDIT
          </li>
          <li onClick={onDelete}>
            <TrashSvgIcon />
            DELETE
          </li>
        </ul>
      </ClickAwayListener>
    </Popper>
  );
};

export default MoreOptionPopper;
