import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  styled,
} from '@mui/material';

import { RmrPhoto } from '../types/RmrTicket';

import { useAppSelector } from '../hooks';

import ArrowLeftSvgIcon from './SvgIcon/ArrowLeftSvgIcon';
import ArrowRightSvgIcon from './SvgIcon/ArrowRightSvgIcon';
import CrossSvgIcon from './SvgIcon/CrossSvgIcon';

const Dialog = styled(MuiDialog)(() => {
  return {
    '& .MuiPaper-root': {
      width: '100vw',
      height: '100vh',
      backgroundColor: 'transparent',
      margin: 0,
      maxHeight: 'unset',
      maxWidth: 'unset',
    },
  };
});

const DialogTitle = styled(MuiDialogTitle)(() => {
  return {
    padding: '8px',
  };
});

const DialogContent = styled(MuiDialogContent)(({ theme }) => {
  return {
    height: 'calc(100vh - 56px)',
    padding: '0',
    '& .page-button': {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      zIndex: 2,
      color: 'rgba(255, 255, 255, 0.7)',
      padding: 0,
      border: 'none',
      margin: 0,
      '&:hover': {
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
      '&:disabled': {
        opacity: 0.3,
        pointerEvents: 'none',
      },
    },
    '& .big-photo-container': {
      position: 'relative',
      height: 'calc(100vh - 56px - 44px - 124px)',
      color: '#fff',
      marginBottom: '44px',
      '& .view': {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        zIndex: 1,
        transition: 'left .3s',
        '& > div': {
          flex: '0 0 100%',
          '& img': {
            height: '100%',
            objectFit: 'contain',
          },
        },
      },
      '& .page-button': {
        '&.left': {
          left: 0,
        },
        '&.right': {
          right: 0,
        },
      },
    },
    '& .control-photo-container': {
      height: '124px',
      textAlign: 'center',
      '& > .container': {
        position: 'relative',
        display: 'inline-block',
        width: '500px',
        height: '100%',
        margin: '0 40px',
        '& > .page-container': {
          position: 'relative',
          width: 'calc(100% - 40px)',
          height: '100%',
          overflow: 'hidden',
          margin: '0 20px',
          '& > .photo-container': {
            flex: '0 0 500px',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: '20px',
            height: '100%',
            transition: 'left .3s',
            '& .photo-wrapper': {
              cursor: 'pointer',
              display: 'inline-block',
              width: '100px',
              height: '100px',
              textAlign: 'center',
              border: '2px solid transparent',
              '&.focus': {
                border: `2px solid ${theme.color.primary.$100}`,
              },
              '& > img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              },
            },
          },
        },
      },
      '& img': {
        height: '100%',
        objectFit: 'cover',
      },
      '& .page-button': {
        '&.left': {
          left: '-40px',
        },
        '&.right': {
          right: '-40px',
        },
      },
    },
  };
});

const AlbumDialog = () => {
  const navigate = useNavigate();
  const rmrTicket = useAppSelector((store) => store.rmr.currentRmrTicket);
  const { ticketId, issueIndex, causeIndex, solutionIndex, photoIndex } =
    useParams();
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    setPageIndex(Math.floor(parseInt(photoIndex ?? '0') / 4));
  }, [photoIndex]);

  let photos: RmrPhoto[] = [];

  if (rmrTicket && ticketId && issueIndex && causeIndex) {
    if (!solutionIndex) {
      photos =
        rmrTicket.issues[parseInt(issueIndex, 10)].causes[
          parseInt(causeIndex, 10)
        ].photos;
    } else {
      photos =
        rmrTicket.issues[parseInt(issueIndex, 10)].causes[
          parseInt(causeIndex, 10)
        ].solutions[parseInt(solutionIndex, 10)].photos;
    }
  }

  const navigateToPhoto = (index: number) => {
    let url = '';

    if (!solutionIndex) {
      url = `../issue/${issueIndex}/cause/${causeIndex}/photo/${index}`;
    } else {
      url = `../issue/${issueIndex}/cause/${causeIndex}/solution/${solutionIndex}/photo/${index}`;
    }

    navigate(url, {
      replace: true,
    });
  };

  const mainPhotoComponents = useMemo(() => {
    return photos.map((photo) => {
      return (
        <div key={`main-photo-${photo.id}`}>
          <img src={photo.url} />
        </div>
      );
    });
  }, [photos]);

  const thumbnailComponents = useMemo(() => {
    return photos.map((photo, index) => {
      return (
        <div
          key={`thumbnail-${photo.id}`}
          className={
            'photo-wrapper' + (index.toString() === photoIndex ? ' focus' : '')
          }
          onClick={() => {
            navigateToPhoto(index);
          }}
        >
          <img src={photo.url} />
        </div>
      );
    });
  }, [photos, photoIndex]);

  return (
    <Dialog open>
      <DialogTitle>
        <CrossSvgIcon
          sx={{
            cursor: 'pointer',
            float: 'right',
            display: 'block',
            color: 'white',
            width: '40px',
            height: '40px',
          }}
          onClick={() => {
            navigate(-1);
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div className="big-photo-container">
          <fieldset
            className="page-button left"
            disabled={photoIndex === '0'}
            onClick={() => {
              if (photoIndex) {
                const index = parseInt(photoIndex, 10);
                navigateToPhoto(index - 1);
              }
            }}
          >
            <ArrowLeftSvgIcon sx={{ width: 80, height: 80 }} />
          </fieldset>
          <div
            className="view"
            style={{
              left: `calc(100vw * ${-1 * parseInt(photoIndex ?? '0', 10)})`,
            }}
          >
            {mainPhotoComponents}
          </div>
          <fieldset
            className="page-button right"
            disabled={photoIndex === (photos.length - 1).toString()}
            onClick={() => {
              if (photoIndex) {
                const index = parseInt(photoIndex, 10);
                navigateToPhoto(index + 1);
              }
            }}
          >
            <ArrowRightSvgIcon sx={{ width: 80, height: 80 }} />
          </fieldset>
        </div>
        <div className="control-photo-container">
          <div className="container">
            <fieldset
              className="page-button left"
              disabled={pageIndex === 0}
              onClick={() => {
                if (pageIndex > 0) {
                  setPageIndex(pageIndex - 1);
                }
              }}
            >
              <ArrowLeftSvgIcon sx={{ width: 40, height: 40 }} />
            </fieldset>
            <div className="page-container">
              <div
                className={'photo-container'}
                style={{ left: `${pageIndex * -480}px` }}
              >
                {thumbnailComponents}
              </div>
            </div>
            <fieldset
              className="page-button right"
              disabled={pageIndex === Math.ceil(photos.length / 4) - 1}
              onClick={() => {
                setPageIndex(pageIndex + 1);
              }}
            >
              <ArrowRightSvgIcon sx={{ width: 40, height: 40 }} />
            </fieldset>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AlbumDialog;
