import { SvgIcon, SvgIconProps } from '@mui/material';

const TrashSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <g clipPath="url(#clip0_1101_9867)">
        <path d="M26 29H14V15.5H26V29ZM21.5 26H23V18.5H21.5V26ZM17 26H18.5V18.5H17V26ZM21.5 14H14V12.5H18.5V11H21.5V12.5H26V14H21.5Z" />
      </g>
      <defs>
        <clipPath id="clip0_1101_9867">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default TrashSvgIcon;
