import { FC } from 'react';
import { styled } from '@mui/material';

import CrossSvgIcon from './SvgIcon/CrossSvgIcon';

const Container = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    lineHeight: '',
    padding: '0 0 0 12px',
    textTransform: 'unset',
    borderRadius: '1000px',
    border: `1px solid ${theme.color.gray.$240}`,
    '& > svg': {
      cursor: 'pointer',
      margin: 0,
    },
  };
});

interface MemeberTagProps {
  name: string;
  onDelete?: () => void;
}

const MemeberTag: FC<MemeberTagProps> = ({ name, onDelete }) => {
  return (
    <Container>
      {name}
      {onDelete && (
        <CrossSvgIcon
          sx={{ width: '2rem', height: '2rem' }}
          onClick={onDelete}
        />
      )}
    </Container>
  );
};

export default MemeberTag;
