import { RouteObject } from 'react-router-dom';

import App from '../App';
import HomePage from '../pages/home';
import PageNotFound from '../pages/PageNotFound';

import devicesRouter from './devices';
import featureListRouter from './feature-list';
import { router as loginRouter } from './login';
import orgSettingRouter from './org-setting';
import projectSettingRouter from './project-setting';
import rmrRouter from './rmr';
import taskListRouter from './task-list';
import transferPaRouter from './transfer-pa';

export const router: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: '/',
        element: <HomePage />,
        children: [
          devicesRouter,
          orgSettingRouter,
          rmrRouter,
          projectSettingRouter,
          taskListRouter,
          featureListRouter,
          transferPaRouter,
        ],
      },
      ...loginRouter,
    ],
  },
];
