import { PagedData } from '../types';
import { BleAoa, Dasbeacon, LoraWanAoa } from '../types/Device';

import { AlertReport, dsmOpsAxios, GeneralReport, NetWorkReport } from '.';

interface PostTransferEndpointsData {
  destinationUserId: string;
  endpointIds: string[];
  clearBinding: boolean;
}

export const transferEndpoints = (data: PostTransferEndpointsData) => {
  return dsmOpsAxios.post('/v1/transfer/endpoints', data);
};

interface PostTransferLocatorsData {
  destinationUserId: string;
  dasbeaconIds: string[];
  bleAoaIds: string[];
  uwbAoaIds: string[];
  clearBinding: boolean;
}

export const transferLocators = (data: PostTransferLocatorsData) => {
  return dsmOpsAxios.post('/v1/transfer/locators', data);
};

export const uploadEndpoint = ({
  projectId,
  excelFile,
}: {
  projectId: string;
  excelFile: File;
}) => {
  const formData = new FormData();
  formData.append('file', excelFile);
  return dsmOpsAxios.post(
    `v1/projects/${projectId}/endpoints/upload`,
    formData,
  );
};

interface RegisterLocatorData {
  dasId: string;
}

export const registerDasbeacon = (data: RegisterLocatorData) => {
  return dsmOpsAxios.post('/v1/locator/dasbeacons', data);
};

export const registerBleAoa = (data: RegisterLocatorData) => {
  return dsmOpsAxios.post('/v1/locator/ble-aoas', data);
};

export const registerUwbAoa = (data: RegisterLocatorData) => {
  return dsmOpsAxios.post('/v1/locator/uwb-aoas', data);
};

interface GetDasbeaconsParams {
  id?: string;
  dasId?: string;
  beaconId?: string;
  name?: string;
  nextCursor?: string;
  prevCursor?: string;
}

export const getDasbeacons = (params?: GetDasbeaconsParams) => {
  return dsmOpsAxios.get<PagedData<Dasbeacon>>('/v1/locator/dasbeacons', {
    params,
  });
};

interface GetAoasParams {
  id?: string;
  dasId?: string;
  nextCursor?: string;
  prevCursor?: string;
}

export const getBleAoas = (params?: GetAoasParams) => {
  return dsmOpsAxios.get<PagedData<BleAoa>>('/v1/locator/ble-aoas', { params });
};

export const getUwbAoas = (params?: GetAoasParams) => {
  return dsmOpsAxios.get<PagedData<LoraWanAoa>>('/v1/locator/uwb-aoas', {
    params,
  });
};

interface getDasendpointNetworkReportParams {
  fromDate: string;
  toDate: string;
  dasId: string;
  limit?: number;
  nextCursor?: string;
  prevCursor?: string;
}

export const getDasendpointNetworkReport = (
  params: getDasendpointNetworkReportParams,
) => {
  return dsmOpsAxios.get<PagedData<NetWorkReport>>(
    '/v1/das-endpoints/network-report',
    {
      params,
    },
  );
};

interface getGeneralReportParams {
  fromDate: string;
  toDate: string;
  id: string;
  limit?: number;
  nextCursor?: string;
  prevCursor?: string;
}

export const getGeneralReport = (params: getGeneralReportParams) => {
  const { id, ...otherparams } = params;
  return dsmOpsAxios.get<PagedData<GeneralReport>>(
    `/v1/das-endpoints/${id}/readings`,
    {
      params: otherparams,
    },
  );
};

export const getAlertReport = (params: getGeneralReportParams) => {
  const { id, ...otherparams } = params;
  return dsmOpsAxios.get<PagedData<AlertReport>>(
    `/v1/das-endpoints/${id}/alerts`,
    {
      params: otherparams,
    },
  );
};
