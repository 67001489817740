import { FC, useRef, useState } from 'react';
import {
  Box,
  BoxProps,
  ClickAwayListener,
  Popper,
  styled,
} from '@mui/material';

import { RmrTicketStatus } from '../types/RmrTicket';

import ArrowDownSvgIcon from './SvgIcon/ArrowDownSvgIcon';

const Container = styled(Box)(({ theme }) => {
  return {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: '#FFF',
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: 1.5,
    textTransform: 'uppercase',
    borderRadius: '4px',
    padding: '0 10px 0 26px',
    backgroundColor: theme.color.gray.$250,

    '&[data-status="open"]': {
      backgroundColor: theme.color.green.$100,
    },

    '&[data-status="inProgress"]': {
      backgroundColor: theme.color.primary.$80,
    },

    '&[data-status="completed"]': {
      backgroundColor: theme.color.secondary.$100,
    },
  };
});

const PopperContainer = styled('div')(({ theme }) => {
  return {
    padding: '5px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '& > div': {
      cursor: 'pointer',
      paddingRight: '11px',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: theme.color.box_bbg,
      },
      '& > span': {
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        '&[data-selected="true"]': {
          color: theme.color.secondary.$100,
          fontWeight: '500',
        },
        '&:before': {
          content: '""',
          display: 'inline-block',
          width: '14px',
          height: '14px',
          margin: '13px 17px 13px 13px',
          borderRadius: '4px',
        },
        '&[data-type="open"]': {
          '&:before': {
            background: theme.color.green.$100,
          },
        },
        '&[data-type="inProgress"]': {
          '&:before': {
            background: theme.color.primary.$80,
          },
        },
        '&[data-type="completed"]': {
          '&:before': {
            background: theme.color.secondary.$100,
          },
        },
      },
    },
  };
});

interface RmrStatusDropdownProps extends BoxProps {
  status: RmrTicketStatus | undefined;
  disabled?: boolean;
  onSelectStatus?: (status: RmrTicketStatus) => void;
}

const RmrStatusDropdown: FC<RmrStatusDropdownProps> = ({
  status,
  disabled = false,
  onSelectStatus,
  ...props
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  let statusText = '';

  switch (status) {
    case 'open':
      statusText = 'open';
      break;
    case 'inProgress':
      statusText = 'in progress';
      break;
    case 'completed':
      statusText = 'completed';
      break;
  }

  const handleOnSelect = (type: RmrTicketStatus) => {
    if (!disabled) {
      onSelectStatus?.(type);
    }
  };

  return (
    <>
      <Container
        data-status={status}
        ref={dropdownRef}
        onClick={() => setOpen(!open)}
        {...props}
      >
        {statusText}
        <ArrowDownSvgIcon
          sx={{ display: 'block', width: '32px', height: '32px' }}
        />
      </Container>
      <Popper
        sx={(theme) => ({
          color: theme.color.secondary.$60,
          zIndex: 2000,
        })}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
        open={open}
        anchorEl={dropdownRef.current}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <PopperContainer>
            <div onClick={() => handleOnSelect('open')}>
              <span data-type="open" data-selected={status === 'open'}>
                OPEN
              </span>
            </div>
            <div onClick={() => handleOnSelect('inProgress')}>
              <span
                data-type="inProgress"
                data-selected={status === 'inProgress'}
              >
                IN PROGRESS
              </span>
            </div>
            <div onClick={() => handleOnSelect('completed')}>
              <span
                data-type="completed"
                data-selected={status === 'completed'}
              >
                COMPLETED
              </span>
            </div>
          </PopperContainer>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default RmrStatusDropdown;
