import { Response } from '../types';
import { User } from '../types/User';

import { dsmOpsAxios } from '.';

export const checkEmailExist = async (params: {
  email: string;
  exact: boolean;
}) => {
  return dsmOpsAxios.head(
    `/v1/users?email=${params.email}&exact=${params.exact}`,
  );
};

export const getWho = async (email) => {
  return dsmOpsAxios.get<Response<User>>(`/v1/who?email=${email}`);
};
