import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowRightSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="80" height="80" viewBox="0 0 80 80" fill="none" {...props}>
      <path
        d="M32 56L48 40L32 24"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default ArrowRightSvgIcon;
