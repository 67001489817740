import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { User } from '../types/User';

const initialState: User = {
  id: '',
  email: '',
  firstName: null,
  lastName: null,
  imageURL: null,
  createdAt: '',
  org: {
    id: '',
    name: '',
    country: '',
    color: '',
    ownerId: '',
    status: 'ACTIVE',
    taxId: '',
  },
  role: '',
  username: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    resetUser: () => {
      return initialState;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
