import { FC, MouseEvent, useRef, useState } from 'react';
import { ClickAwayListener, Popper, styled } from '@mui/material';
import { format } from 'date-fns';

import { RmrTicket } from '../types/RmrTicket';

import { getRmrTicketRevision } from '../apis/rmrApi';
import { useAppDispatch } from '../hooks';
import { setRmrTicket } from '../slices/rmrSlice';

import CheckSvgIcon from './SvgIcon/CheckSvgIcon';
import HistoryVersionSvgIcon from './SvgIcon/HistoryVersionSvgIcon';
import StatusIcon from './StatusIcon';

const Container = styled('div')(({ theme }) => {
  return {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: '8px',
    '&:hover': {
      backgroundColor: theme.color.secondary.$40,
    },
    '&[data-disabled="true"]': {
      pointerEvents: 'none',
      opacity: 0.3,
    },
  };
});

const DropdownContainer = styled('div')(({ theme }) => {
  return {
    backgroundColor: '#fff',
    maxHeight: '184px',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))',
    overflow: 'auto',
    padding: '8px',
    '& > div': {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '16px',
      width: '584px',
      padding: '8px 8px',
      '&.selected': {
        color: theme.color.secondary.$100,
      },
      '&:hover': {
        backgroundColor: theme.color.secondary.$40,
      },
      '& > img': {
        display: 'block',
        width: '32px',
        height: '32px',
        objectFit: 'cover',
        borderRadius: '50%',
      },
      '& > .check-wrapper': {
        width: '40px',
        height: '40px',
        '& > .check': {
          color: '#E6a600',
        },
      },
      '& > .name': {
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  };
});

interface RmrVersionDropdownProps {
  rmrTicket: RmrTicket | undefined;
  currentRmrTicket: RmrTicket | undefined;
  disabled?: boolean;
}

const RmrVersionDropdown: FC<RmrVersionDropdownProps> = ({
  rmrTicket,
  currentRmrTicket,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [versionIndex, setVersionIndex] = useState(0);

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleSelectVersion = async (e: MouseEvent, verisonIndex: number) => {
    e.stopPropagation();
    setVersionIndex(verisonIndex);

    if (rmrTicket) {
      if (verisonIndex > 0) {
        const result = await getRmrTicketRevision(
          rmrTicket.id,
          rmrTicket.revisions[rmrTicket.revisions.length - verisonIndex].id,
        );
        dispatch(setRmrTicket(result.data.data));
      } else {
        dispatch(setRmrTicket(rmrTicket));
      }
    }
  };

  const items = rmrTicket?.revisions
    .concat({
      id: rmrTicket.id,
      createdAt: rmrTicket.createdAt,
      status: rmrTicket.status,
      version: rmrTicket.version,
      editor: rmrTicket.createdBy,
    })
    .reverse()
    .map((revision, index) => {
      return (
        <div
          key={`revision-item-${revision.id}`}
          className={versionIndex === index ? 'selected' : undefined}
          onClick={(e) => handleSelectVersion(e, index)}
        >
          <div className="check-wrapper">
            {versionIndex === index && (
              <CheckSvgIcon
                className="check"
                sx={{ width: '40px', height: '40px' }}
              />
            )}
          </div>
          <StatusIcon status={revision.status} />
          <span>{`V${revision.version.toString().padStart(3, '0')}`}</span>
          <img src="/assets/image/svg/user_default_avatar.svg" />
          <span
            className="name"
            title={`${revision.editor.firstName} ${revision.editor.lastName}`}
          >
            {`${revision.editor.firstName} ${revision.editor.lastName}`}
          </span>
          <span>Created</span>
          <span>
            {format(new Date(revision.createdAt), 'yyyy - MM - dd HH : mm')}
          </span>
        </div>
      );
    });

  return (
    <>
      <Container
        ref={(ref) => (containerRef.current = ref)}
        data-disabled={disabled}
        onClick={() => {
          if (!disabled) {
            setOpen(!open);
          }
        }}
      >
        {`V${(currentRmrTicket?.version ?? '').toString().padStart(3, '0')}`}
        <HistoryVersionSvgIcon
          sx={{
            display: 'inline-block',
            width: '32px',
            height: '32px',
            marginLeft: '8px',
          }}
        />
        <Popper
          sx={(theme) => ({
            color: theme.color.secondary.$60,
            zIndex: 2000,
          })}
          open={open}
          anchorEl={containerRef.current}
          placement="bottom-end"
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ],
          }}
        >
          <ClickAwayListener onClickAway={handleOnClose}>
            <DropdownContainer>{items}</DropdownContainer>
          </ClickAwayListener>
        </Popper>
      </Container>
    </>
  );
};

export default RmrVersionDropdown;
