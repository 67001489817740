import type { SVGProps } from 'react';

const SuccesssSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <circle cx={16} cy={16} r={10} fill="currentColor" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={2}
      d="m12 16 3 3 5-5"
    />
  </svg>
);
export default SuccesssSvgIcon;
