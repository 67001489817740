import type { SVGProps } from 'react';
const PermitToWorkSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 5a3 3 0 0 1 3 3h3a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H10a3 3 0 0 1-3-3V11a3 3 0 0 1 3-3h3a3 3 0 0 1 3-3Zm5 6v-1h1a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1Zm-5-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm4.707 7.707a1 1 0 0 0-1.414-1.414L15 19.586l-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3 .707.707.707-.707 5-5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PermitToWorkSvgIcon;
