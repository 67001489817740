import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { AuthState } from '../types/Store';

const initialState: AuthState = {
  accessToken: localStorage.getItem('access_token'),
  expiresIn: parseInt(localStorage.getItem('expires_in') ?? '0', 10),
  refreshToken: localStorage.getItem('refresh_token'),
  refreshExpiresIn: parseInt(
    localStorage.getItem('refresh_expires_in') ?? '0',
    10,
  ),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{
        [K in keyof AuthState]: NonNullable<AuthState[K]>;
      }>,
    ) => {
      const now = Date.now();
      localStorage.setItem('access_token', action.payload.accessToken);
      localStorage.setItem('expires_in', action.payload.expiresIn.toString());
      localStorage.setItem('refresh_token', action.payload.refreshToken);
      localStorage.setItem(
        'refresh_expires_in',
        action.payload.refreshExpiresIn.toString(),
      );
      return {
        accessToken: action.payload.accessToken,
        expiresIn: now + action.payload.expiresIn * 1000,
        refreshToken: action.payload.refreshToken,
        refreshExpiresIn: now + action.payload.refreshExpiresIn * 1000,
      };
    },
    logout: () => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('expires_in');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('refresh_expires_in');
      return {
        accessToken: null,
        expiresIn: 0,
        refreshToken: null,
        refreshExpiresIn: 0,
      };
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
