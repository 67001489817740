import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { setSidebar } from '../../slices/systemSlice';

const checkIsActive = ({ isActive }: { isActive: boolean }) => {
  return isActive ? 'selected' : undefined;
};

const Container = styled('aside')(({ theme }) => {
  return {
    flex: '0 0 270px',
    display: 'flex',
    flexDirection: 'column',
    width: '270px',
    height: '100vh',
    padding: '16px',
    '& > .logo-wrapper': {
      flex: '0 0 auto',
      padding: '32px 39px',
      '& > .logo': {
        width: '100%',
      },
    },
    '& > .list-container': {
      flex: '1',
      minHeight: '0',
      padding: '0 21px',
      overflow: 'auto',
      '&  ul': {
        listStyleType: 'none',
        padding: 0,
        margin: '0',
        '& .option': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .option, & li': {
          minHeight: '32px',
          margin: '16px 0',
          '& > a': {
            fontSize: '1.25rem',
            lineHeight: 1.333333,
            color: 'white',
            textDecoration: 'none',
            paddingLeft: '40px',
            '&.selected': {
              display: 'flex',
              alignItems: 'center',
              color: theme.color.primary.$100,
              fontWeight: 700,
              paddingLeft: 0,
              '&:before': {
                display: 'inline-block',
                width: '32px',
                height: '32px',
                content: 'url("/assets/image/svg/list-focus.svg")',
                marginRight: '8px',
              },
            },
          },
        },
        '& > a': {
          color: 'white',
          fontSize: '1.25rem',
          lineHeight: 1.333333,
          textDecoration: 'none',
          margin: '16px 0',
          paddingLeft: '40px',
          '&.selected': {
            alignItems: 'center',
            color: theme.color.primary.$100,
            fontWeight: 700,
            paddingLeft: 0,
            '&:before': {
              width: '32px',
              height: '32px',
              content: 'url("/assets/image/svg/list-focus.svg")',
              marginRight: '8px',
            },
          },
        },
        '& > ul > li': {
          display: 'flex',
          alignItems: 'center',
          margin: '16px 0',
          '& > a': {
            color: 'white',
            fontSize: '1.125rem',
            lineHeight: 1.4,
            textDecoration: 'none',
            '&.selected': {
              color: theme.color.primary.$100,
              fontWeight: 700,
              '&:before': {
                width: '32px',
                height: '32px',
                content: 'url("/assets/image/svg/list-focus-sub.svg")',
                marginRight: '8px',
              },
            },
          },
        },
      },
    },
    '& > .version': {
      flex: '0 0 auto',
      textAlign: 'center',
    },
  };
});

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isHidden } = useAppSelector((store) => store.system.sidebar);
  const [show, setShow] = useState(!isHidden);

  useEffect(() => {
    const handleSidebar = (event: KeyboardEvent) => {
      if (event.key === 'F2') {
        setShow((prev) => !prev);
      }
    };
    document.addEventListener('keyup', handleSidebar);

    return () => {
      document.removeEventListener('keyup', handleSidebar);
    };
  }, []);

  useEffect(() => {
    if (isHidden !== !show) {
      dispatch(
        setSidebar({
          isHidden: !show,
        }),
      );
    }
  }, [isHidden, show]);

  if (isHidden) {
    return null;
  }

  return (
    <Container>
      <div className="logo-wrapper">
        <img className="logo" src="/assets/image/png/logo.png" />
      </div>
      <div className="list-container">
        <ul>
          <li>
            <NavLink to="/devices/das-endpoint" className={checkIsActive}>
              Devices
            </NavLink>
          </li>
          <ul>
            <li>
              <NavLink to="/org-setting" className={checkIsActive}>
                Organization Setting
              </NavLink>
            </li>
          </ul>
          <ul>
            <div className="option">
              <NavLink to="/rmr" className={checkIsActive}>
                RMR
              </NavLink>
            </div>
            {/\/rmr\/*/.test(location.pathname) && (
              <li>
                <NavLink to="/rmr/hardware" className={checkIsActive}>
                  Hardware
                </NavLink>
              </li>
            )}
          </ul>
          {/* <ul>
            <div className="option">
              <NavLink to="/task-list" className={checkIsActive}>
                Task List
              </NavLink>
            </div>
          </ul> */}
          <ul>
            <div className="option">
              <NavLink to="/feature-list" className={checkIsActive}>
                Feature List
              </NavLink>
            </div>
          </ul>
          <ul>
            <div className="option">
              <NavLink to="/project-setting" className={checkIsActive}>
                Project Setting
              </NavLink>
            </div>
            {/\/project-setting\/*/.test(location.pathname) && (
              <li>
                <NavLink
                  to="/project-setting/3d-model"
                  className={checkIsActive}
                >
                  3D Model
                </NavLink>
              </li>
            )}
          </ul>
          <ul>
            <div className="option">
              <NavLink to="/transfer-pa" className={checkIsActive}>
                Transfer PA
              </NavLink>
            </div>
          </ul>
        </ul>
      </div>
      <div className="version">v{__APP_VERSION__}</div>
    </Container>
  );
};

export default Sidebar;
