import { useMemo, useState } from 'react';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';

import { DasEndpoint } from '../../../../types/Device';

import {
  getDasEndpoints,
  updateDasEndPoints,
} from '../../../../apis/dasEndpointApi';

import AddButton from '../../../../components/AddButton';
import Input from '../../../../components/Input';
import AddSvgIcon from '../../../../components/SvgIcon/AddSvgIcon';
import CrossSvgIcon from '../../../../components/SvgIcon/CrossSvgIcon';
import TrashSvgIcon from '../../../../components/SvgIcon/TrashSvgIcon';

import { inputSx } from '../../../../theme';
import Validator from '../../../../utils/Validator';

const Dialog = styled(MuiDialog)(({ theme }) => {
  return {
    '& .MuiPaper-root': {
      width: '520px',
      '& .header': {
        ...theme.typography.subtitle1,
        position: 'relative',
        textAlign: 'center',
        fontSize: '1.125rem',
        padding: '16px',
      },
    },
    '& .banner': {
      color: '#fff',
      fontSize: '1rem',
      padding: '16px',
      margin: '32px 0 16px 0',
      '&[data-status="error"]': {
        background: theme.color.highlight,
      },
      '&[data-status="success"]': {
        background: theme.color.green.$100,
      },
    },
  };
});

const DialogContent = styled(MuiDialogContent)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px 30px',
    '& > .property': {
      marginBottom: '16px',
      '& > label': {
        color: '#969696',
        '& > span': {
          color: theme.color.highlight,
        },
      },
    },
  };
});

const DialogActions = styled(MuiDialogActions)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
  };
});

const FormContainer = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 20px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.gray.$235};
  background: ${({ theme }) => theme.color.gray.$250};
  border-radius: 8px;
  & > h3 {
    width: 100%;
    text-align: left;
    color: ${({ theme }) => theme.color.secondary.$100};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }
  & > div {
    width: 100%;
    & > label {
      color: ${({ theme }) => theme.color.secondary.$60};
      & > span {
        color: ${({ theme }) => theme.color.highlight};
      }
    }
    input {
      background-color: #fff;
      &:read-only {
        background-color: #f3f3f3;
      }
    }
  }
`;

interface EditDascasDialogProps extends DialogProps {
  dascas:
    | (DasEndpoint & {
        bindingDascasGMap: { [id: string]: DasEndpoint | undefined };
      })
    | undefined;
  onEditSuccess?: () => void;
}

const EditDascasDialog: React.FC<EditDascasDialogProps> = ({
  dascas,
  onClose,
  onEditSuccess,
  ...otherProps
}) => {
  const [config, setConfig] = useState<{
    dasId: string;
    uwbId: string;
    collisionId: string;
    backupCollisionId: string;
    sim: string;
    dascasGMap: { [dasId: string]: DasEndpoint | undefined };
  }>({
    dasId: dascas?.dasId ?? '',
    uwbId: dascas?.metadata.uwbId ?? '',
    sim: dascas?.metadata.sim ?? '',
    collisionId: dascas?.metadata.collisionId,
    backupCollisionId: dascas?.metadata.backupCollisionId,
    dascasGMap: dascas?.bindingDascasGMap ?? {},
  });
  const [responseSatusResult, setResponseSatusResult] = useState<{
    status: 'success' | 'error';
    message: string;
  }>({
    status: 'success',
    message: '',
  });

  const isValid = useMemo(() => {
    const dascasGsResult = Object.values(config.dascasGMap).reduce(
      (prev, curr) => {
        if (curr?.dasId) {
          return prev && /^P[A-Z]{2}G[A-Z]{3}\d{6}/.test(curr.dasId);
        }
        return false;
      },
      true,
    );
    return (
      !Validator.validateDasCasDasId(config.dasId).isError &&
      !!config.collisionId &&
      !!config.backupCollisionId &&
      dascasGsResult
    );
  }, [config]);

  const dasgasGPropertyContainers = Object.entries(config.dascasGMap).map(
    ([key, d]) => {
      if (d) {
        return (
          <div
            key={`dascas-g-property-container-${d.id}`}
            className="property-container"
          >
            <label>
              Main Detector<span>*</span>
            </label>
            <Input
              sx={inputSx}
              value={d.dasId}
              onChange={(e) => {
                const dasId = e.currentTarget.value;
                setConfig((origin) => {
                  return {
                    ...origin,
                    dascasGMap: {
                      ...origin.dascasGMap,
                      [key]: {
                        ...d,
                        dasId,
                      },
                    },
                  };
                });
              }}
            />
          </div>
        );
      }
    },
  );

  const handleUpdate = async () => {
    setResponseSatusResult({
      status: 'success',
      message: '',
    });
    const bindingDascasGs: string[] = [];
    const dascasGs = Object.values(config.dascasGMap);
    for (let i = 0; i < dascasGs.length; i++) {
      const d = dascasGs[i];
      if (d) {
        if (/^dascas_g_/.test(d.id ?? '')) {
          const res = await getDasEndpoints({
            dasId: d.dasId,
          });

          if (res.data.data.length === 1) {
            const dascas = res.data.data[0];
            bindingDascasGs.push(dascas.id);
          } else {
            setResponseSatusResult({
              status: 'error',
              message: `Device ${d.dasId} is not exist`,
            });
            return;
          }
        } else {
          bindingDascasGs.push(d.id);
        }
      }
    }
    const payload = {
      uwbId: config.uwbId === '' ? '-' : config.uwbId,
      sim: config.sim === '' ? '-' : config.sim,
      collisionId: config.collisionId,
      backupCollisionId: config.backupCollisionId,
      bindingDascasGs,
    };
    try {
      await updateDasEndPoints({
        dasId: config.dasId,
        payload,
      });

      setResponseSatusResult({
        status: 'success',
        message: `Endpoint updated successfully`,
      });

      onEditSuccess?.();
    } catch (error) {
      if (error instanceof AxiosError) {
        setResponseSatusResult({
          status: 'error',
          message: error.response?.data.error.message,
        });
      } else if (error instanceof Error) {
        setResponseSatusResult({
          status: 'error',
          message: error.message,
        });
      }
    }
  };

  return (
    <Dialog {...otherProps}>
      <div className="header">
        Edit DasCAS
        <CrossSvgIcon
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 1,
            width: '40px',
            height: '40px',
          }}
          onClick={(e) => {
            onClose?.(e, 'backdropClick');
          }}
        />
      </div>
      {responseSatusResult.message !== '' && (
        <div className="banner" data-status={responseSatusResult.status}>
          {responseSatusResult.message}
        </div>
      )}
      <DialogContent>
        <FormContainer>
          <Typography component="h3">DasCAS</Typography>
          <div className="property-container">
            <label>
              Das ID(Cabin Alarm)<span>*</span>
            </label>
            <Input
              sx={inputSx}
              value={config.dasId}
              onChange={(e) => {
                setConfig({ ...config, dasId: e.currentTarget.value });
              }}
            />
          </div>
          <div className="property-container">
            <label>UWB ID</label>
            <Input
              sx={inputSx}
              value={config.uwbId}
              onChange={(e) => {
                setConfig({ ...config, uwbId: e.currentTarget.value });
              }}
            />
          </div>
          {/* <div className="property-container">
            <label>SIM Card</label>
            <Input
              sx={inputSx}
              value={config.sim}
              onChange={(e) => {
                setConfig({ ...config, sim: e.currentTarget.value });
              }}
            />
          </div> */}
        </FormContainer>
        <FormContainer>
          <Typography component="h3">Hock Tag</Typography>
          <div className="property-container">
            <label>
              Hock Tag<span>*</span>
            </label>
            <Input
              sx={inputSx}
              value={config.collisionId}
              onChange={(e) => {
                setConfig({ ...config, collisionId: e.currentTarget.value });
              }}
            />
          </div>
          <div className="property-container">
            <label>
              Hock Tag<span>*</span>
            </label>
            <Input
              sx={inputSx}
              value={config.backupCollisionId}
              onChange={(e) => {
                setConfig({
                  ...config,
                  backupCollisionId: e.currentTarget.value,
                });
              }}
            />
          </div>
        </FormContainer>
        <FormContainer>
          {dasgasGPropertyContainers}
          <AddButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setConfig((origin) => {
                const dascases = Object.values(origin.dascasGMap);
                return {
                  ...origin,
                  dascasGMap: {
                    ...origin.dascasGMap,
                    [`dascas_g_${dascases.length + 1}`]: {
                      ...(dascases[0] as unknown as DasEndpoint),
                      id: `dascas_g_${dascases.length + 1}`,
                      dasId: '',
                    },
                  },
                };
              });
            }}
          >
            <AddSvgIcon color="primary" /> Add Main Detector
          </AddButton>

          {Object.values(config.dascasGMap).length > 4 && (
            <AddButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setConfig((origin) => {
                  const dascasEntries = Object.entries(origin.dascasGMap);
                  const localDascasGMap = { ...origin.dascasGMap };
                  delete localDascasGMap[
                    `${dascasEntries[dascasEntries.length - 1][0]}`
                  ];
                  return {
                    ...origin,
                    dascasGMap: localDascasGMap,
                  };
                });
              }}
            >
              <TrashSvgIcon color="primary" /> Remove Main Detector
            </AddButton>
          )}
        </FormContainer>
      </DialogContent>
      <DialogActions>
        <DialogButton
          variant="text"
          color="primary"
          sx={(theme) => ({
            color: theme.color.primary.$100,
          })}
          onClick={(e) => onClose?.(e, 'backdropClick')}
        >
          Cancel
        </DialogButton>
        <DialogButton
          variant="contained"
          color="primary"
          disabled={!isValid}
          onClick={handleUpdate}
        >
          Update
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditDascasDialog;
