import { FC, MouseEvent, ReactNode, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { styled } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { DasEndpointType } from '../types/Device';
import { RmrCause as RmrCauseData } from '../types/RmrTicket';

import { deleteRmrCause } from '../apis/rmrApi';

import { parseDasId } from '../utils/common';

import ArrowLeftSvgIcon from './SvgIcon/ArrowLeftSvgIcon';
import ArrowRightSvgIcon from './SvgIcon/ArrowRightSvgIcon';
import CreateRmrSolution from './CreateRmrSolution';
import DeviceTag from './DeviceTag';
import EditRmrCause from './EditRmrCause';
import MoreOptionPopper from './MoreOptionPopper';

const Container = styled('div')(({ theme }) => {
  return {
    marginTop: '8px',
    padding: '16px',
    backgroundColor: theme.color.gray.$240,
    border: `1px solid ${theme.color.secondary.$40}`,
    borderRadius: '4px',

    '& > .header': {
      display: 'flex',
      alignItems: 'center',

      '& > div:nth-of-type(1)': {
        flex: 1,
        paddingLeft: '56px',
        '& > .cause-number': {
          ...theme.typography.caption,
          color: theme.color.secondary.$80,
        },
        '& > .title': {
          fontSize: '1.125rem',
          fontWeight: '700',
        },
      },
      '& .icon-more': {
        cursor: 'pointer',
        '&[data-disabled="true"]': {
          cursor: 'default',
          opacity: 0.3,
        },
      },
    },

    '& > .content': {
      '& > .device-tag-Container': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        paddingLeft: '56px',
        margin: '16px 0',
      },
      '& > .picture-control-container': {
        position: 'relative',
        height: '150px',
        '& > .picture-container': {
          position: 'relative',
          width: '764px',
          height: '150px',
          overflow: 'hidden',
          margin: '0 56px',
        },
        '& .picture': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '150px',
          overflow: 'hidden',
          transition: 'left 0.3s',
          '& > .list': {
            position: 'absolute',
            display: 'flex',
            gap: '20px',
            '& > img': {
              cursor: 'pointer',
              width: '150px',
              height: '150px',
              objectFit: 'cover',
            },
          },
        },
        '& .page-button': {
          cursor: 'pointer',
          position: 'absolute',
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          width: '56px',
          height: '100%',
          '&.previous': {
            top: 0,
            left: 0,
            justifyContent: 'flex-start',
          },
          '&.next': {
            top: 0,
            right: 0,
            justifyContent: 'flex-end',
          },
        },
      },
      '& > .description': {
        fontSize: '0.875rem',
        paddingLeft: '56px',
        margin: '16px 0',
      },
      '& > .create-solution-container': {
        textAlign: 'center',
        margin: '16px 0',
      },
    },
  };
});

interface RmrCauseProps {
  disabledMoreOption?: boolean;
  issueId: string;
  issueIndex: number;
  devicePool: string[];
  causeIndex: number;
  data: RmrCauseData;
  children?: ReactNode;
  refetch?: () => void;
}

const RmrCause: FC<RmrCauseProps> = ({
  disabledMoreOption = false,
  issueId,
  issueIndex,
  devicePool,
  causeIndex,
  data,
  children,
  refetch,
}) => {
  const pictureRef = useRef<HTMLDivElement | null>(null);
  const moreOptionRef = useRef<HTMLImageElement | null>(null);
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const [showCreateSolution, setShowCreateSolution] = useState(false);
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [picturePage, setPicturePage] = useState(1);

  const deleteCauseMutation = useMutation({
    mutationFn: deleteRmrCause,
    onError: (error) => {
      if (error instanceof AxiosError) {
        alert(error.response?.data?.error?.message ?? error.message);
      }
    },
    onSuccess: () => {
      refetch?.();
    },
  });

  const handleOnClickPrevious = () => {
    if (picturePage - 1 >= 1) {
      setPicturePage(picturePage - 1);
    }
  };

  const handleOnClickNext = () => {
    if (picturePage + 1 <= totalPage) {
      setPicturePage(picturePage + 1);
    }
  };

  const handleOnDelete = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    if (
      confirm(
        `Do you really delete the cause ${causeIndex + 1}. "${data.title}"?`,
      )
    ) {
      if (ticketId) {
        deleteCauseMutation.mutate({
          ticketId,
          issueId,
          causeId: data.id,
        });
      }
    }
  };

  const handleOnEdit = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setShowMoreOption(false);
    setMode('edit');
  };

  const handleShowMoreOption = (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    if (!disabledMoreOption) {
      setShowMoreOption(!showMoreOption);
    }
  };

  const totalPage = Math.ceil(data.photos.length / 4);

  const images = data.photos.map((photo, index) => {
    return (
      <img
        key={`cause-${data.id}-photo-${index}`}
        src={photo.url}
        onClick={() =>
          navigate(`issue/${issueIndex}/cause/${causeIndex}/photo/${index}`)
        }
      />
    );
  });

  const deviceTags = data.issueDevices.map((dasId) => {
    return (
      <DeviceTag
        key={`cause-${data.id}-dasId-${dasId}`}
        mode="view"
        type={parseDasId(dasId) as DasEndpointType}
        dasId={dasId}
      />
    );
  });

  if (mode === 'view')
    return (
      <Container>
        <div className="header">
          <div>
            <div className="cause-number">CAUSE {causeIndex + 1}.</div>
            <div className="title">{data.title}</div>
          </div>
          <img
            ref={moreOptionRef}
            className="icon-more"
            src="/assets/image/svg/more.svg"
            data-disabled={disabledMoreOption}
            onClick={handleShowMoreOption}
          />
          <MoreOptionPopper
            anchorEl={moreOptionRef.current}
            placement="bottom-end"
            open={showMoreOption}
            onClose={() => setShowMoreOption(false)}
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
          />
        </div>
        <div className="content">
          <div className="device-tag-Container">{deviceTags}</div>
          {images.length > 0 && (
            <div className="picture-control-container">
              {picturePage > 1 && (
                <div
                  className="page-button previous"
                  onClick={handleOnClickPrevious}
                >
                  <ArrowLeftSvgIcon sx={{ width: 40, height: 40 }} />
                </div>
              )}
              <div className="picture-container">
                <div
                  ref={pictureRef}
                  className="picture"
                  style={{ left: `${(picturePage - 1) * -680}px` }}
                >
                  <div className="list">{images}</div>
                </div>
              </div>
              {picturePage < totalPage && (
                <div className="page-button next" onClick={handleOnClickNext}>
                  <ArrowRightSvgIcon sx={{ width: 40, height: 40 }} />
                </div>
              )}
            </div>
          )}
          <div className="description">{data.description}</div>
          {showCreateSolution ? (
            <CreateRmrSolution
              issueId={issueId}
              causeId={data.id}
              number={data.solutions.length + 1}
              issueIndex={issueIndex}
              causeIndex={causeIndex}
              devicePool={data.issueDevices}
              onClose={() => {
                setShowCreateSolution(false);
                refetch?.();
              }}
            />
          ) : (
            !disabledMoreOption && (
              <div className="create-solution-container">
                <DialogButton
                  variant="contained"
                  color="primary"
                  endIcon={<div>+</div>}
                  onClick={() => setShowCreateSolution(true)}
                >
                  SOLUTION
                </DialogButton>
              </div>
            )
          )}
          {children}
        </div>
      </Container>
    );

  if (mode === 'edit')
    return (
      <EditRmrCause
        issueId={issueId}
        issueIndex={issueIndex}
        devicePool={devicePool}
        number={causeIndex + 1}
        data={data}
        onClose={() => {
          setMode('view');
        }}
        onSuccess={refetch}
      />
    );

  return null;
};

export default RmrCause;
