import { SvgIcon, SvgIconProps } from '@mui/material';

const OverdueIconSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
      <circle cx="5" cy="5" r="5" fill="#FF6B00" />
      <path
        d="M5 2V5L7 7"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default OverdueIconSvgIcon;
