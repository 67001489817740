import { SyntheticEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

const Container = styled('div')(() => {
  return {};
});

const Tabs = styled(MuiTabs)(() => {
  return {};
});

const Tab = styled(MuiTab)(() => {
  return {};
});

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnChangeTab = (e: SyntheticEvent<Element, Event>, value: any) => {
    navigate(`/devices/${value}`, { replace: true });
  };

  return (
    <Container>
      <Tabs
        value={location.pathname.split('/')?.[2] ?? 'das-endpoint'}
        onChange={handleOnChangeTab}
      >
        <Tab label="Device" value="das-endpoint" />
        <Tab label="Locator" value="locator" />
      </Tabs>
    </Container>
  );
};

export default Header;
