import { ReactNode } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';

import DialogCloseSvgIcon from '../../assets/svg/dialog/DialogCloseSvgIcon';

const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    min-width: 600px;
    border-radius: 10px;
  }

  & .header {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: ${({ theme }) => theme.color.box_bbg};
    padding: 12px 16px;

    .button {
      cursor: pointer;
    }

    & .start-container {
      display: flex;
      align-items: center;
      &::after {
        display: inline-block;
        content: '';
        width: 2px;
        height: 30px;
        background-color: ${({ theme }) => theme.color.secondary.$40};
        border-radius: 1px;
        margin-left: 20px;
      }
    }

    & .center-container {
      flex: 1;
    }

    & .end-container {
      display: flex;
      align-items: center;
      &::before {
        display: inline-block;
        content: '';
        width: 2px;
        height: 30px;
        background-color: ${({ theme }) => theme.color.secondary.$40};
        border-radius: 1px;
        margin-right: 20px;
      }
    }
  }
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 24px;
`;

interface InformationDialogProps extends DialogProps {
  titleNode?: {
    start?: ReactNode;
    center?: ReactNode;
    end?: ReactNode;
  };
  closeable?: boolean;
  onClose?: () => void;
}

const InformationDialog: React.FC<InformationDialogProps> = ({
  titleNode,
  closeable,
  onClose,
  children,
  ...otherProps
}) => {
  return (
    <Dialog {...otherProps}>
      <div className="header">
        {titleNode?.start && (
          <div className="start-container">{titleNode?.start}</div>
        )}
        <div className="center-container">{titleNode?.center}</div>
        {closeable && (
          <div className="end-container">
            {titleNode?.end}
            <DialogCloseSvgIcon className="button" onClick={onClose} />
          </div>
        )}
      </div>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default InformationDialog;
