import { styled } from '@mui/material';

const Container = styled('div')`
  padding: 10px 30px;
  & > .title {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.5;
    text-align: center;
  }

  & > .description {
    text-align: center;
    margin: 20px 70px;
  }
`;

interface TransferDeviceContentProps {
  successCount: number;
  failureCount: number;
  totalCount: number;
}

const TransferDeviceContent: React.FC<TransferDeviceContentProps> = ({
  successCount,
  failureCount,
  totalCount,
}) => {
  return (
    <Container>
      <div className="title">
        Transfering...
        {`(${successCount + failureCount}/${totalCount})`}
      </div>
      <div className="description">
        Please do not close the window. Otherwise some devices will not transfer
        to new account.
      </div>
    </Container>
  );
};

export default TransferDeviceContent;
