import { FC, useRef, useState } from 'react';
import {
  Box,
  BoxProps,
  ClickAwayListener,
  Popper,
  styled,
} from '@mui/material';

import ArrowDownSvgIcon from './SvgIcon/ArrowDownSvgIcon';

const Container = styled(Box)(({ theme }) => {
  return {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: '#FFF',
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: 1.5,
    textTransform: 'uppercase',
    borderRadius: '4px',
    padding: '0 10px 0 26px',
    backgroundColor: theme.color.primary.$100,
  };
});

const PopperContainer = styled('div')(({ theme }) => {
  return {
    minWidth: '154px',
    padding: '1px 5px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '& > div': {
      cursor: 'pointer',
      padding: '6.5px 16px',
      borderRadius: '4px',
      color: theme.color.secondary.$100,
      margin: '4px 0',
      '&[data-selected=true]': {
        color: '#fff',
        backgroundColor: theme.color.primary.$100,
      },
      '&:hover': {
        color: '#fff',
        backgroundColor: theme.color.primary.$100,
      },
    },
  };
});

interface NormalDropdownProps extends Omit<BoxProps, 'onSelect'> {
  list: Array<{ id: string; name: string; value: string }>;
  value: string;
  disabled?: boolean;
  onSelect?: (value: string) => void;
}

const NormalDropdown: FC<NormalDropdownProps> = ({
  list,
  value,
  disabled = false,
  onSelect,
  ...props
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleOnSelect = (value: string) => {
    if (!disabled) {
      onSelect?.(value);
      setOpen(false);
    }
  };

  const items = list.map((data) => {
    return (
      <div
        key={`normal-dropdown-items-${data.id}`}
        data-type={data.value}
        data-selected={value === data.value}
        onClick={() => handleOnSelect(data.value)}
      >
        {data.name}
      </div>
    );
  });

  return (
    <>
      <Container
        data-status={status}
        ref={dropdownRef}
        onClick={() => setOpen(!open)}
        {...props}
      >
        {value}
        <ArrowDownSvgIcon
          sx={{ display: 'block', width: '32px', height: '32px' }}
        />
      </Container>
      <Popper
        sx={(theme) => ({
          color: theme.color.secondary.$60,
          zIndex: 2000,
        })}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
        open={open}
        anchorEl={dropdownRef.current}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <PopperContainer>{items}</PopperContainer>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default NormalDropdown;
