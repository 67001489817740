import type { SVGProps } from 'react';

const DasgasSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 8a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8Zm7 14a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm10 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM8 9h16v9H8V9Zm15 1H9v7h14v-7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DasgasSvgIcon;
