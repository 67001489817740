import type { SVGProps } from 'react';

const DasairSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M6 16h15.972c.6 0 1.184-.176 1.682-.506s.886-.798 1.116-1.346a2.973 2.973 0 0 0-.657-3.27 3.054 3.054 0 0 0-3.3-.65 3.021 3.021 0 0 0-1.358 1.105 2.98 2.98 0 0 0-.51 1.667M6 12h6.957c.601 0 1.19-.176 1.69-.506s.89-.798 1.121-1.346a2.96 2.96 0 0 0 .173-1.733 2.987 2.987 0 0 0-.832-1.536 3.058 3.058 0 0 0-1.559-.821 3.084 3.084 0 0 0-1.758.17 3.034 3.034 0 0 0-1.366 1.105A2.969 2.969 0 0 0 9.913 9M6 20h6.957c.601 0 1.19.176 1.69.506s.89.798 1.121 1.346c.23.548.291 1.151.173 1.733a2.987 2.987 0 0 1-.832 1.536 3.084 3.084 0 0 1-3.317.65 3.033 3.033 0 0 1-1.366-1.104A2.969 2.969 0 0 1 9.913 23" />
    </g>
  </svg>
);
export default DasairSvgIcon;
