import type { SVGProps } from 'react';

const AttendanceSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4472 4.10557C16.1657 3.96481 15.8343 3.96481 15.5528 4.10557L7.55279 8.10557C7.214 8.27496 7 8.62123 7 9V14C7 14.3788 7.214 14.725 7.55279 14.8944L15.5528 18.8944C15.8343 19.0352 16.1657 19.0352 16.4472 18.8944L24.4472 14.8944C24.786 14.725 25 14.3788 25 14V9C25 8.62123 24.786 8.27496 24.4472 8.10557L16.4472 4.10557ZM9 13.382V10.618L15 13.618V16.382L9 13.382ZM16 11.882L10.2361 9L16 6.11803L21.7639 9L16 11.882ZM7.47427 17.1493C7.76909 16.9671 8.13723 16.9506 8.44721 17.1056L16 20.882L23.5528 17.1056C23.8628 16.9506 24.2309 16.9671 24.5257 17.1493C24.8205 17.3315 25 17.6534 25 18V23C25 23.3788 24.786 23.725 24.4472 23.8944L16.4472 27.8944C16.1657 28.0352 15.8343 28.0352 15.5528 27.8944L7.55279 23.8944C7.214 23.725 7 23.3788 7 23V18C7 17.6534 7.17945 17.3315 7.47427 17.1493ZM9 19.618V22.382L15 25.382V22.618L9 19.618Z"
      fill="currentColor"
    />
  </svg>
);
export default AttendanceSvgIcon;
