import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { DasEndpoint } from '../types/Device';

import { getDasEndpoints } from '../apis/dasEndpointApi';

const useDascasMap = () => {
  const [localMap, setLocalMap] = useState<{
    [id: string]:
      | (DasEndpoint & {
          bindingDascasGMap: { [id: string]: DasEndpoint | undefined };
        })
      | undefined;
  }>();
  const {
    data: dascasMap,
    refetch: refetchDascasMap,
    isLoading: isDasgasLoading,
    isFetching: isDasgasFetching,
    isRefetching: isDasgasRefetching,
  } = useQuery(
    ['get-all-dascas'],
    async () => {
      let dascasGs: DasEndpoint[] = [];
      const load = async (nextCursor?: string) => {
        const res = await getDasEndpoints({
          type: 'dascas',
          nextCursor,
        });

        dascasGs = dascasGs.concat(res.data.data);

        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };
      await load();

      const map: {
        [id: string]:
          | (DasEndpoint & {
              bindingDascasGMap: { [id: string]: DasEndpoint | undefined };
            })
          | undefined;
      } = {};
      dascasGs.forEach((g) => {
        map[g.id] = { ...g, bindingDascasGMap: {} };
      });

      return map;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    refetch: refetchDasgasG,
    isLoading: isDasgasGLoading,
    isFetching: isDasgasGFetching,
    isRefetching: isDasgasGRefetching,
  } = useQuery(
    ['get-all-dascas-g', dascasMap],
    async () => {
      let dascasGs: DasEndpoint[] = [];
      const load = async (nextCursor?: string) => {
        const res = await getDasEndpoints({
          type: 'dascas_g',
          nextCursor,
        });

        dascasGs = dascasGs.concat(res.data.data);

        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };

      await load();

      dascasGs.forEach((g) => {
        const dascas = dascasMap?.[g.metadata.towerId];
        if (dascas) {
          dascas.bindingDascasGMap[g.dasId] = g;
        }
      });

      setLocalMap({ ...dascasMap });

      return {};
    },
    {
      enabled: !!dascasMap,
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: localMap,
    refetct: () => {
      refetchDascasMap();
      refetchDasgasG();
    },
    isLoading: isDasgasLoading || isDasgasGLoading,
    isFetching: isDasgasFetching || isDasgasGFetching,
    isRefetching: isDasgasRefetching || isDasgasGRefetching,
  };
};

export default useDascasMap;
