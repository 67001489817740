import type { SVGProps } from 'react';

const DastrackSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.226 16.762a.754.754 0 0 1-.08-.984l7.364-9.394A.992.992 0 0 1 13.316 6a1.004 1.004 0 0 1 .786.403l3.86 5.653L18 12h4v3h3a.5.5 0 0 1 .5.5V18h2a.5.5 0 0 1 .5.5v2.917c0 .154-.06.303-.164.412a.55.55 0 0 1-.397.171H15a1 1 0 0 1-1-1v-2.849a.5.5 0 0 1 .084-.277l1.676-2.514-2.838-5.693-6.561 7.075a.765.765 0 0 1-.563.258.774.774 0 0 1-.572-.238ZM24.5 16H22v2h2.5v-2Zm-8.768 3.832a.17.17 0 0 0 .043.124.16.16 0 0 0 .116.051L20.5 19v-5h-2l-2.768 4.5v1.332Zm-11.228-1.94a.636.636 0 0 0-.256.108.594.594 0 0 0-.183.207.559.559 0 0 0-.065.261v3.658c0 .702.846 1.375 1.96 1.58.228.039.46.058.691.058a4.585 4.585 0 0 0 2.549-.81 9.175 9.175 0 0 0 3.157-4.271.557.557 0 0 0-.147-.637.623.623 0 0 0-.204-.124.663.663 0 0 0-.474.011.612.612 0 0 0-.331.32c-.179.417-.365.807-.557 1.171l-5.86-1.521a.654.654 0 0 0-.28-.01ZM14.5 23a1.5 1.5 0 1 0 0 3h13a1.5 1.5 0 0 0 0-3h-13Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DastrackSvgIcon;
