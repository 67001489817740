import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowLeftSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="80" height="80" viewBox="0 0 80 80" fill="none" {...props}>
      <path
        d="M48 24L32 40L48 56"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
};

export default ArrowLeftSvgIcon;
