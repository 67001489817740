import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <path d="M13 20L14.6667 18.3333L18 21.6667L24.6667 15L26.3333 16.6667L18 25L13 20Z" />
    </SvgIcon>
  );
};

export default CheckSvgIcon;
