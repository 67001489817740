export class CoordinateConverter {
  static R = 6371000;

  static degreeToRadian(degree) {
    return (degree * Math.PI) / 180;
  }

  center: {
    latitude: number;
    longitude: number;
  };

  constructor(latitude: number, longitude: number) {
    this.center = {
      latitude,
      longitude,
    };
  }

  getCenter() {
    return this.center;
  }

  setCenter(latitude: number, longitude: number) {
    this.center = {
      latitude,
      longitude,
    };
  }

  /**
   * @returns distance (meter)
   */

  distance = (latitude, longitude) => {
    const φ1 = CoordinateConverter.degreeToRadian(this.center.latitude);
    const φ2 = CoordinateConverter.degreeToRadian(latitude);
    const Δφ = CoordinateConverter.degreeToRadian(
      latitude - this.center.latitude,
    );
    const Δλ = CoordinateConverter.degreeToRadian(
      longitude - this.center.longitude,
    );
    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = CoordinateConverter.R * c;
    return d;
  };

  geographicToCartesian = (latitude, longitude) => {
    let distanceX = this.distance(this.center.latitude, longitude);
    let distanceZ = this.distance(latitude, this.center.longitude);

    if (this.center.longitude * longitude > 0) {
      if (longitude > this.center.longitude) {
        distanceX *= -1;
      }
    } else if (longitude > 0) {
      distanceX *= -1;
    }

    if (latitude < this.center.latitude) {
      distanceZ *= -1;
    }
    return { x: distanceX, y: 0, z: distanceZ };
  };

  cartesianToGeographic = (x: number, y: number) => {
    const rEarth = 6378;
    const pi = Math.PI;
    const xPos = -x / 1000;
    const yPos = y / 1000;
    const lat = this.center.latitude + (yPos / rEarth) * (180 / pi);
    const lng =
      this.center.longitude +
      ((xPos / rEarth) * (180 / pi)) /
        Math.cos((this.center.latitude * pi) / 180);

    return { latitude: lat, longitude: lng };
  };
}
