import React from 'react';
import { styled } from '@mui/material';

const Button = styled('button')`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.color.secondary.$60};
  padding: 16px 20px;
  background-color: ${({ theme }) => theme.color.gray.$250};
  border: 1px solid ${({ theme }) => theme.color.gray.$235};
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.color.gray.$245};
  }

  &:active {
    background-color: ${({ theme }) => theme.color.gray.$240};
  }
`;

const AddButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = (props) => {
  return <Button {...props} />;
};

export default AddButton;
