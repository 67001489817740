import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const RmrPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/rmr') {
      navigate('/rmr/hardware', { replace: true });
    }
  }, [location]);

  return <Outlet />;
};

export default RmrPage;
