import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';

import Header from './Header';

const Container = styled('div')(() => {
  return {
    height: 'calc(100vh - 74px)',
  };
});

const DevicesPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/devices') {
      navigate('/devices/das-endpoint', { replace: true });
    }
  }, [location]);

  return (
    <Container>
      {/.*\/devices\/(das-endpoint|locator).*/.test(pathname) && <Header />}
      <Outlet />
    </Container>
  );
};

export default DevicesPage;
