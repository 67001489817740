import type { SVGProps } from 'react';

const CctvSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.416 6.034a1 1 0 0 0-1.225.707L6.26 13.953a1 1 0 0 0 .707 1.225l5.52 1.479-1.923 4.119H8v-1.4l-3-1.6v8l3-1.6v-1.4h3.837l.27-.578 2.34-5.016 4.615 1.237 4.41-8.619L9.415 6.034ZM24.8 11.557l-3.15 6.156 4.292 1.15 1.75-6.531-2.892-.775Zm-12.176-.463a1.352 1.352 0 1 1-2.613-.7 1.352 1.352 0 0 1 2.613.7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CctvSvgIcon;
