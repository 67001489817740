import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import {
  Button,
  // DialogButton,
  Dropdown,
} from '@beeinventor/dasiot-react-component-lib';
import { CircularProgress, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Organization, OrganizationDetail } from '../../types/Organization';

import { getOrgDetail, getOrgs } from '../../apis/orgApi';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setNeedRefresh } from '../../slices/systemSlice';

import PageHeaderContainer from '../../components/PageHeaderContainer';

import { delay } from '../../utils/common';

// import AddSvgIcon from '../../components/SvgIcon/AddSvgIcon';
import OrgItem from './OrgItem';

const STATUS_LIST: Array<{
  id: Organization['status'] | 'status_default';
  name: string;
  value: Organization['status'] | 'status_default';
}> = [
  {
    id: 'status_default',
    name: 'All',
    value: 'status_default',
  },
  {
    id: 'ACTIVE',
    name: 'Active',
    value: 'ACTIVE',
  },
  {
    id: 'SUSPENDED',
    name: 'Suspend',
    value: 'SUSPENDED',
  },
];

const CONTRY_LIST: Array<{ id: string; name: string; value: string }> = [
  {
    id: 'country_default',
    name: 'All',
    value: 'country_default',
  },
  {
    id: 'TW',
    name: 'Taiwan',
    value: 'TW',
  },
  {
    id: 'JP',
    name: 'Japan',
    value: 'JP',
  },
  {
    id: 'HK',
    name: 'Hong Kong',
    value: 'HK',
  },
];

const Container = styled('div')`
  width: 100%;
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 74px - 74px);

  & > .add-container {
    text-align: center;
    padding: 24px;
  }

  & > .list-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    padding: 13px 0;

    & > .head {
      display: flex;
      align-items: center;
      padding: 0px 24px 8px 16px;
      margin: 0 48px 8px;
      & > div {
        &:first-of-type {
          width: 30px;
        }
        &:nth-of-type(2) {
          margin: 0 8px;
        }
        &:nth-of-type(3) {
          flex: 2;
        }
        &:nth-of-type(4) {
          flex: 1;
        }
        &:nth-of-type(5) {
          flex: 1;
        }
        &:nth-of-type(6) {
          flex: 1;
        }
      }
    }

    & > .item-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
      overflow: auto;

      & > a {
        text-decoration: none;
        color: inherit;
        margin: 0 48px 8px;
      }

      .loading-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

const OrgSettingPage = () => {
  const dispatch = useAppDispatch();
  const needRefresh = useAppSelector((store) => store.system.needRefresh);
  const mainFilter = useAppSelector((store) => store.system.filter.text);
  const [country, setCountry] = useState<string>();
  const [status, setStatus] = useState<string>();

  const { data: orgs } = useQuery(
    ['get-orgs'],
    async () => {
      const res = await getOrgs();
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: orgsDetail,
    isLoading: isLoadingOrgsDetail,
    refetch,
  } = useQuery(
    ['get-orgs-detail', orgs],
    async () => {
      const localOrgs = orgs as Organization[];
      const localOrgsDetail: OrganizationDetail[] = [];
      for (let i = 0; i < localOrgs.length; i++) {
        const res = await getOrgDetail({ orgId: localOrgs[i].id });
        localOrgsDetail.push(res.data.data);
        await delay(100);
      }
      return localOrgsDetail;
    },
    {
      enabled: !!orgs && orgs.length > 0,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (needRefresh) {
      dispatch(setNeedRefresh(false));
      refetch();
    }
  }, [refetch, needRefresh]);

  const handleOnSelectCountry = (value) => {
    if (value === 'country_default') {
      setCountry(undefined);
    } else {
      setCountry(value);
    }
  };

  const handleOnSelectStatus = (value) => {
    if (value === 'status_default') {
      setStatus(undefined);
    } else {
      setStatus(value);
    }
  };

  const reset = () => {
    setCountry(undefined);
    setStatus(undefined);
  };

  const orgItems = orgsDetail
    ?.filter((org) => {
      const includeOrgName = org.name.includes(mainFilter);
      const includeTaxId = org.taxId.includes(mainFilter);
      return includeOrgName || includeTaxId;
    })
    .filter((org) => {
      if (country) {
        return country === org.country;
      }
      return true;
    })
    .filter((org) => {
      if (status) {
        return status === org.status;
      }
      return true;
    })
    ?.map((org, index) => {
      return (
        <Link key={`org-item-${org.id}`} to={`${org.id}`}>
          <OrgItem order={index + 1} org={org} />
        </Link>
      );
    });

  return (
    <>
      <Container>
        <PageHeaderContainer>
          <Dropdown
            sx={{
              display: 'inline-flex',
              paddingTop: 0,
              paddingBottom: 0,
            }}
            popperProps={{
              sx: {
                color: '#606060',
                zIndex: 2000,
              },
            }}
            list={CONTRY_LIST}
            onSelect={handleOnSelectCountry}
            selectedId={country}
            placeholder="Country"
          />
          <Dropdown
            sx={{
              display: 'inline-flex',
              paddingTop: 0,
              paddingBottom: 0,
            }}
            popperProps={{
              sx: {
                color: '#606060',
                zIndex: 2000,
              },
            }}
            list={STATUS_LIST}
            onSelect={handleOnSelectStatus}
            selectedId={status}
            placeholder="Status"
          />
          <Button
            variant="contained"
            disabled={!(!status || !country)}
            onClick={reset}
          >
            Reset
          </Button>
        </PageHeaderContainer>
        <Content>
          {/* <div className="add-container">
          <DialogButton
            sx={{
              fontWeight: '500',
              padding: '0 16px 0 32px',
            }}
            variant="contained"
            color="primary"
            disabled
            endIcon={<AddSvgIcon sx={{ width: '32px', height: '32px' }} />}
            onClick={() => navigate('create')}
          >
            Add New Organization
          </DialogButton>
        </div> */}
          <div className="list-container">
            <div className="head">
              <div></div>
              <div>Status</div>
              <div>Org Name</div>
              <div>Country</div>
              <div>Tax ID</div>
              <div>Owner</div>
            </div>
            <div className="item-container">
              {isLoadingOrgsDetail ? (
                <div className="loading-container">
                  <CircularProgress color="primary" />
                </div>
              ) : (
                orgItems
              )}
            </div>
          </div>
        </Content>
      </Container>
      <Outlet />
    </>
  );
};

export default OrgSettingPage;
