import { useState } from 'react';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { styled } from '@mui/material';

import AddButton from '../../../components/AddButton';
import Input from '../../../components/Input';
import AddSvgIcon from '../../../components/SvgIcon/AddSvgIcon';

import CloseDialogSvgIcon from '../../../assets/svg/dialog/CloseDialogSvgIcon';
import { inputSx } from '../../../theme';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .public-access-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 20px;
    background: ${({ theme }) => theme.color.gray.$250};
    border: 1px solid ${({ theme }) => theme.color.gray.$235};
    border-radius: 8px;

    & .title {
      font-weight: 500;
      & > span {
        color: ${({ theme }) => theme.color.red.$100};
        font-weight: 400;
      }
    }

    & .text-field {
      color: ${({ theme }) => theme.color.secondary.$60};
    }

    & .close-icon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  & button {
    & > svg {
      color: ${({ theme }) => theme.color.primary.$100};
    }
  }
`;

interface PublicAccessDialogContentProps {
  secrets: Array<{ name: string; accessToken?: string }> | undefined;
  onChange: (rtmpUrls: Array<{ name: string; accessToken?: string }>) => void;
}

const PublicAccessDialogContent: React.FC<PublicAccessDialogContentProps> = ({
  secrets: originalSecrets,
  onChange,
}) => {
  const [copyIndex, setCopyIndex] = useState(-1);
  const [secrets, setSecrets] = useState(
    originalSecrets ?? [
      {
        name: '',
      },
    ],
  );

  const rtmpUrlItems = secrets.map((secret, index) => {
    return (
      <div key={`public-access-${index}`} className="public-access-container">
        {index > 0 && (
          <CloseDialogSvgIcon
            className="close-icon"
            onClick={() => {
              if (originalSecrets) {
                const front = originalSecrets.slice(0, index);
                const rear = originalSecrets.slice(
                  index + 1,
                  originalSecrets.length,
                );
                const newSecrets = [...front, ...rear];
                setSecrets(newSecrets);
                onChange(newSecrets);
              }
            }}
          />
        )}
        <div className="title">
          Token<span>*</span>
        </div>
        <div className="text-field">
          <label>Name</label>
          <Input
            sx={{ ...inputSx, '& > input': { backgroundColor: '#fff' } }}
            value={secret.name}
            onChange={(e) => {
              const localSecrets = [...secrets];
              localSecrets[index].name = e.currentTarget.value;
              setSecrets(localSecrets);
              onChange(localSecrets);
            }}
          />
        </div>
        <div className="text-field">
          <label>Token</label>
          <Input
            sx={{ display: 'flex' }}
            value={secret?.accessToken}
            message={copyIndex === index ? 'Copied' : undefined}
            readOnly
            rightIcon={
              secret?.accessToken ? (
                <ContentCopyIcon
                  sx={{ cursor: 'pointer', mr: '8px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(secret?.accessToken ?? '');
                    setCopyIndex(index);
                  }}
                />
              ) : undefined
            }
          />
        </div>
      </div>
    );
  });

  return (
    <Container>
      {rtmpUrlItems}
      <AddButton
        onClick={() => {
          const localSecrets = [...secrets, { name: '' }];
          setSecrets(localSecrets);
          onChange(localSecrets);
        }}
      >
        <AddSvgIcon /> Add Token
      </AddButton>
    </Container>
  );
};

export default PublicAccessDialogContent;
