import { Link } from 'react-router-dom';
import { styled } from '@mui/material';

import { Project } from '../../types/Project';

const Container = styled(Link)`
  display: flex;
  align-items: center;
  min-height: 72px;
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.secondary.$100};
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 11px 24px 11px 16px;
  margin: 8px 0;
  gap: 24px;
  text-decoration: none;

  & > div {
    &:first-of-type {
      min-width: 30px;
      font-weight: 500;
      text-align: center;
    }
  }
`;

interface ProjectProps {
  order: number;
  project: Project;
}

const ProjectItem: React.FC<ProjectProps> = ({ order, project }) => {
  return (
    <Container to={`${project.id}/info`}>
      <div>{order}</div>
      <div>{project.name}</div>
    </Container>
  );
};

export default ProjectItem;
