import type { SVGProps } from 'react';

const ThreeDPlusSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 5v4h4v2h-4v4h-2v-4h-4V9h4V5h2Zm-4 12v-1.667L17 18v10l9-6v-5h-5Zm-5-.5 5-3.333V13h-4V7h2.273L16 5l-9 5.5 9 6ZM6 12v10l9 6V18l-9-6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ThreeDPlusSvgIcon;
