import React, { useState } from 'react';
import { styled } from '@mui/material';

import Input from '../../../components/Input';

interface DisplayNotificationContentProps {
  metadata: {
    [propertyName: string]: any;
  };
  onChange: (propertyName: string, value: any) => void;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  & > label {
    padding-top: 15px;
    color: var(--Secondary-60, #a1a1a1);
    font-family: 'Noto Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  & > div {
    & > input {
      color: #3e3e3e;
    }
    & > input::placeholder {
      color: #a1a1a1;
    }
  }
`;

const DisplayNotificationContent: React.FC<DisplayNotificationContentProps> = ({
  metadata,
  onChange,
}) => {
  const [notiMetaData, setnotiMetaData] = useState<{
    [propertyName: string]: any;
  }>({
    alertDuration: metadata?.alertDuration,
    alertAmount: metadata?.alertAmount,
  });

  return (
    <Container>
      <label>Number of notification</label>
      <Input
        sx={{
          width: '100%',
          display: 'block',
          placeholderShown: 'true',
        }}
        value={notiMetaData?.alertAmount || ''}
        placeholder={'Default : 10 (1-10)'}
        onChange={(e) => {
          setnotiMetaData({
            ...notiMetaData,
            alertAmount: Number(e.currentTarget.value),
          });
          onChange('alertAmount', e.currentTarget.value);
        }}
      ></Input>
      <label>Auto hide duration (ms)</label>
      <Input
        sx={{
          width: '100%',
          display: 'block',
          placeholderShown: 'true',
        }}
        value={notiMetaData?.alertDuration || ''}
        placeholder={'Default : 20000'}
        onChange={(e) => {
          setnotiMetaData({
            ...notiMetaData,
            alertDuration: Number(e.target.value),
          });
          onChange('alertDuration', e.target.value);
        }}
      ></Input>
    </Container>
  );
};

export default DisplayNotificationContent;
