import { FC, MouseEvent, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { DasEndpointType } from '../types/Device';
import { RmrSolution as RmrSolutionData } from '../types/RmrTicket';

import { deleteRmrSolution } from '../apis/rmrApi';

import { parseDasId } from '../utils/common';

import ArrowLeftSvgIcon from './SvgIcon/ArrowLeftSvgIcon';
import ArrowRightSvgIcon from './SvgIcon/ArrowRightSvgIcon';
import DeviceTag from './DeviceTag';
import EditRmrSolution from './EditRmrSolution';
import MoreOptionPopper from './MoreOptionPopper';

const Container = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    margin: '16px 0',
    '&:before': {
      display: 'block',
      content: '""',
      width: '3px',
      minHeight: '0',
      borderRadius: '1.5px',
      backgroundColor: theme.color.primary.$100,
      marginRight: '8px',
    },
    '& .container': {
      flex: 1,
      backgroundColor: theme.color.secondary.$40,
      padding: '16px 16px 16px 5px',
      '& > .header': {
        display: 'flex',
        alignItems: 'center',
        '& > div:nth-of-type(1)': {
          flex: 1,
          paddingLeft: '56px',
          '& > .cause-number': {
            ...theme.typography.caption,
            color: theme.color.secondary.$80,
          },
          '& > .title': {
            fontSize: '1.125rem',
            fontWeight: '700',
          },
        },
        '& .icon-more': {
          cursor: 'pointer',
          '&[data-disabled="true"]': {
            cursor: 'default',
            opacity: 0.3,
          },
        },
      },
      '& .content': {
        '& > .device-tag-Container': {
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          paddingLeft: '56px',
          margin: '16px 0',
        },
        '& > .picture-control-container': {
          position: 'relative',
          height: '150px',
          '& > .picture-container': {
            position: 'relative',
            width: '730px',
            height: '150px',
            overflow: 'hidden',
            margin: '0 56px',
          },
          '& .picture': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '150px',
            overflow: 'hidden',
            transition: 'left 0.3s',
            '& > .list': {
              position: 'absolute',
              display: 'flex',
              gap: '20px',
              '& > img': {
                cursor: 'pointer',
                width: '150px',
                height: '150px',
                objectFit: 'cover',
              },
            },
          },
          '& .page-button': {
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            width: '56px',
            height: '100%',
            '&.previous': {
              top: 0,
              left: 0,
              justifyContent: 'flex-start',
            },
            '&.next': {
              top: 0,
              right: 0,
              justifyContent: 'flex-end',
            },
          },
        },
        '& > .description': {
          fontSize: '0.875rem',
          paddingLeft: '56px',
          margin: '16px 0',
        },
      },
    },
  };
});

interface RmrSolutionProps {
  disabledMoreOption?: boolean;
  issueId: string;
  issueIndex: number;
  causeId: string;
  causeIndex: number;
  solutionIndex: number;
  devicePool: string[];
  data: RmrSolutionData;
  refetch?: () => void;
}

const RmrSolution: FC<RmrSolutionProps> = ({
  disabledMoreOption = false,
  issueId,
  issueIndex,
  causeId,
  causeIndex,
  solutionIndex,
  devicePool,
  data,
  refetch,
}) => {
  const { ticketId } = useParams();
  const pictureRef = useRef<HTMLDivElement | null>(null);
  const moreOptionRef = useRef<HTMLImageElement | null>(null);
  const navigate = useNavigate();
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [picturePage, setPicturePage] = useState(1);

  const deleteSolutionMutation = useMutation({
    mutationFn: deleteRmrSolution,
    onError: (error) => {
      if (error instanceof AxiosError) {
        alert(error.response?.data?.error?.message ?? error.message);
      }
    },
    onSuccess: () => {
      refetch?.();
    },
  });

  const totalPage = Math.ceil(data.photos.length / 4);

  const images = data.photos.map((photo, index) => {
    return (
      <img
        key={`solution-${data.id}-photo-${index}`}
        src={photo.url}
        onClick={() =>
          navigate(
            `issue/${issueIndex}/cause/${causeIndex}/solution/${solutionIndex}/photo/${index}`,
          )
        }
      />
    );
  });

  const deviceTags = data.issueDevices.map((dasId) => {
    return (
      <DeviceTag
        key={`solution-${data.id}-dasId-${dasId}`}
        mode="view"
        type={parseDasId(dasId) as DasEndpointType}
        dasId={dasId}
      />
    );
  });

  const handleOnClickPrevious = () => {
    if (picturePage - 1 >= 1) {
      setPicturePage(picturePage - 1);
    }
  };

  const handleOnClickNext = () => {
    if (picturePage + 1 <= totalPage) {
      setPicturePage(picturePage + 1);
    }
  };

  const handleOnEdit = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setShowMoreOption(false);
    setMode('edit');
  };

  const handleOnDelete = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    if (
      confirm(
        `Do you really delete the solution ${solutionIndex + 1}. "${
          data.title
        }"?`,
      )
    ) {
      if (ticketId) {
        deleteSolutionMutation.mutate({
          ticketId,
          issueId,
          causeId,
          solutionId: data.id,
        });
      }
    }
  };

  const handleShowMoreOption = (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    if (!disabledMoreOption) {
      setShowMoreOption(!showMoreOption);
    }
  };

  if (mode === 'view')
    return (
      <Container>
        <div className="container">
          <div className="header">
            <div>
              <div className="cause-number">SOLUTION {solutionIndex + 1}.</div>
              <div className="title">{data.title}</div>
            </div>
            <img
              ref={moreOptionRef}
              className="icon-more"
              src="/assets/image/svg/more.svg"
              data-disabled={disabledMoreOption}
              onClick={handleShowMoreOption}
            />
            <MoreOptionPopper
              anchorEl={moreOptionRef.current}
              placement="bottom-end"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              onEdit={handleOnEdit}
              onDelete={handleOnDelete}
            />
          </div>
          <div className="content">
            <div className="device-tag-Container">{deviceTags}</div>
            {images.length > 0 && (
              <div className="picture-control-container">
                {picturePage > 1 && (
                  <div
                    className="page-button previous"
                    onClick={handleOnClickPrevious}
                  >
                    <ArrowLeftSvgIcon sx={{ width: '40px', height: '40px' }} />
                  </div>
                )}
                <div className="picture-container">
                  <div
                    ref={pictureRef}
                    className="picture"
                    style={{ left: `${(picturePage - 1) * -680}px` }}
                  >
                    <div className="list">{images}</div>
                  </div>
                </div>
                {picturePage < totalPage && (
                  <div className="page-button next" onClick={handleOnClickNext}>
                    <ArrowRightSvgIcon sx={{ width: '40px', height: '40px' }} />
                  </div>
                )}
              </div>
            )}
            <div className="description">{data.description}</div>
          </div>
        </div>
      </Container>
    );
  if (mode === 'edit')
    return (
      <EditRmrSolution
        issueId={issueId}
        issueIndex={issueIndex}
        causeId={causeId}
        causeIndex={causeIndex}
        devicePool={devicePool}
        number={solutionIndex + 1}
        data={data}
        onClose={() => {
          setMode('view');
        }}
        onSuccess={refetch}
      />
    );

  return null;
};

export default RmrSolution;
