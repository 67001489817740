import type { SVGProps } from 'react';

const TunnelSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_3642_2267)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4455 6.06548C23.6014 6.61803 26.9761 10.5051 26.9846 14.7473L27.0006 22.4295L21.9939 25.0058L21.9803 18.2819C21.9703 13.3692 18.0544 8.76557 12.916 8.01083L15.4266 6.71879C16.5882 6.12145 17.967 5.86876 19.4455 6.06548Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00002 16.3163L5.01517 23.9993L20 25.9992L19.9848 18.317C19.9764 14.0749 16.616 10.1878 12.4777 9.63441C8.33943 9.08266 4.9916 12.0741 5.00002 16.3163ZM11.9231 13.4071C13.898 13.6722 15.9798 15.7898 15.9849 18.3241V23.1429L9.00214 22.2109L9.00001 16.3091C8.99561 14.0949 10.4856 13.2157 11.9231 13.4071Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3642_2267">
        <rect width="22" height="20" fill="white" transform="translate(5 6)" />
      </clipPath>
    </defs>
  </svg>
);
export default TunnelSvgIcon;
