import { useEffect, useState } from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Cartographic, createWorldTerrain, Ion, sampleTerrain } from 'cesium';

import { setProjectElevation } from '../slices/systemSlice';

import type { AppDispatch, RootState } from '../store';

Ion.defaultAccessToken = import.meta.env.VITE_CESIUM_ACCESS_TOKEN ?? '';

const terrainProvider = createWorldTerrain();

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useCesiumElevation = (lon?: number, lat?: number) => {
  const dispatch = useDispatch();
  const [elevation, setElevation] = useState<number>();
  useEffect(() => {
    const run = async () => {
      if (typeof lon !== 'undefined' && typeof lat !== 'undefined') {
        const terrains = await sampleTerrain(terrainProvider, 13, [
          Cartographic.fromDegrees(lon, lat),
        ]);
        dispatch(setProjectElevation(terrains[0].height));
        setElevation(terrains[0].height);
      } else {
        dispatch(setProjectElevation(undefined));
        setElevation(undefined);
      }
    };
    run();
  }, [lon, lat]);
  return elevation;
};
