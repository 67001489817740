import { FC } from 'react';
import {
  ClickAwayListener,
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
  styled,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { logout } from '../../apis/authApi';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { logout as logoutAction } from '../../slices/authSlice';

const Popper = styled(MuiPopper)(() => {
  return {};
});

const Container = styled('div')(({ theme }) => {
  return {
    backgroundColor: 'white',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    '& > .info': {
      display: 'flex',
      color: '#606060',
      backgroundColor: '#F7F7F7',
      marginRight: '5px',
      padding: '20px',

      '& > div': {
        marginRight: '24px',

        '& img': {
          objectFit: 'cover',
          display: 'block',
          width: '80px',
          height: '80px',
          margin: 0,
          borderRadius: '50%',
        },

        '& > .email': {
          ...theme.typography.body1,
          color: theme.color.secondary.$80,
        },
        '& > .name': {
          ...theme.typography.body1,
          color: theme.color.secondary.$100,
        },
        '& > .org': {
          ...theme.typography.body1,
          color: theme.color.secondary.$100,
        },
      },
    },
    '& > .logout': {
      ...theme.typography.body1,
      cursor: 'pointer',
      color: '#606060',
      padding: '20px',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
  };
});

interface PopperProps extends MuiPopperProps {
  onClickAway: (event: MouseEvent | TouchEvent) => void;
}

const UserPopper: FC<PopperProps> = ({ onClickAway, ...props }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user);
  const logoutMutation = useMutation(
    async () => {
      return logout();
    },
    {
      onSuccess: () => {
        dispatch(logoutAction());
      },
    },
  );

  const handleLogout = () => {
    logoutMutation.mutate();
  };

  return (
    <Popper {...props}>
      <ClickAwayListener onClickAway={onClickAway}>
        <Container>
          <div className="info">
            <div>
              <img
                src={
                  user.imageURL
                    ? user.imageURL
                    : '/assets/image/svg/user_default_avatar.svg'
                }
              />
            </div>
            <div>
              <div className="email">{user.email}</div>
              <div className="name">{`${user.lastName} ${user.firstName}`}</div>
              <div className="org">{user.org.name}</div>
            </div>
          </div>
          <div className="logout" onClick={handleLogout}>
            Logout
          </div>
        </Container>
      </ClickAwayListener>
    </Popper>
  );
};

export default UserPopper;
