import { capitalize } from '@mui/material';

import { DasEndpointType } from '../../types/Device';
import { SystemState } from '../../types/Store';

import { mapToDeviceUrl, parseDasId } from '../../utils/common';

interface StatisticsDevicesBlockProps {
  deviceMap: SystemState['transferDeviceMap'];
}

const StatisticsDevicesBlock: React.FC<StatisticsDevicesBlockProps> = ({
  deviceMap,
}) => {
  const deviceCountMap: { [dasId: string]: number | undefined } = {};

  Object.entries(deviceMap).forEach(([key, obj]) => {
    if (obj?.isSelected) {
      const deviceType = parseDasId(key) ?? 'other';

      if (!deviceCountMap[deviceType]) {
        deviceCountMap[deviceType] = 1;
      } else {
        (deviceCountMap[deviceType] as number) += 1;
      }
    }
  });

  const deviceItems = Object.entries(deviceCountMap).map(([type, count]) => {
    let name;
    switch (type as DasEndpointType | 'other') {
      case 'dasloop_video':
        name = 'Dasloop Video';
        break;
      case 'dastrack_v':
        name = 'Dastrack-V';
        break;
      case 'das_aoa_tag':
        name = 'DasAoATag';
        break;
      case 'daswater':
        name = 'Dawater-L';
        break;
      default:
        name = capitalize(type);
    }

    return (
      <div className="device-item" key={`device-item-${name}`}>
        <span className="number">{count}</span>
        <img
          width={40}
          height={40}
          className="das-type"
          src={mapToDeviceUrl(type as DasEndpointType)}
        />
        <span className="device-type">{name}</span>
      </div>
    );
  });
  return <div className="device-list">{deviceItems}</div>;
};

export default StatisticsDevicesBlock;
