import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  OrgText,
  StatusDropdown,
} from '@beeinventor/dasiot-react-component-lib';
import { Avatar, styled } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Organization } from '../../../types/Organization';

import { getOrgDetail, updateOrg } from '../../../apis/orgApi';
import { useAppDispatch } from '../../../hooks';
import { setNeedRefresh } from '../../../slices/systemSlice';

import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import OpsDialog from '../../../components/OpsDialog';
import CrossSvgIcon from '../../../components/SvgIcon/CrossSvgIcon';
import EditSvgIcon from '../../../components/SvgIcon/EditSvgIcon';

import accessDeniedPng from '../../../assets/png/img_access_denied.png';
import theme from '../../../theme';

import OrgMemberItem from './OrgMemberItem';

const statusMap = {
  ACTIVE: {
    bgColor: theme.color.green.$100,
    displayName: 'ACTIVE',
  },
  SUSPENDED: {
    bgColor: theme.color.secondary.$60,
    displayName: 'SUSPENDED',
  },
};

const Header = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;

  & > .icon-button-container {
    display: flex;
    align-items: center;

    &::before {
      display: block;
      content: '';
      width: 2px;
      height: 1.875rem;
      background-color: ${({ theme }) => theme.color.secondary.$40};
      margin-right: 20px;
      border-radius: 1px;
    }
  }

  & div[role='tooltip'] {
    z-index: 1;
  }
`;

const Content = styled('div')`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;

  & > .owner-info {
    padding: 0 36px;
    & > div {
      &:nth-of-type(1) {
        font-size: 0.875rem;
        line-height: 1.1875rem;
        color: ${({ theme }) => theme.color.secondary.$60};
        margin-bottom: 6px;
      }
      &:nth-of-type(2) {
        display: flex;
        align-items: center;
        gap: 10px;
        & > .name {
          color: ${({ theme }) => theme.color.secondary.$100};
        }
        & > .email {
          color: ${({ theme }) => theme.color.secondary.$80};
        }
      }
    }
  }

  & > .org-info {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: ${({ theme }) => theme.color.gray.$250};
    border: 1px solid ${({ theme }) => theme.color.gray.$235};
    border-radius: 8px;
    padding: 10px;
    margin: 20px 0;

    & > div {
      flex: 1;
      & > div {
        text-align: center;
        &:nth-of-type(1) {
          color: ${({ theme }) => theme.color.secondary.$60};
          margin-bottom: 4px;
        }
        &:nth-of-type(2) {
          font-size: 2.5rem;
          line-height: 1.3;
          font-weight: 700;
        }
      }
    }
  }

  & > .member-list {
    max-height: 432px;
    background-color: ${({ theme }) => theme.color.gray.$250};
    border: 1px solid ${({ theme }) => theme.color.gray.$235};
    border-radius: 8px;
    overflow: auto;
    padding: 10px 0;
  }
`;

const Root = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [suspendDialogOpen, setSuspendDialogOpen] = useState(false);
  const { orgId } = useParams();
  const orgStatusMutation = useMutation({
    mutationFn: updateOrg,
    onMutate: () => {
      setErrorMessage(undefined);
    },
    onSuccess: () => {
      refetch();
      dispatch(setNeedRefresh(true));
      setSuspendDialogOpen(false);
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        setErrorMessage(err.response?.data.error.message);
      } else {
        setErrorMessage((err as Error).message);
      }
    },
  });

  const { data: orgDetail, refetch } = useQuery(
    ['get-org-detail', orgId],
    async () => {
      const res = await getOrgDetail({ orgId: orgId as string });
      return res.data.data;
    },
    {
      enabled: !!orgId,
      onSuccess: (data) => {
        setStatus(data.status);
      },
    },
  );

  const owner = useMemo(() => {
    if (orgDetail) {
      for (let i = 0; i < orgDetail.members.length; i++) {
        if (orgDetail.ownerId === orgDetail.members[i].id) {
          return orgDetail.members[i];
        }
      }
    }
  }, [orgDetail?.members]);

  const handleChangeOrgStatus = () => {
    if (orgDetail) {
      orgStatusMutation.mutate({
        orgId: orgDetail.id,
        payload: { status: status as Organization['status'] },
      });
    }
  };

  return (
    <>
      <OpsDialog
        open
        headerNode={
          <Header>
            <StatusDropdown
              statusMap={statusMap}
              defaultStatus={status}
              onSelect={(st) => {
                const newStatus = st as Organization['status'];
                if (status === 'ACTIVE' && newStatus === 'SUSPENDED') {
                  setSuspendDialogOpen(true);
                } else if (
                  orgDetail &&
                  orgDetail.status === 'SUSPENDED' &&
                  newStatus === 'ACTIVE'
                ) {
                  orgStatusMutation.mutate({
                    orgId: orgDetail.id,
                    payload: { status: newStatus },
                  });
                }
                setStatus(st);
              }}
            />
            <OrgText
              sx={{
                flex: 1,
                '& .org-name': {
                  maxWidth: '260px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                },
              }}
              title={orgDetail?.name}
              orgColor={orgDetail?.color}
              orgName={orgDetail?.name ?? ''}
            />
            <div className="icon-button-container">
              <EditSvgIcon
                sx={{
                  cursor: 'pointer',
                  display: 'block',
                  width: '40px',
                  height: '40px',
                  mr: '4px',
                  '&[data-diabled="true"]': {
                    cursor: 'default',
                    opacity: 0.3,
                  },
                }}
                onClick={() => {
                  // if (!isViewRevision) {
                  //   navigate('edit');
                  // }
                }}
              />
              <CrossSvgIcon
                sx={{
                  cursor: 'pointer',
                  display: 'block',
                  width: '40px',
                  height: '40px',
                }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </Header>
        }
      >
        <Content>
          <div className="owner-info">
            <div>Owner</div>
            <div>
              <Avatar src={owner?.imageURL ?? undefined} />
              <span className="name">{`${owner?.firstName ?? ''} ${
                owner?.lastName ?? ''
              }`}</span>
              <span className="email">{`( ${owner?.email ?? 'N/A'} )`}</span>
            </div>
          </div>
          <div className="org-info">
            <div>
              <div>Org Projects</div>
              <div>{orgDetail?.projectCounts ?? 0}</div>
            </div>
            <div>
              <div>Org Members</div>
              <div>{orgDetail?.members.length ?? 0}</div>
            </div>
          </div>

          <div className="member-list">
            {orgDetail?.members.map((member) => {
              return (
                <OrgMemberItem
                  key={`member-item-${member.id}`}
                  name={`${member?.firstName ?? ''} ${member?.lastName ?? ''}`}
                  email={member.email}
                  imageUrl={member?.imageURL ?? undefined}
                />
              );
            })}
          </div>
        </Content>
      </OpsDialog>
      <ConfirmDialog
        open={suspendDialogOpen}
        title="You really want to suspend organization?"
        description="This action will suspend all projects of organization."
        imageUrl={accessDeniedPng}
        isLoading={orgStatusMutation.isLoading}
        errorMessage={errorMessage}
        onClose={() => {
          setStatus(orgDetail?.status);
          setSuspendDialogOpen(false);
          setErrorMessage(undefined);
        }}
        onSubmit={handleChangeOrgStatus}
      />
    </>
  );
};

export default Root;
