import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import {
  ConnectionLostMetadata,
  DasEndpointType,
  FeatureListType,
  OtherFeatureType,
  SSSSFeatureType,
} from '../../../types/Device';
import { ProjectFeature } from '../../../types/Project';

import {
  createProjectFeature,
  deleteProjectFeature,
  getProjectFeature,
  getProjects,
  upsertProjectFeature,
} from '../../../apis/projectApi';

import InformationDialog from '../../../components/Dialog/InformationDialog';
import SettingDialog from '../../../components/Dialog/SettingDialog';
import NormalDropdown from '../../../components/NormalDropdown';

import AttendanceDialogContent from './AttendanceDialogContent';
import BasicContent from './BasicContent';
import CapOffDialogContent from './CapOffDialogContent';
import DascartDialogContent from './DascartDialogContent';
import DasLoopDialogContent from './DasLoopDialogContent';
import DataDownloadDialogContent from './DataDownloadDialogContent';
import DisplayNotificationContent from './DisplayNotificationContent';
import PublicAccessDialogContent from './PublicAccessDialogContent';
import SSSSContent from './SSSSContent';
import WebhookDialogContent from './WebhookDialogContent';
import LostConnectDialogContent from './LostConnectDialogContent';

const list: Array<{ id: string; name: string; value: string }> = [
  {
    id: 'basic',
    name: 'BASIC',
    value: 'basic',
  },
  {
    id: 'four_s',
    name: 'SSSS',
    value: 'four_s',
  },
];

const Title = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled('div')`
  padding: 0 36px;

  & svg {
    display: block;
  }

  & .title {
    color: ${({ theme }) => theme.color.secondary.$60};
  }

  & ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;

    & .switch-container {
      display: flex;
      align-items: center;
      gap: 10px;
      & > div:nth-of-type(1) {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      & > div:nth-of-type(2) {
        cursor: pointer;
        &.disabled {
          pointer-events: none;
          color: ${({ theme }) => theme.color.secondary.$40};
        }
      }
    }

    & li {
      margin: 10px 0;
      & > .metadata-setting {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 50px;
      }
    }
  }

  & hr {
    border-top: 1px solid ${({ theme }) => theme.color.secondary.$40};
    margin: 10px 0;
  }
`;

const FeatureListInfoPage = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false);
  const [selectedType, setSelectedType] = useState<
    DasEndpointType | FeatureListType | OtherFeatureType | SSSSFeatureType
  >('dasloop');
  const [disablePa, setDisablePa] = useState(false);
  const [displayBy, setDisplayBy] = useState('area');
  const [rtmpUrls, setRtmpUrls] = useState<string[]>(['']);
  const [secrets, setSecrets] = useState<
    Array<{ name: string; accessToken?: string }>
  >([
    {
      name: '',
    },
  ]);
  const [capOffMetadata, setCapOffMetadata] = useState<{
    [propertyName: string]: any;
  }>({});
  const [notiMetadata, setNotiMetadata] = useState<{
    [propertyName: string]: any;
  }>({});
  const [dataDownloadMetadata, setDataDownloadMetadata] = useState<{
    [propertyName: string]: any;
  }>({});
  const [webhookMetadata, setWebhookMetadata] = useState<{
    [propertyName: string]: any;
  }>({});

  const [mainFeatureType, setMainFeatureType] = useState<'basic' | 'four_s'>(
    'basic',
  );
  const [connectionLostMetadata, setConnectionLostMetadata] = useState<
    ConnectionLostMetadata | undefined
  >();

  const { data: project } = useQuery(
    ['get-projects', projectId],
    async () => {
      const res = await getProjects({ id: projectId });
      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].id === projectId) {
          return res.data.data[i];
        }
      }
      return null;
    },
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: projectFeature,
    refetch: refetchProjectFeature,
    isLoading,
  } = useQuery(
    ['get-feature-list', projectId],
    async () => {
      const res = await getProjectFeature({
        projectId: projectId as string,
      });
      if (res.data.data.length > 0) {
        const featureListMap = res.data.data.reduce<{
          [type: string]: ProjectFeature;
        }>((prev, curr) => {
          prev[curr.type] = curr;
          return prev;
        }, {});
        if (featureListMap['tunnel']?.metadata?.displayBy) {
          setDisplayBy(featureListMap['tunnel'].metadata.displayBy);
        }
        if (featureListMap['dascart']?.metadata?.rtmpUrls) {
          setRtmpUrls(featureListMap['dascart'].metadata.rtmpUrls);
        }
        if (featureListMap['public_access']?.metadata?.secrets) {
          setSecrets(featureListMap['public_access'].metadata.secrets);
        }
        if (featureListMap['dasloop']?.metadata.disablePa) {
          setDisablePa(featureListMap['dasloop']?.metadata.disablePa);
        }
        if (featureListMap['four_s']?.metadata.disablePa) {
          setDisablePa(featureListMap['four_s']?.metadata.disablePa);
        }
        const { metadata } = featureListMap['four_s'] || {};

        if (metadata) {
          const dataDownloadMetadata: { [propertyName: string]: any } = {};

          if ('generalReport' in metadata) {
            dataDownloadMetadata.generalReport = metadata.generalReport;
          }

          if ('attendanceReport' in metadata) {
            dataDownloadMetadata.attendanceReport = metadata.attendanceReport;
          }
          if ('vod' in metadata) {
            dataDownloadMetadata.vod = metadata.vod;
          }
          if ('workTimeReport' in metadata) {
            dataDownloadMetadata.workTimeReport = metadata.workTimeReport;
          }
        }
        if (featureListMap['cap_off']?.metadata) {
          setCapOffMetadata(featureListMap['cap_off']?.metadata);
        }
        if (featureListMap['report']?.metadata && mainFeatureType === 'basic') {
          setDataDownloadMetadata(featureListMap['report']?.metadata);
        }
        if (featureListMap['notification']?.metadata) {
          setNotiMetadata(featureListMap['notification']?.metadata);
        }
        if (featureListMap['connection_lost']?.metadata) {
          setConnectionLostMetadata(
            featureListMap['connection_lost']?.metadata,
          );
        }
        if (featureListMap['webhook']?.metadata) {
          setNotiMetadata(featureListMap['webhook']?.metadata);
        }
        return featureListMap;
      }
      return {};
    },
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    return () => {
      setMainFeatureType('basic');
    };
  }, []);

  useEffect(() => {
    if (projectFeature?.['four_s']) {
      setMainFeatureType('four_s');
    }
  }, [projectFeature]);

  let dialogTitle = '';
  switch (selectedType) {
    case 'tunnel':
      dialogTitle = 'Tunnel Settings';
      break;
    case 'dascart':
      dialogTitle = 'Dascart Settings';
      break;
    case 'public_access':
      dialogTitle = 'Access Token Settings';
      break;
    case 'dasloop':
    case 'SmartMonitoringDevices':
      dialogTitle = 'DasLoop Settings';
      break;
    case 'cap_off':
      dialogTitle = 'Cap Off Settings';
      break;
    case 'report':
      dialogTitle = 'Data Download Settings';
      break;
    case 'notification':
      dialogTitle = 'Notification Settings';
      break;
    case 'connection_lost':
      dialogTitle = 'Lost Connection Settings';
      break;
    case 'webhook':
      dialogTitle = 'WebHook Settings';
      break;
  }

  let metadataSaveable = true;
  if (selectedType === 'dascart') {
    metadataSaveable = rtmpUrls.reduce(
      (prev, url) => (prev = prev && !!url),
      true,
    );
  } else if (selectedType === 'public_access') {
    metadataSaveable = secrets.reduce(
      (prev, secret) => (prev = prev && !!secret.name),
      true,
    );
  }

  const handleOnChange = async (
    type:
      | DasEndpointType
      | FeatureListType
      | OtherFeatureType
      | SSSSFeatureType,
    checked: boolean,
  ) => {
    setSelectedType(type);

    if (projectId && projectFeature) {
      if (checked === true) {
        const metadata: { [key: string]: any } = {};
        if (type === 'tunnel') {
          metadata.displayBy = 'area';
          // setIsOpenSettingDialog(true);
        } else if (type === 'dascart') {
          setIsOpenSettingDialog(true);
        } else if (type === 'public_access') {
          setIsOpenSettingDialog(true);
        }
        if (type === 'connection_lost') {
          const metadata = { interval: 5, max: 5 };
          await createProjectFeature({
            projectId,
            data: {
              type,
              metadata,
            },
          });
        }
        if (type !== 'public_access') {
          await createProjectFeature({
            projectId,
            data: {
              type,
              metadata,
            },
          });
        }
      } else {
        await deleteProjectFeature({
          projectId,
          data: {
            featureId: projectFeature[type].id,
          },
        });
      }
      refetchProjectFeature();
    }
  };

  const handleOnChangeMetadata = async (
    type:
      | DasEndpointType
      | FeatureListType
      | OtherFeatureType
      | SSSSFeatureType,
    key: string,
    value: any,
  ) => {
    if (projectId && projectFeature?.[type]) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(async () => {
        await upsertProjectFeature({
          projectId,
          data: {
            featureId: projectFeature[type].id,
            metadata: {
              [key]: value,
            },
          },
        });
        refetchProjectFeature();
      }, 1000);
    }
  };

  const handleOnChangeMetadataObject = async (
    type:
      | DasEndpointType
      | FeatureListType
      | OtherFeatureType
      | SSSSFeatureType,
    metadata: any,
  ) => {
    if (projectId && projectFeature?.[type]) {
      await upsertProjectFeature({
        projectId,
        data: {
          featureId: projectFeature[type].id,
          metadata,
        },
      });
      refetchProjectFeature();
    }
  };

  const handleOnSave = async () => {
    if (selectedType === 'tunnel') {
      handleOnChangeMetadata('tunnel', 'displayBy', displayBy);
    } else if (selectedType === 'dascart') {
      handleOnChangeMetadata('dascart', 'rtmpUrls', rtmpUrls);
    } else if (selectedType === 'public_access') {
      if (projectFeature?.['public_access']?.metadata?.secrets) {
        handleOnChangeMetadata('public_access', 'secrets', secrets);
      } else {
        await createProjectFeature({
          projectId: projectId as string,
          data: {
            type: 'public_access',
            metadata: {
              secrets,
            },
          },
        });
      }
    } else if (
      selectedType === 'dasloop' ||
      selectedType === 'SmartMonitoringDevices'
    ) {
      if (selectedType === 'dasloop') {
        handleOnChangeMetadata(selectedType, 'disablePa', disablePa);
      } else {
        handleOnChangeMetadata('four_s', 'disablePa', disablePa);
      }
    } else if (selectedType === 'cap_off') {
      if (projectFeature?.['cap_off']?.metadata) {
        handleOnChangeMetadataObject('cap_off', capOffMetadata);
      } else {
        await createProjectFeature({
          projectId: projectId as string,
          data: {
            type: 'cap_off',
            metadata: capOffMetadata,
          },
        });
      }
    } else if (selectedType === 'report' || selectedType === 'DataDownload') {
      if (selectedType === 'report') {
        handleOnChangeMetadataObject('report', dataDownloadMetadata);
      } else {
        handleOnChangeMetadataObject('four_s', dataDownloadMetadata);
      }
    } else if (selectedType === 'notification') {
      handleOnChangeMetadataObject('notification', notiMetadata);
    } else if (selectedType === 'connection_lost') {
      handleOnChangeMetadataObject('connection_lost', connectionLostMetadata);
    } else if (selectedType === 'webhook') {
      handleOnChangeMetadataObject('webhook', webhookMetadata);
    }

    setIsOpenSettingDialog(false);
    refetchProjectFeature();
  };

  const handleOnSelectMainFeature = async (
    mainFeatureType: 'basic' | 'four_s',
  ) => {
    setMainFeatureType(mainFeatureType);
    if (mainFeatureType === 'four_s') {
      await createProjectFeature({
        projectId: projectId as string,
        data: {
          type: 'four_s',
          metadata: {
            DigitisedTrackingSystem: true,
            DigitalisedPermitToWorkSystem: true,
            HazardousAreasAccessControl: true,
            'UnsafeActs/DangerousSituationAlertForMobilePlantOperationDangerZone':
              true,
            'UnsafeActs/DangerousSituationAlertForTowerCraneLiftingZone': true,
            SmartMonitoringDevices: true,
            SafetyMonitoringSystem: true,
            ConfinedSpaceMonitoringSystem: true,
            SafetyTrainingWithVirtualRealityTechnology: true,
            DataDownload: true,
          },
        },
      });
    } else if (mainFeatureType === 'basic') {
      if (projectFeature?.['four_s']) {
        await deleteProjectFeature({
          projectId: projectId as string,
          data: {
            featureId: projectFeature['four_s'].id,
          },
        });
      }
    }
    refetchProjectFeature();
  };

  useEffect(() => {
    console.log(webhookMetadata);
  }, [webhookMetadata]);

  return (
    <>
      <InformationDialog
        open
        titleNode={{
          start: (
            <NormalDropdown
              list={list}
              value={mainFeatureType}
              onSelect={(v) =>
                handleOnSelectMainFeature(v as 'basic' | 'four_s')
              }
            />
          ),
          center: (
            <Title>
              <span>{project?.name}</span>
              <span>
                {project &&
                  format(new Date(project.createdAt), 'yyyy - MM - dd')}
              </span>
            </Title>
          ),
        }}
        closeable
        onClose={() => navigate(-1)}
      >
        {isLoading ? (
          <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
        ) : (
          <Container>
            <div className="title">Main Feature</div>
            {mainFeatureType === 'basic' && (
              <BasicContent
                projectFeature={projectFeature}
                handleOnChange={handleOnChange}
                setSelectedType={setSelectedType}
                setIsOpenSettingDialog={setIsOpenSettingDialog}
              />
            )}
            {mainFeatureType === 'four_s' && (
              <SSSSContent
                projectFeature={projectFeature}
                handleOnChange={handleOnChange}
                handleOnChangeMetadata={handleOnChangeMetadata}
                setSelectedType={setSelectedType}
                setIsOpenSettingDialog={setIsOpenSettingDialog}
              />
            )}
          </Container>
        )}
      </InformationDialog>
      <SettingDialog
        open={isOpenSettingDialog}
        dialogTitle={dialogTitle}
        saveable={metadataSaveable}
        onCancel={() => setIsOpenSettingDialog(false)}
        onSave={handleOnSave}
      >
        {selectedType === 'tunnel' && (
          <AttendanceDialogContent
            defaultValue={displayBy}
            onChange={(e, v) => setDisplayBy(v)}
          />
        )}
        {selectedType === 'dascart' && (
          <DascartDialogContent
            rtmpUrls={rtmpUrls}
            onChange={(newRtmpUrls) => {
              setRtmpUrls(newRtmpUrls);
            }}
          />
        )}
        {selectedType === 'public_access' && (
          <PublicAccessDialogContent
            secrets={secrets}
            onChange={(newSecrets) => {
              setSecrets(newSecrets);
            }}
          />
        )}
        {selectedType === 'notification' && (
          <DisplayNotificationContent
            metadata={{ ...projectFeature?.['notification']?.metadata }}
            onChange={(propertyName: string, value: any) => {
              if (
                propertyName === 'alertDuration' ||
                propertyName === 'alertAmount'
              ) {
                const localNotiMetadata = { ...notiMetadata };
                if (value === '' || value === undefined) {
                  localNotiMetadata[propertyName] = null;
                } else if (propertyName === 'alertDuration') {
                  localNotiMetadata[propertyName] = Number(value);
                } else if (propertyName === 'alertAmount') {
                  if (value >= 0 && value <= 10) {
                    localNotiMetadata[propertyName] = Number(value);
                  } else {
                    localNotiMetadata[propertyName] = 5;
                  }
                }
                setNotiMetadata(localNotiMetadata);
              }
            }}
          />
        )}
        {selectedType === 'cap_off' &&
          projectFeature?.['cap_off']?.metadata && (
            <CapOffDialogContent
              metadata={{
                ...projectFeature?.['cap_off']?.metadata,
              }}
              onChange={(propertyName: string, value: any) => {
                if (
                  propertyName === 'maxAttempts' ||
                  propertyName === 'maxPeriod'
                ) {
                  const localCapOffMetadata = { ...capOffMetadata };

                  if (value === '' || value === undefined) {
                    localCapOffMetadata[propertyName] = null;
                  } else {
                    localCapOffMetadata[propertyName] = Number(value);
                  }
                  setCapOffMetadata(localCapOffMetadata);
                }
              }}
            />
          )}
        {selectedType === 'connection_lost' &&
          projectFeature?.['connection_lost']?.metadata && (
            <LostConnectDialogContent
              metadata={{
                ...projectFeature?.['connection_lost']?.metadata,
              }}
              onChange={(propertyName: string, value: any) => {
                if (propertyName === 'max' || propertyName === 'interval') {
                  const localMetadata = { ...connectionLostMetadata };
                  if (propertyName === 'interval') {
                    if (value > 1 || value <= 99) {
                      localMetadata.interval = value;
                    }
                  } else if (propertyName === 'max') {
                    if (value > 0) {
                      localMetadata.max = value;
                    }
                  }
                  setConnectionLostMetadata(
                    localMetadata as ConnectionLostMetadata,
                  );
                }
              }}
            />
          )}
        {(selectedType === 'report' || selectedType === 'DataDownload') &&
          projectFeature?.['report']?.metadata && (
            <DataDownloadDialogContent
              metadata={{
                ...projectFeature['report']?.metadata,
                ...dataDownloadMetadata,
              }}
              onChange={(propertyName: string, value: any) => {
                if (propertyName === 'allAttendance') {
                  setDataDownloadMetadata({
                    ...dataDownloadMetadata,
                    attendanceReport: value,
                    workTimeReport: value,
                  });
                } else {
                  setDataDownloadMetadata((prevData) => ({
                    ...prevData,
                    [propertyName]: value,
                  }));
                }
              }}
            />
          )}
        {selectedType === 'webhook' && (
          <WebhookDialogContent
            metadata={{ ...projectFeature?.['webhook']?.metadata }}
            onChange={(propertyName: string, value: string) => {
              const localWebhookMetadata = { ...webhookMetadata };
              if (propertyName === 'limit') {
                if (value === null || value === undefined) {
                  localWebhookMetadata[propertyName] = '';
                } else {
                  localWebhookMetadata[propertyName] = Number(value);
                }
              }
              if (!webhookMetadata?.event) {
                const addEvent = {
                  events: [],
                };
                setWebhookMetadata({ ...localWebhookMetadata, ...addEvent });
              } else {
                setWebhookMetadata(localWebhookMetadata);
              }
            }}
          />
        )}
        {(selectedType === 'dasloop' ||
          selectedType === 'SmartMonitoringDevices') &&
          (projectFeature?.['dasloop']?.metadata ||
            projectFeature?.['four_s']?.metadata) && (
            <DasLoopDialogContent
              metadata={{
                ...projectFeature['dasloop']?.metadata,
                ...projectFeature['four_s']?.metadata,
                disablePa,
              }}
              onChange={(propertyName: string, value: any) => {
                if (propertyName === 'disablePa') {
                  setDisablePa(value);
                }
              }}
            />
          )}
      </SettingDialog>
    </>
  );
};

export default FeatureListInfoPage;
