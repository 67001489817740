import { SvgIcon, SvgIconProps } from '@mui/material';

const AddPhotoSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="180"
      height="180"
      viewBox="0 0 180 180"
      fill="none"
      {...props}
    >
      <rect x="66" y="88" width="48" height="4" rx="2" fill="#A1A1A1" />
      <rect
        x="92"
        y="66"
        width="48"
        height="4"
        rx="2"
        transform="rotate(90 92 66)"
        fill="#A1A1A1"
      />
      <rect
        x="1"
        y="1"
        width="178"
        height="178"
        stroke="#A1A1A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6 10"
        fill="none"
      />
    </SvgIcon>
  );
};

export default AddPhotoSvgIcon;
