import { useMemo } from 'react';
import { styled } from '@mui/material';

import { Organization, OrganizationDetail } from '../../types/Organization';

import UserText from '../../components/UserText';

const Container = styled('div')`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 11px 24px 13px 16px;

  .icon-container {
    padding: 13px;
    margin: 0 8px;
  }

  & > div {
    &:first-of-type {
      min-width: 30px;
      font-weight: 500;
      text-align: center;
    }
    &:nth-of-type(2) {
      padding: 13px;
      margin: 0 8px;
    }
    &:nth-of-type(3) {
      flex: 2;
    }
    &:nth-of-type(4) {
      flex: 1;
    }
    &:nth-of-type(5) {
      flex: 1;
    }
    &:nth-of-type(6) {
      flex: 1;
    }
  }
`;

interface StatusIconProps {
  status: Organization['status'];
}

const StatusIcon = styled('div')<StatusIconProps>`
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: ${({ status }) =>
    status === 'ACTIVE' ? '#78DC00' : '#E5E5E5'};
  border-radius: 4px;
`;

interface OrgTextProps {
  color: string;
}

const OrgText = styled('span')<OrgTextProps>`
  flex: 1;
  display: inline-block;
  font-weight: 500;

  &::before {
    display: inline-block;
    width: 6px;
    height: 14px;
    content: '';
    background-color: ${({ color }) => color};
    margin-right: 8px;
    border-radius: 3px;
  }
`;

interface OrgItemProps {
  order: number;
  org: OrganizationDetail;
}

const OrgItem: React.FC<OrgItemProps> = ({ order, org }) => {
  const owner = useMemo(() => {
    for (let i = 0; i < org.members.length; i++) {
      if (org.ownerId === org.members[i].id) {
        return org.members[i];
      }
    }
  }, [org.members]);

  return (
    <Container>
      <div>{order}</div>
      <div>
        <StatusIcon sx={{ display: 'block' }} status={org.status} />
      </div>
      <div>
        <OrgText color={org.color}>{org.name}</OrgText>
      </div>
      <div>{org.country}</div>
      <div>{org.taxId}</div>
      <div>
        <UserText
          imageUrl={owner?.imageURL ?? undefined}
          name={`${owner?.firstName ?? ''} ${owner?.lastName ?? ''}`}
        />
      </div>
    </Container>
  );
};

export default OrgItem;
