import { useState } from 'react';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  styled,
} from '@mui/material';

import { useAppDispatch } from '../../../hooks';
import { setTransferReserveSetting } from '../../../slices/systemSlice';

const Container = styled('div')`
  padding: 10px 30px;
  ${({ theme }) => theme.typography.body2}

  & > .title {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.5;
    text-align: center;
  }

  & > .description {
    margin: 20px 70px;
  }

  & > .action {
    display: flex;
    justify-content: space-between;
    padding: 24px 0 14px;
  }
`;

const RadioGroup = styled(MuiRadioGroup)`
  margin: auto;
  & .MuiFormControlLabel-label {
    ${({ theme }) => theme.typography.body2}
  }
`;

interface ReserveSettingContentProps {
  onNext: () => void;
  onCancel: () => void;
}

const ReserveSettingContent: React.FC<ReserveSettingContentProps> = ({
  onNext,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const [reserveSetting, setReserveSetting] = useState<'reserve' | 'clear'>(
    'reserve',
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setTransferReserveSetting(
        (e.currentTarget.value as 'reserve' | 'clear') === 'reserve',
      ),
    );
    setReserveSetting(e.currentTarget.value as 'reserve' | 'clear');
  };

  const handleClickNext = () => {
    onNext();
  };

  return (
    <Container>
      <div className="title">Reserve Setting?</div>
      <div className="description">
        While transferring the device, the settings will be reserved according
        to the requirements.
      </div>
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={reserveSetting}
          name="reserve-setting-radio-buttons-group"
          onChange={handleChange}
        >
          <FormControlLabel
            value="reserve"
            control={<Radio />}
            label="Reserve assigned project setting"
          />
          <FormControlLabel
            value="clear"
            control={<Radio />}
            label="Clear assigned project setting"
          />
        </RadioGroup>
      </FormControl>
      <div className="action">
        <DialogButton
          sx={(theme) => ({ color: theme.color.primary.$100 })}
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </DialogButton>
        <DialogButton
          variant="contained"
          color="primary"
          onClick={handleClickNext}
        >
          Next
        </DialogButton>
      </div>
    </Container>
  );
};

export default ReserveSettingContent;
