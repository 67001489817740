import type { SVGProps } from 'react';

const CancelSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <circle cx={16} cy={16} r={10} fill="currentColor" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11 14.5h10a1.5 1.5 0 1 1 0 3H11a1.5 1.5 0 0 1 0-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CancelSvgIcon;
