import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const ProjectSettingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/project-setting') {
      navigate('/project-setting/3d-model', { replace: true });
    }
  }, [location]);

  return <Outlet />;
};

export default ProjectSettingPage;
