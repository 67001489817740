import type { SVGProps } from 'react';

const DasloopSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g fill="currentColor">
      <path d="M20.78 6.238c-1.484.248-2.886 1.496-3.847 3.427-1.618 3.252-2.423 6.967-2.818 9.619a41.044 41.044 0 0 0-.243 1.927c3.228.103 7.512-.39 10.788-3.052 1.91-1.551 2.145-2.48 2.17-2.624v-.05c0-.04.24-4.854-2.565-7.912-1.069-1.077-2.275-1.536-3.484-1.335Z" />
      <path d="M19.796 5.19A11.95 11.95 0 0 0 17.63 5h-.016c-.534 0-1.047.026-1.535.075-2.388.445-4.267 2.609-5.593 6.424-.726 2.097-1.178 4.402-1.458 6.447.571.255 1.867.697 3.93.664.435-2.702 1.27-6.32 2.864-9.522.983-1.968 2.402-3.349 3.975-3.898ZM8.869 19.251a53.914 53.914 0 0 0-.151 1.594l-.023.287c.534.22 1.085.394 1.648.52.69.148 1.623.203 2.217.224 0-.039.006-.079.01-.12.04-.479.105-1.118.204-1.873h-.041c-1.82 0-3.1-.34-3.864-.632ZM7.942 10.872c-1.267 2.914-1.383 6.2-1.422 7.907.282.396.637.734 1.044.995.41-4.226 1.473-10.025 4.21-13.372-1.68.978-2.963 2.472-3.832 4.47Z" />
      <path d="M28.024 13.785c.057.58.075 1.163.054 1.746 0 .34-.197 1.629-2.642 3.619-1.927 1.565-5.314 3.341-10.753 3.341-.3 0-.605-.005-.918-.017v.083a.638.638 0 0 1-.192.428.617.617 0 0 1-.43.175c-.075 0-1.838 0-3.054-.263-.795-.178-1.57-.44-2.311-.784a.642.642 0 0 1-.356-.615l.022-.321c-1.53-.83-2.05-1.823-2.11-1.942a.643.643 0 0 1-.064-.296c.01-.394.022-.798.041-1.21-1.284 1.236-2.163 2.495-2.297 3.61-.482 3.989 11.53 7.113 19.69 1.98 4.1-2.584 8.228-7.047 5.32-9.534Z" />
    </g>
  </svg>
);
export default DasloopSvgIcon;
