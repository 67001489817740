import { SvgIcon, SvgIconProps } from '@mui/material';

const SaveSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M10 22H22V13L19 10H10V22Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13 17.75C13 17.1977 13.4477 16.75 14 16.75H18C18.5523 16.75 19 17.1977 19 17.75V22H13V17.75Z"
        stroke="white"
        strokeWidth="2"
        fill="none"
      />
    </SvgIcon>
  );
};

export default SaveSvgIcon;
