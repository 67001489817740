import { RouteObject } from 'react-router-dom';

import AlbumDialog from '../../components/AlbumDialog';

import RmrPage from '../../pages/rmr';
import Hardware from '../../pages/rmr/hardware';
import CreateRmrTIcketDialog from '../../pages/rmr/hardware/CreateRmrTIcketDialog';
import EditRmrTicketDialog from '../../pages/rmr/hardware/EditRmrTicketDialog';
import RmrTicketDialog from '../../pages/rmr/hardware/RmrTicketDialog';

const rmrRouter: RouteObject = {
  path: 'rmr',
  element: <RmrPage />,
  children: [
    {
      path: 'hardware',
      element: <Hardware />,
      children: [
        {
          path: ':ticketId',
          element: <RmrTicketDialog />,
          children: [
            {
              path: 'issue/:issueIndex/cause/:causeIndex/photo/:photoIndex',
              element: <AlbumDialog />,
            },
            {
              path: 'issue/:issueIndex/cause/:causeIndex/solution/:solutionIndex/photo/:photoIndex',
              element: <AlbumDialog />,
            },
          ],
        },
        {
          path: ':ticketId/edit',
          element: <EditRmrTicketDialog />,
        },
        {
          path: 'create',
          element: <CreateRmrTIcketDialog />,
        },
      ],
    },
  ],
};

export default rmrRouter;
