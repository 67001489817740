import { ChangeEvent, FC } from 'react';
import { styled, SxProps, TextareaAutosize } from '@mui/material';

const Container = styled('div')(({ theme }) => {
  return {
    display: 'inline-block',
    '& > textarea': {
      display: 'block',
      width: '100%',
      fontSize: '1rem',
      fontFamily:
        '"Noto Sans TC", "Noto Sans",Roboto,Helvetica,Arial,sans-serif',
      resize: 'none',
      padding: '8px',
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      outlineColor: theme.color.primary.$100,
      '&[data-error="true"]': {
        border: `1px solid ${theme.color.highlight}`,
      },
    },
    '& > .errorMessage': {
      fontSize: '0.75rem',
      color: '#FF6B00',
    },
  };
});

interface TextareaProps {
  sx?: SxProps;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  error?: boolean;
  errorMessage?: string;
}

const Textarea: FC<TextareaProps> = ({
  sx,
  value,
  onChange,
  onBlur,
  error,
  errorMessage,
}) => {
  return (
    <Container sx={sx}>
      <TextareaAutosize
        data-error={error}
        minRows={4}
        maxRows={4}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <div className="errorMessage">{errorMessage}</div>}
    </Container>
  );
};

export default Textarea;
