import { styled } from '@mui/material';

import { useAppSelector } from '../../../hooks';

const Container = styled('div')`
  padding: 10px 30px;
  & > .title {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.5;
    text-align: center;
  }

  & > .description {
    margin: 20px 70px;
  }
`;

interface TransferDeviceResultContentProps {
  successCount: number;
  totalCount: number;
}

const TransferDeviceResultContent: React.FC<
  TransferDeviceResultContentProps
> = ({ successCount, totalCount }) => {
  const transferEmail = useAppSelector((store) => store.system.transferEmail);
  return (
    <Container>
      <div className="title">
        {successCount === totalCount
          ? 'Transfer Success'
          : 'Partial Transfer Success'}
      </div>
      <div className="description">
        {successCount === totalCount ? (
          <>
            {totalCount} devices transfer to <b>{transferEmail}</b>
          </>
        ) : (
          <>
            <div>
              {totalCount} devices transfer to <b>{transferEmail}</b>
            </div>
            <div>{successCount} devices transfer successfully</div>
          </>
        )}
      </div>
    </Container>
  );
};

export default TransferDeviceResultContent;
