import { FC } from 'react';
import { styled } from '@mui/material';
import { format } from 'date-fns';

import { RmrTicket } from '../../../types/RmrTicket';

import StatusIcon from '../../../components/StatusIcon';

const Container = styled('div')(({ theme }) => {
  return {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontFamily: '"Noto Sans TC"',
    fontSize: '1rem',
    fontWeight: '500',
    color: theme.color.secondary.$100,
    backgroundColor: 'white',
    padding: '11px 24px 11px 16px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '8px',

    '& > .serial-number': {
      display: 'inline-block',
      minWidth: '30px',
      textAlign: 'center',
    },

    '& > .device-icon': {
      display: 'block',
      marginRight: '24px',
    },

    '& > .customer-name': {
      display: 'inline-block',
      width: '100px',
      marginRight: '24px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '& > .ticket-serial-number': {
      display: 'inline-block',
      width: '100px',
      color: theme.color.secondary.$60,
      fontWeight: '400',
      marginRight: '24px',
    },

    '& > .ticket-version': {
      display: 'inline-block',
      minWidth: '40px',
      color: theme.color.secondary.$60,
      fontWeight: '400',
      marginRight: '24px',
    },

    '& > .ticket-contract': {
      flex: 1,
      display: 'inline-block',
      color: theme.color.primary.$100,
      fontWeight: '400',
      marginRight: '68px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '& > .ticket-created-at': {
      display: 'inline-block',
      textAlign: 'right',
      color: theme.color.secondary.$60,
      fontWeight: '400',
    },
  };
});

interface SimpleRmrTicketProps {
  ticketNumber: number;
  data: RmrTicket;
  onClick: (ticketId: string) => void;
}

const SimpleRmrTicket: FC<SimpleRmrTicketProps> = ({
  ticketNumber,
  data,
  onClick,
}) => {
  const now = Date.now();

  return (
    <Container onClick={() => onClick(data.id)}>
      <span className="serial-number">{ticketNumber}</span>
      <StatusIcon
        sx={{
          margin: '0 8px',
        }}
        status={data.status}
        overdue={now > new Date(data.dueDate).getTime()}
      />
      <img className="device-icon" src="/assets/image/svg/rmr-hardware.svg" />
      <span className="customer-name" title={data.customer}>
        {data.customer}
      </span>
      <span className="ticket-serial-number">{data.serialNumber}</span>
      <span className="ticket-version">{`V${data.version
        .toString()
        .padStart(3, '0')}`}</span>
      <span className="ticket-contract">{data.contract}</span>
      <span className="ticket-created-at">
        {format(new Date(data.createdAt), 'yyyy - MM - dd')}
      </span>
    </Container>
  );
};

export default SimpleRmrTicket;
