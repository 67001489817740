import { theme as BeeinventorTheme } from '@beeinventor/dasiot-react-component-lib';
import { createTheme, SxProps } from '@mui/material';

const theme = createTheme({
  ...BeeinventorTheme,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        input: {
          colorScheme: 'dark',
        },
        body: {
          color: 'white',
          backgroundColor: BeeinventorTheme.color.secondary.$100,
          fontFamily:
            '"Noto Sans TC", "Noto Sans",Roboto,Helvetica,Arial,sans-serif',
        },
      },
    },
  },
  externalColor: {
    default: '#fff',
  },
});

declare module '@mui/material/styles' {
  interface Theme {
    externalColor: {
      default: string;
    };
  }

  interface ThemeOptions {
    externalColor?: {
      default?: string;
    };
  }
}

export const datePickerPopperSx: SxProps = {
  '& .MuiPaper-root': {
    color: 'white',
    backgroundColor: theme.color.secondary.$100,
  },
  '& .MuiPickersDay-root': {
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      background: theme.palette.primary.light,
    },
    '&.Mui-selected': {
      backgroundColor: theme.color.primary.$100,
    },
  },
  '& .MuiButtonBase-root': {
    color: 'white',
  },
  '& .MuiTypography-root': {
    color: 'white',
  },
  '& .PrivatePickersYear-yearButton': {
    fontSize: '1.5rem',
    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
};

export const memberButtonSx: SxProps = {
  fontSize: '0.75px',
  padding: '1px 4px 1px 12px',
  '& > span': {
    margin: 0,
  },
};

export const inputSx: SxProps = {
  display: 'block',
  width: '100%',
};

export const editDeviceInputSx: SxProps = {
  '& input': {
    backgroundColor: theme.color.box_bbg,
  },
};

export const rmrIssueCloseButtonSx: SxProps = {
  cursor: 'pointer',
  position: 'absolute',
  display: 'block',
  top: 0,
  right: 0,
  width: '40px',
  height: '40px',
  zIndex: 1,
};

export default theme;
