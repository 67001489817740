import { ReactNode } from 'react';
import { Switch } from '@beeinventor/dasiot-react-component-lib';
import { styled } from '@mui/material';

import {
  DasEndpointType,
  FeatureListType,
  OtherFeatureType,
  SSSSFeatureType,
} from '../../../types/Device';
import { ProjectFeature } from '../../../types/Project';

import AiCameraSvgIcon from '../../../assets/svg/feature-list/AiCameraSvgIcon';
import ConfinedSpaceSvgIcon from '../../../assets/svg/feature-list/ConfinedSpaceSvgIcon';
import DasfindSvgIcon from '../../../assets/svg/feature-list/DasfindSvgIcon';
import DaslockSvgIcon from '../../../assets/svg/feature-list/DaslockSvgIcon';
import DasloopSvgIcon from '../../../assets/svg/feature-list/DasloopSvgIcon';
import DastrackSvgIcon from '../../../assets/svg/feature-list/DastrackSvgIcon';
import DataDownloadSvgIcon from '../../../assets/svg/feature-list/DataDownloadSvgIcon';
import PermitToWorkSvgIcon from '../../../assets/svg/feature-list/PermitToWorkSvgIcon';
import TowerCraneSvgIcon from '../../../assets/svg/feature-list/TowerCraneSvgIcon';
import VrSvgIcon from '../../../assets/svg/feature-list/VrSvgIcon';

const list: Array<{
  id: SSSSFeatureType;
  name: string;
  icon: ReactNode;
}> = [
  {
    id: 'DigitisedTrackingSystem',
    name: 'Digitised tracking system for site plants, powered tools and ladders',
    icon: <DasfindSvgIcon />,
  },
  {
    id: 'DigitalisedPermitToWorkSystem',
    name: 'Digitalised permit-to-work system for high risk activities',
    icon: <PermitToWorkSvgIcon />,
  },
  {
    id: 'HazardousAreasAccessControl',
    name: 'Hazardous areas access control by electronic lock and key system',
    icon: <DaslockSvgIcon />,
  },
  {
    id: 'UnsafeActs/DangerousSituationAlertForMobilePlantOperationDangerZone',
    name: 'Unsafe Acts / Dangerous Situation Alert For Mobile Plant Operation Danger Zone',
    icon: <DastrackSvgIcon />,
  },
  {
    id: 'UnsafeActs/DangerousSituationAlertForTowerCraneLiftingZone',
    name: 'Unsafe Acts / Dangerous Situation Alert For Tower Crane Lifting Zone',
    icon: <TowerCraneSvgIcon />,
  },
  {
    id: 'SmartMonitoringDevices',
    name: 'Smart monitoring devices for workers and frontline site personnel',
    icon: <DasloopSvgIcon />,
  },
  {
    id: 'SafetyMonitoringSystem',
    name: 'Safety Monitoring System using Artificial Intelligence',
    icon: <AiCameraSvgIcon />,
  },
  {
    id: 'ConfinedSpaceMonitoringSystem',
    name: 'Confined Space Monitoring System',
    icon: <ConfinedSpaceSvgIcon />,
  },
  {
    id: 'SafetyTrainingWithVirtualRealityTechnology',
    name: 'Safety Training with Virtual Reality Technology',
    icon: <VrSvgIcon />,
  },
];

const List = styled('ul')`
  display: flex;
  align-items: center;
  svg {
    min-width: 32px;
  }

  & > .switch-container {
    display: flex;
    width: 100%;
    & > .edit-feature {
      margin-left: auto;
    }
  }
`;

interface SSSSContentProps {
  projectFeature:
    | {
        [type: string]: ProjectFeature;
      }
    | undefined;
  setSelectedType: (
    type:
      | DasEndpointType
      | FeatureListType
      | OtherFeatureType
      | SSSSFeatureType,
  ) => void;
  setIsOpenSettingDialog: (isOpen: boolean) => void;
  handleOnChange: (
    type:
      | DasEndpointType
      | FeatureListType
      | OtherFeatureType
      | SSSSFeatureType,
    checked: boolean,
  ) => void;
  handleOnChangeMetadata: (
    type: 'four_s',
    key: SSSSFeatureType,
    checked: boolean,
  ) => void;
}

const SSSSContent: React.FC<SSSSContentProps> = ({
  projectFeature,
  setSelectedType,
  setIsOpenSettingDialog,
  handleOnChange,
  handleOnChangeMetadata,
}) => {
  const items = list.map((data) => {
    return (
      <List key={`${data.id}`}>
        <div className="switch-container">
          <div>
            <Switch
              checked={!!projectFeature?.['four_s']?.metadata?.[data.id]}
              onChange={(_, checked) =>
                handleOnChangeMetadata('four_s', data.id, checked)
              }
            />
            {data.icon}
            {data.name}
          </div>
          {data.id === 'SmartMonitoringDevices' && (
            <div
              onClick={() => {
                setSelectedType('SmartMonitoringDevices');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          )}
        </div>
      </List>
    );
  });

  return (
    <>
      <ul>{items}</ul>
      <hr />
      <div className="title">Other Feature</div>
      <ul>
        <List>
          <div className="switch-container">
            <div>
              <Switch
                checked={!!projectFeature?.['report']}
                onChange={(_, checked) => handleOnChange('report', checked)}
              />
              <DataDownloadSvgIcon />
              Data Download
            </div>
            <div
              className={!projectFeature?.['report'] ? 'disabled' : undefined}
              onClick={() => {
                setSelectedType('report');
                setIsOpenSettingDialog(true);
              }}
            >
              Edit
            </div>
          </div>
        </List>
      </ul>
    </>
  );
};

export default SSSSContent;
