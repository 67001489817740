import { RouteObject } from 'react-router-dom';

import TransferPaPage from '../../pages/transfer-pa';

const transferPaRouter: RouteObject = {
  path: 'transfer-pa',
  element: <TransferPaPage />,
};

export default transferPaRouter;
