import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  styled,
  Typography,
} from '@mui/material';

import AnimatedCheckSvgIcon from '../assets/svg/AnimatedCheckSvgIcon';

const Dialog = styled(MuiDialog)`
  ${() => ({
    // '& .MuiPaper-root': {
    //   backgroundColor: theme.color.secondary.$80,
    // },
  })}

  & .MuiPaper-root {
    color: white;
    border-radius: 10px;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  text-align: center;
`;

interface LoadingDialogProps {
  open: boolean;
  isLoading: boolean;
  text?: string;
}

const LoadingDialog: React.FC<LoadingDialogProps> = ({
  open,
  isLoading,
  text,
}) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        {text && <Typography sx={{ color: 'black' }}>{text}</Typography>}
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : (
          <AnimatedCheckSvgIcon />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
