import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps as MuiDialogProps,
  styled,
} from '@mui/material';

import CrossSvgIcon from '../SvgIcon/CrossSvgIcon';

const Dialog = styled(MuiDialog)`
  & .icon {
    display: block;
    width: 32px;
    height: 32px;
    padding: 4px;
    margin: 0;
  }

  & .button {
    cursor: pointer;
  }

  & .MuiPaper-root {
    & .title {
      max-width: 316px;
      font-size: 1.75rem;
      line-height: 1.5;
      text-align: center;
      font-weight: 700;
      color: ${({ theme }) => theme.color.secondary.$120};
      margin: 0 82px;
    }

    & .operation {
      display: flex;
      justify-content: end;
      padding: 8px;
    }

    & .error-container {
      text-align: center;
      padding: 8px;
      color: #fff;
      background-color: ${({ theme }) => theme.color.highlight};
    }
  }
`;

const DialogContent = styled(MuiDialogContent)`
  max-width: 316px;
  text-align: center;
  margin: 20px auto 0;
  padding: 0;

  & .description {
    margin: 20px 0;
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: center;
    & span {
      &.bold {
        font-weight: bold;
      }
    }
  }
`;

const DialogActions = styled(MuiDialogActions)`
  justify-content: space-between;
  padding: 24px;
`;

interface ConfirmDialogProps extends MuiDialogProps {
  title: string;
  description: string | React.ReactNode;
  imageUrl?: string;
  isLoading?: boolean;
  errorMessage?: string;
  onClose?: () => void;
  onSubmit?: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  description,
  imageUrl,
  isLoading,
  errorMessage,
  onSubmit,
  onClose,
  ...otherProps
}) => {
  return (
    <Dialog onClose={onClose} {...otherProps}>
      <div
        className="operation button"
        onClick={() => {
          onClose?.();
        }}
      >
        <div className="icon">
          <CrossSvgIcon />
        </div>
      </div>
      {errorMessage && <div className="error-container">{errorMessage}</div>}
      <div className="title">{title}</div>
      <DialogContent>
        <div className="description">{description}</div>
        {imageUrl ? <img src={imageUrl} /> : null}
      </DialogContent>
      <DialogActions>
        <DialogButton
          mode="light"
          variant="text"
          color="secondary"
          onClick={() => {
            onClose?.();
          }}
        >
          Cancel
        </DialogButton>
        <DialogButton
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit?.();
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: '#fff' }} size="1.5rem" />
          ) : (
            'OK'
          )}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
