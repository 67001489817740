import type { SVGProps } from 'react';
const VrSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill="#3E3E3E"
      fillRule="evenodd"
      d="M28 12a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v1.331A3.578 3.578 0 0 1 7.52 13h18.96c.555 0 1.068.12 1.52.332V12ZM7.375 15C6.615 15 6 15.651 6 16.454v3.98c0 1.3.814 2.44 1.995 2.797l3.59 1.085c1.197.362 2.474-.171 3.114-1.3l.835-1.472h2.932l.835 1.472c.64 1.129 1.917 1.662 3.114 1.3l3.59-1.085C27.187 22.875 28 21.733 28 20.435v-3.98C28 15.65 27.384 15 26.625 15H7.375ZM27 19l-3-3h-2l5 5v-2Zm0-1-2-2h2v2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default VrSvgIcon;
