import type { SVGProps } from 'react';

const DastrackVSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 7h-4v3H4v5a1 1 0 0 0 1 1h13V9h2V7ZM8.5 11 6 15h1.5l2.5-4H8.5Zm3 0L9 15h1.5l2.5-4h-1.5Zm.5 4 2.5-4H16l-2.5 4H12Zm14-5h1v2h-1v3h1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-3.17a3.001 3.001 0 0 0-5.66 0h-6.34a3.001 3.001 0 0 0-5.66 0H5a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h14v-7h7Zm-5 2v3h3v-3h-3Zm2 10a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM9 24a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DastrackVSvgIcon;
