import type { SVGProps } from 'react';
const ConfinedSpaceSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.586 5H27v15.414l-.293.293-6 6-.293.293H5V11.586l.293-.293 6-6L11.586 5ZM13 11h6.586l4-4H13v4Zm-2 0V8.414L8.414 11H11Zm14-2.586-4 4v11.172L23.586 21H22v-2h3V8.414ZM7 23.586V13h12v6h-6v-4h-2v4.586l-4 4ZM12.414 21H19v4H8.414l4-4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ConfinedSpaceSvgIcon;
