import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';

import CloseDialogSvgIcon from '../../assets/svg/dialog/CloseDialogSvgIcon';
import theme from '../../theme';
import ArrowLeftSvgIcon from '../SvgIcon/ArrowLeftSvgIcon';
import ArrowRightSvgIcon from '../SvgIcon/ArrowRightSvgIcon';

const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    min-width: 400px;
    & div.title {
      display: flex;
      align-items: center;
      & .text {
        width: 100%;
        text-align: center;
        margin: 16px 48px;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
      }
      & .icon-close {
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
`;
const DialogContent = styled(MuiDialogContent)`
  padding: 16px 30px;
`;
const DialogActions = styled(MuiDialogActions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

interface PreviewDialogDialogProps extends DialogProps {
  dialogTitle?: string;
  saveable?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  onPrevious?: () => void;
  previous?: boolean;
}

const PreviewDialog: React.FC<PreviewDialogDialogProps> = ({
  dialogTitle,
  saveable,
  onSave,
  onPrevious,
  onCancel,
  previous,
  children,
  ...otherProps
}) => {
  return (
    <Dialog {...otherProps}>
      <div className="title">
        <div className="text">{dialogTitle}</div>
        <CloseDialogSvgIcon className="icon-close" onClick={onCancel} />
      </div>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <DialogButton
          mode="light"
          color="info"
          variant="text"
          style={{ color: theme.color.primary.$100 }}
          onClick={onCancel}
        >
          Cancel
        </DialogButton>
        <div>
          {previous && (
            <DialogButton
              mode="light"
              color="info"
              variant="text"
              style={{ color: theme.color.primary.$100 }}
              onClick={onPrevious}
              startIcon={<ArrowLeftSvgIcon />}
            >
              Back
            </DialogButton>
          )}
          <DialogButton
            mode="light"
            color="primary"
            variant="contained"
            disabled={!saveable}
            onClick={onSave}
            endIcon={<ArrowRightSvgIcon />}
          >
            Check
          </DialogButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDialog;
