import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material';

import InformationDialog from '../../../components/Dialog/InformationDialog';

import TaskStatusIcon from '../TaskStatusIcon';

const Title = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Content = styled('div')`
  padding: 0 36px;
  & .item {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 0;
    }
    & label {
      color: ${({ theme }) => theme.color.secondary.$60};
    }
  }
`;

const TaskInformationDialog = () => {
  const navigate = useNavigate();
  const { taskId } = useParams();
  return (
    <InformationDialog
      open
      titleNode={{
        start: <TaskStatusIcon status="pending" />,
        center: (
          <Title>
            <span>{taskId}</span>
            <span>{'Firmware Update'}</span>
          </Title>
        ),
      }}
      closeable
      onClose={() => navigate(-1)}
    >
      <Content>
        <div className="item">
          <label>Time</label>
          <div>2021 - 11 - 11 10 : 23 : 03</div>
        </div>
        <div className="item">
          <label>PA</label>
          <div>Audio ID</div>
        </div>
      </Content>
    </InformationDialog>
  );
};

export default TaskInformationDialog;
